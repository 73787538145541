export const UPLOAD_TYPE = {
  BIDSHEET: "BIDSHEET",
  SBA1502: "SBA1502"
}

export const SBA_TYPE = {
  INDIVIDUAL: "INDIVIDUAL",
  POOL: "POOL"
}

export const LOAN_TYPE = {
  WHOLE_LOANS: "whole_loans",
  GOVERMENT_LOANS: "current_guaranteed_loan_balance"
}
