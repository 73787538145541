import { ITextInput } from "./type";

import { FC } from "react";
import cn from "classnames";

import { Icon } from "components";

import styles from "./TextInput.module.sass";

export const TextInput: FC<ITextInput> = ({
  className,
  classLabel,
  classInput,
  label,
  empty,
  view,
  isError = false,
  errorMessage,
  icon,
  note,
  ...props
}) => {
  return (
    <div
      className={cn(
        styles.field,
        { [styles.empty]: empty },
        { [styles.view]: view },
        { [styles.icon]: icon },
        className
      )}
    >
      {label && <div className={cn(classLabel, styles.label)}>{label}</div>}
      <div className={styles.wrap}>
        <input className={cn(classInput, styles.input, styles.inputWebkit)} {...props} />
        {view && (
          <button className={styles.toggle}>
            <Icon name="eye" size="24" />
          </button>
        )}
        {icon && (
          <div className={styles.preview}>
            <Icon name={icon} size="24" />
          </div>
        )}
        {note && <div className={styles.note}>{note}</div>}
        {isError && errorMessage && (
          <span className="input__error" style={{
            "position": "absolute",
            "top": "100%"
          }}>{errorMessage}</span>
        )}
      </div>
    </div>
  );
};
