export const LANGUAGES = [
    {
      title: "English",
      flag: "🇺🇸",
    },
    {
      title: "Vietnamese",
      flag: "🇻🇳",
    },
    {
      title: "Russia",
      flag: "🇷🇺",
    },
  ];
  