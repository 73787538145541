import { useEffect } from "react";
import cn from "classnames";
import useDarkMode from "use-dark-mode";
import { useSetRecoilState } from "recoil";

import { Icon } from "components";
import { darkThemeState } from "states";

import styles from "./Theme.module.sass";
import { useCookie } from "@hooks/cookie";

const Theme = ({ className, icon, small, size = "24" }) => {
  const darkMode = useDarkMode();
  const { get: getCookieData } = useCookie();

  const setThemeDark = useSetRecoilState(darkThemeState);

  useEffect(() => {
    const token = getCookieData("userDetails");

    if (token) {
      setThemeDark(darkMode.value);
      localStorage?.setItem("darkMode", darkMode.value ? "true" : "false");
    } else {
      setThemeDark(darkMode.value);
      localStorage?.setItem("darkMode", "true");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode]);

  const handleChange = () => {
    darkMode.toggle();
    const id = document.getElementById("themeChangeFromExchange");
    if (id) id.click();
  };

  return (
    <label className={cn(className, styles.theme, { [styles.small]: small })}>
      <input
        className={styles.input}
        checked={darkMode.value}
        onChange={handleChange}
        type="checkbox"
      />
      {icon ? (
        <div className={styles.icon}>
          <Icon name="theme-light" size={size} />
          <Icon name="theme-dark-moon" size={size} />
        </div>
      ) : (
        <span className={styles.inner}>
          <span className={styles.box}></span>
        </span>
      )}
    </label>
  );
};

export default Theme;
