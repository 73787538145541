import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button, CountdownTimer, Loader, Pagination } from "../../../../components";
import "./user-bid-list.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { USER_BID_STATUS_COLOR, USER_BID_TABLE_HEADERS } from "../../constant";
import { ReactResponsiveTable } from "@storybook/react-responsive-table";
import { APIS } from "../../../../constant";
import {
  useCurrency,
  useDebounce,
  useNetwork,
  useNotification,
  usePrevious,
} from "../../../../hooks";
import { getJsonToParams, getObjectFromUseSearchParams } from "@utils/common";
import { Json } from "../../../../types";
import { PlaceBidModal } from "../index";
import { useRecoilValue } from "recoil";
import {
  AuctionBidPostState,
  userSelectedCurrencyState,
} from "../../../../states";
import { Tooltip as ReactTooltip } from "react-tooltip";
import useAuctionHook from "@views/AuctionConsumer/hook";

const RenderPrice = ({ detail, price }: Json) => {
  const { sbaAndNormalPrice, isSbaLoan } = useAuctionHook(detail);
  const { formatCurrencyWithBillion } = useCurrency();
  return <>
    {sbaAndNormalPrice(detail, { price: price })}
    {isSbaLoan && <>
      <br />
      <small>
        {formatCurrencyWithBillion(price, 2)}
      </small>
    </>}
  </>
}

export const UserBidList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFilter = { limit: 20, offset: 0 };
  const currency = useRecoilValue(userSelectedCurrencyState);
  const { get: fetchUserAuction } = useNetwork({ updateState: false });
  const { get: fetchAuctionDetail, loading: auctionLoading } = useNetwork();
  const navigator = useNavigate();
  const { errorNotification } = useNotification();
  const { data: bidDetails = {}, loading: bidLoading } =
    useRecoilValue(AuctionBidPostState);
  const prevBidLoading = usePrevious(bidLoading);
  const [{ data: userAuctions = [], loading }, setUserAuctions] =
    useState<Json>({});
  const [placeBid, setPlaceBid] = useState<null | Json>(null);
  const [filters, setFilters] = useState<Json>({
    ...initialFilter,
    ...getObjectFromUseSearchParams(searchParams),
  });
  const debouncedFilters = useDebounce(
    filters,
    { immediate: filters.immediate },
    400
  );

  useEffect(() => {
    handleFetchUserAuction();
  }, [debouncedFilters]);

  useEffect(() => {
    if (bidDetails?.message !== "ok" && !bidLoading && prevBidLoading) {
      errorNotification(bidDetails?.message);
    } else if (bidDetails?.message === "ok" && !bidLoading && prevBidLoading) {
      const { currentBidPrice, endTime, id, status, bidPrice, bidStatus } =
      bidDetails?.data || {};
      setPlaceBid(null);
      setUserAuctions((prev) => {
        let temp = JSON.parse(JSON.stringify(prev.data));
        const idx = temp.findIndex((_data: Json) => _data?.id === id);
        temp[idx] = {
          ...temp[idx],
          currentPrice: currentBidPrice,
          endTime,
          status,
          bidPrice,
          bidStatus,
        };
        return { ...prev, data: [...temp] };
      });
    }
  }, [bidDetails]);

  const handleFetchUserAuction = useCallback(async () => {
    setUserAuctions({ loading: true });
    const { immediate, tab = "myAuction", ..._filters } = filters;
    setSearchParams({ tab, ..._filters });
    const res = await fetchUserAuction(
      APIS.AuctionBid + getJsonToParams({ bidList: 1, ..._filters })
    );
    if (res?.message === "ok") {
      setUserAuctions({ loading: false, data: res?.data });
    } else {
      setUserAuctions({ loading: false });
      errorNotification(
        res?.message || "Something went wrong! Please try again"
      );
    }
  }, [filters]);

  const handleFilter = (filterObj: Json, debounce: boolean = true) => {
    setFilters((prev) => ({ ...prev, ...filterObj, immediate: !debounce }));
  };

  const handlePagination = useCallback((val: number, action: string) => {
    let pageChange: Json = {};
    if (action === "perPage") {
      pageChange.limit = val;
      pageChange.offset = 0;
    }
    if (action === "page") pageChange.offset = val;
    handleFilter(pageChange);
  }, []);

  const handleFetchAuctionDetail = useCallback(async (id: string) => {
    setPlaceBid({ id });
    const res = await fetchAuctionDetail(`${APIS.Auction}/${id}`);
    if (res?.message === "ok") {
      setPlaceBid({ ...res?.data });
    } else {
      errorNotification(
        res?.message || "Something went wrong! Please try again"
      );
    }
  }, []);

  const handleClickView = useCallback((id: any, status: string) => {
    if (status === "live") {
      navigator(`/auction-details/${id}`);
    } else {
      navigator(`/user-bid-details/${id}`);
    }
  }, []);

  const renderDate = useCallback(
    ({ days, hours, minutes, seconds, completed }: Json, id: string) => {
      return `${!!days ? days + "D " : ""}${(hours < 10 ? "0" : "") + hours}:${
        (minutes < 10 ? "0" : "") + minutes
      }:${(seconds < 10 ? "0" : "") + seconds}`;
    },
    []
  );

  const tableRows = useMemo(() => {
    if (!userAuctions?.length) return [];
    const rows: any = [];
    userAuctions?.forEach((items: any) => {
      let row: any = {};
      USER_BID_TABLE_HEADERS.forEach(({ format, key }) => {
        if (format === "jsx" && key === "price") {
          const value = () => <RenderPrice detail={items} price={items?.bidPrice} />
          return (row[key] = value);
        }

        if (format === "jsx" && key === "currentPrice") {
          const value = () => <RenderPrice detail={items} price={items?.currentPrice} />
          return (row[key] = value);
        }

        if (format === "jsx" && key === "productName") {
          return (row[key] = () => (
            <div className="auction__table-product">
              <img
                src={
                  items?.assetIcon ||
                  "https://upload.wikimedia.org/wikipedia/commons/5/5f/Grey.PNG"
                }
                alt="assetIcon"
              />
              <div className="auction__table-product-desc">
                <span>#{items?.id || "-"}</span>
                <p className="auction-product-name" data-tooltip-id={items.id}>
                  {items?.name || "-"}
                </p>
                {items?.name?.length >= 25 && (
                  <ReactTooltip
                    className="theme-tooltip"
                    id={items.id}
                    place="bottom"
                    content={items?.name}
                  />
                )}
              </div>
            </div>
          ));
        }

        if (format === "jsx" && key === "timeLeft") {
          row.timeLeft = () =>
            items?.status === "live" ? (
              <CountdownTimer
                dateTime={items?.endTime}
                renderer={(value) => renderDate(value, items?.id)}
              />
            ) : (
              "00:00:00"
            );
        }

        if (format === "jsx" && key === "bidStatus") {
          return (row[key] = () => (
            <span className="auction-status">
              <i
                style={{
                  backgroundColor: USER_BID_STATUS_COLOR[items?.bidStatus],
                }}
              />
              {items?.bidStatus?.split("-").join(" ")}
            </span>
          ));
        }

        if (format === "jsx" && key === "status") {
          return (row[key] = () => (
            <span className="auction-status">
              <i
                style={{
                  backgroundColor: USER_BID_STATUS_COLOR[items?.status],
                }}
              />
              {items?.status}
            </span>
          ));
        }

        if (format === "jsx" && key === "action") {
          const isFetching = placeBid?.id === items.id && auctionLoading;
          return (row[key] = () => (
            <div className="auction-button-groups">
              {items?.bidStatus === "outbid" && items?.status === "live" && (
                <Button
                  label={
                    isFetching ? (
                      <Loader className="white-transparent" dimension={15} />
                    ) : (
                      "Bid Now"
                    )
                  }
                  type="button-green btn-h-auto"
                  handleClick={() => handleFetchAuctionDetail(items?.id)}
                />
              )}
              <Button
                label="View"
                type="btn-light-grey btn-h-auto"
                handleClick={() => handleClickView(items?.id, items?.status)}
              />
            </div>
          ));
        }
      });
      rows.push(row);
    });
    return rows;
  }, [userAuctions, auctionLoading, placeBid, currency]);

  return (
    <>
      {!!placeBid?.tradeType && (
        <PlaceBidModal
          open={!!placeBid}
          details={placeBid}
          onClose={() => setPlaceBid(null)}
        />
      )}

      <div
        className="auction-bid-list-container pt-0"
        style={{ height: "auto" }}
      >
        <ReactResponsiveTable
          rootClassName="auction-bid-table"
          rows={tableRows}
          column={USER_BID_TABLE_HEADERS}
          isLoading={loading}
        />

        <Pagination
          showCount={false}
          listCount={userAuctions?.length}
          page={parseInt(filters.offset)}
          perPage={parseInt(filters.limit)}
          onChangePage={(page) => handlePagination(page, "page")}
          onChangePerPage={(perPage) => handlePagination(perPage, "perPage")}
        />
      </div>
    </>
  );
};
