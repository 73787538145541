import styles from "./compliance.module.sass";
import { Image } from "@storybook";
import {  useSetRecoilState,useRecoilValue } from "recoil";
import { darkThemeState } from "states";


export const KycStart = ()=>{
    // const setIsStartVerification = useSetRecoilState(isStartVerificationState);
    const themeDark = useRecoilValue(darkThemeState);
    return(
        <div className={styles.kyc}>
            {themeDark ?
                <Image fileName= {"images/kyc-start.svg"} height={"220px"}/> :
                <Image fileName= {"images/kyc-start-light.svg"} height={"220px"}/>
            }
            <h2 className={styles.kyc_heading}>Verification required!</h2>
            <p className={styles.kyc_para}>Before you can start trading or do any transaction, we require <br/> some essential verification details from you or your entity.</p>
            {/* <button className={`${styles.proceed_btn} ${styles.kyc_btn}`} onClick={()=>setIsStartVerification(true)}>
                Start verification
            </button> */}
        </div>
    );
}