import { Image } from "@storybook";
import { useEffect } from "react";

export const TermsOfService = () => {
  useEffect(() => {
    const OneTrust = (window as any).OneTrust;
    OneTrust.NoticeApi.Initialized.then(function () {
      OneTrust.NoticeApi.LoadNotices([
        "https://privacyportal-cdn.onetrust.com/f28abd65-17b0-4df7-b558-d20b86121b10/privacy-notices/9be91fa8-24e5-46a2-8770-9f90ee82e316.json",
      ]);
    });
  }, []);

  return (
    <div className="terms-of-service">
      {/* <div className="terms-of-service__header">
        <Image
          className="terms-of-service__logo"
          fileName={`images/Liquidity_Blue_Logo.png`}
        />
      </div> */}
      <div
        id="otnotice-9be91fa8-24e5-46a2-8770-9f90ee82e316"
        className="otnotice"
      ></div>
    </div>
  );
};
