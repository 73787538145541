export const CANCEL_ORDER = {
  TITLE: "Cancel order",
  DESCRIPTION: "Cancelling order will remove it from the order book.",
  BOLD_DESCRIPTION: "Do you want to cancel?",
};

export const CANCEL_ORDER_BTN = {
  CANCEL_YES: "Yes",
  CANCEL_NO: "No",
};
