import React, { useMemo } from "react";

import "./bid-card.scss";
import { SkeletonLogo } from "../../../../components";

export const SkeletonBidCard = ({ listsToRender = 1 }) => {

  const renderCards = useMemo(() => {
    return Array(listsToRender).fill(1).map((card, index) => (
      <div className="bid-card-container no-skeleton" key={`bidCard${index}`}>
        <div className="bid-card-image">
          <SkeletonLogo className="image border-radius-0 skeleton-fade-in-out" />
        </div>
        <div className="bid-card-details">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="logo-container" style={{ alignItems: "center" }}>
              <div className={`logo-img`}>
                <SkeletonLogo className="border-radius-0" />
              </div>
              <div className="title-heading-symbol">
                <SkeletonLogo style={{ height: "28px", width: "50%" }} />
              </div>
            </div>

            <div className="date-and-buttons" style={{ justifyContent: "space-around", flex: "1 1" }}>
              <div className="bid-buttons">
                <div className="bid-buttons-grp">
                  <SkeletonLogo style={{ height: "24px", width: "120px" }} />
                  <SkeletonLogo style={{ height: "24px", width: "120px" }} />
                </div>
              </div>
            </div>
          </div>
          <div className="time-container">
            <SkeletonLogo style={{ height: "96px", width: "96px", borderRadius: "50%" }} />
          </div>
        </div>
      </div>));
  }, []);

  return <>{renderCards}</>
};
