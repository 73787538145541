import { useCallback } from "react";
import { useNetwork } from "./network";
import { useCookie } from "./cookie";
import { APIS } from "../constant";
import { useSetRecoilState } from "recoil";
import { AuctionBidPostState, AuctionListState, AuctionProductCategoryState } from "../states";
import { Json } from "../types";
import { useNotification } from "./notification";
import { getJsonToParams } from "../utils";

export const useAuctionApis = () => {
  const { get: getTokenFromCookie } = useCookie();
  const user = getTokenFromCookie("userDetails");
  const { get: fetchAuctions } = useNetwork({ updateState: false });
  const { post: auctionBidPost } = useNetwork({ updateState: false });
  const { errorNotification } = useNotification();
  const setAuctionListState = useSetRecoilState(AuctionListState);
  const seAuctionProductCategory = useSetRecoilState(AuctionProductCategoryState);
  const setAuctionBidPost = useSetRecoilState(AuctionBidPostState);

  const fetchAuctionList = useCallback(
    async (query: Json = {}): Promise<void> => {
      if (!user?.token) return;
      setAuctionListState((prev) => ({
        ...prev, loading: true, data: {},
      }));
      const resData: Json = { loading: false };
      try {
        resData.data = await fetchAuctions(
          APIS.Auction + getJsonToParams(query)
        );
      } catch (e: any) {
        errorNotification(e?.message || "Something went wrong!");
      } finally {
        setAuctionListState((prev) => ({ ...prev, ...resData }));
      }
    }, []);

  const fetchAuctionProductCategory = useCallback(async (query: Json = {}): Promise<void> => {
    if (!user?.token) return
    seAuctionProductCategory((prev) => ({
      ...prev, loading: true, data: {},
    }));
    const resData: Json = { loading: false };
    try {
      resData.data = await fetchAuctions(APIS.AuctionProductCategory + getJsonToParams(query));
    } catch (e: any) {
      errorNotification(e?.message || "Something went wrong!");
    } finally {
      seAuctionProductCategory((prev) => ({ ...prev, ...resData }));
    }
  }, []);

  const postAuctionBid = useCallback(async (query: Json = {}): Promise<void> => {
    if (!user?.token) return
    setAuctionBidPost((prev) => ({
      ...prev, loading: true, data: {},
    }));
    const resData: Json = { loading: false };
    try {
      resData.data = await auctionBidPost(APIS.AuctionBid, query, { apiResponse: true });
    } catch (e: any) {
      errorNotification(e?.message || "Something went wrong!");
    } finally {
      setAuctionBidPost((prev) => ({ ...prev, ...resData }));
    }
  }, []);

  return {
    fetchAuctionList, fetchAuctionProductCategory, postAuctionBid
  };
};
