export const items = [
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: "80",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: ".5",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: "50",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: ".5",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: "100",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: "32",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: "20",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: "50",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: "32",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: ".5",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: ".5",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: "15",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: ".5",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: "32",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: ".5",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: "50",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: ".5",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    negative: true,
    percent: ".5",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: "87",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: "60",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: "50",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: ".5",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: "60",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: ".5",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: ".5",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: "50",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: "23",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: ".5",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: ".5",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: "60",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: ".5",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: "60",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: ".5",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: "87",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: ".5",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: "60",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: ".5",
  },
  {
    price: "36920.12",
    amount: "0.758965",
    total: "28,020.98",
    positive: true,
    percent: ".5",
  },
];

export const counterOptions = [10, 20, 30];

export const defaultValue = "0";
