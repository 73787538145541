import { useCallback, useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { LoginPhoneNumberState, SignInActiveStep } from "states";
import { useWebAuthentication } from "hooks";
import { QRCodeScan } from "./components/qr-code-scan";
import { useNavigate } from "react-router-dom";

export const SignIn = () => {
  //custom hook that helps us to create and get credentials for Web Authentication

  const signInActiveStep = useRecoilValue(SignInActiveStep);
  const [loginPhoneNumber, setLoginPhoneNumber] = useRecoilState(
    LoginPhoneNumberState
  );
  const navigate = useNavigate();
  const { registerNewCredentials, authenticate, getRegistrations } =
    useWebAuthentication();

  const handleOnSuccess = useCallback(() => {
  }, []);

  const handleOnFailed = useCallback(() => {
  }, []);

  const handleVerification = useCallback(async () => {
    const registerPayload = {
      email: loginPhoneNumber?.phone ?? "",
      id: loginPhoneNumber?.phone ?? "",
      displayName: loginPhoneNumber?.phone ?? "",
    };

    const isAlreadyExist = getRegistrations().find(
      (item: any) => item.name === loginPhoneNumber?.phone ?? ""
    );

    if (!isAlreadyExist)
      registerNewCredentials(handleOnSuccess, registerPayload);
    else authenticate(handleOnSuccess, handleOnFailed);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getComponent = useCallback(() => {
    switch (signInActiveStep) {
      case "qrcode":
        return navigate("/sign-in");
      case "chooseMethod":
        return navigate("/sign-in/choose-method");
      default:
        <div></div>;
    }
  }, [signInActiveStep, navigate]);

  useEffect(() => {
    getComponent();
  }, [getComponent]);

  return <QRCodeScan />;
};
