import { atom, selector } from 'recoil';
// @ts-ignore
import { Json } from "@types/common";

export interface Asset {
  name: string;
  imgSrc: string;
}

export const selectedChatIdState = atom<string | null>({
  key: 'selectedChatIdState',
  default: null,
});

export const chatListState = atom({
  key: 'chatListState',
  default: [
    { id: 'LIQDTY1234584', name: 'LIQDTY1234584', messages: ['How can I help you?'] },
    {
      id: 'LiquidityID',
      name: 'LiquidityID',
      messages: ['Yes Please! Ask your Questions, also sending you some document and images.']
    },
    { id: 'LIQDTY1234584', name: 'LIQDTqwert', messages: ['How can I help you?'] },
    {
      id: 'LiquidityID',
      name: 'Liquiditabcd',
      messages: ['Yes Please! Ask your Questions, also sending you some document and images.']
    },

  ],
});

export const selectedUserChatState = atom<Json | null>({
  key: 'selectedUserChatState',
  default: null,
});

export const InitiateChatConfirmationState = atom<Json | null>({
  key: 'InitiateChatConfirmationState',
  default: null,
});

export const AssetChatDataState = atom<any>({
  key: "AssetChatDataState",
  default: {loading:false, data:[], offset: 0, limit: 5},
});

export const ChatsOnAssetState = atom<any>({
  key: "ChatsOnAssetState",
  default: {loading:false, data:[], offset: 0, limit: 5},
});

