import { useNetwork } from "@hooks/network";
import { userPersonalDetails } from "@states/user";
import { APIS } from "constant";
import { useCallback, useState } from "react";
import {useRecoilValue, useSetRecoilState } from "recoil";
import {
  InitiateChatConfirmationState,
  selectedUserChatState,
} from "../ChatModal/state";
import { getJsonToParams } from "@utils/common";
import { useNotification } from "@hooks/notification";
import { useWebSocket } from "@hooks/web-socket";
import { Json } from "../../../types/common";

const UseChatroomApi = () => {
  const { socket } = useWebSocket();
  const setInitiateChatConfirmation = useSetRecoilState(InitiateChatConfirmationState);
  const { get: getAssetwiseList } = useNetwork();
  const { get: getAssetChat, loading: assetChatLoading } = useNetwork();
  const { get: getAssetChatHistory, loading: assetChatHistoryLoading } = useNetwork();
  const { put: chatRead } = useNetwork();
  const { post: chatInitiated, loading: chatInitiatedLoading } = useNetwork();
  const { errorNotification } = useNotification();

  const personalData = useRecoilValue(userPersonalDetails);
  const [selectedChatHistory, setSelectedChatHistory] = useState<Json>({});
  const setSelectedUserChat = useSetRecoilState(selectedUserChatState);

  const getUserWiseAssetsList = useCallback((query: Json = {}, options?: any,setAssetChatData?:any) => {
    setAssetChatData((prev: any) => {
      return {
        ...prev,
        loading:true,
      };
    });
    getAssetwiseList( `${APIS.ASSETS_CHAT}/${personalData.customerId}` + getJsonToParams(query)).then((res: Json) => {
      if (res) {
        if (options.updateNewData === false) {
          setAssetChatData({
            loading: false,
            data: [...res?.data],
            offset: query.offset,
            limit: query.limit,
          });
        } else {
          setAssetChatData((prev: any) => ({
            ...prev,
            loading: false,
            data: [...prev?.data, ...res?.data],
            offset: query.offset,
            limit: query.limit,
          }));
        }
      }
    }).catch(error => {
      setAssetChatData((prev: any) => ({
        ...prev,
        loading: false,
      }));
    });
  }, [getAssetwiseList, personalData.customerId]);

  const getAssetWiseChatList = useCallback((query: Json = {}, options: any,setChatsOnAsset:any,assetId: string, userId: string,) => {
    setChatsOnAsset((prev: any) => {
      return {
        ...prev,
        loading:true,
      };
    });
    getAssetwiseList( `${APIS.ASSETS_CHAT}?assetId=${assetId}&userId=${
            personalData.customerId
    }&limit=${query?.limit}&offset=${query?.offset}&search=${query?.search || ""}`).then((res: Json) => {
      if (res) {
        if (options.updateNewData === false) {
          setChatsOnAsset({
            loading: false,
            data: [...res?.data],
            offset: query.offset,
            limit: query.limit,
          });
        } else {
          setChatsOnAsset((prev: any) => ({
            ...prev,
            loading: false,
            data: [...prev?.data, ...res?.data],
            offset: query.offset,
            limit: query.limit,
          }));
        }
      }
    }).catch(error => {
      setChatsOnAsset((prev: any) => ({
        ...prev,
        loading: false,
      }));
    });
  }, [getAssetwiseList, personalData.customerId]);


  const updateReadChat = useCallback((chatId: string, data: Json = []) => {
    const payload = {
      userId: personalData.customerId,
    };
    chatRead(`${APIS.ASSET_CHAT_HISTORY}/${chatId}`, payload).then((res) => {
      if (res) {
        socket?.emit("chat", {
          type: "READUPDATE",
          chatId: chatId,
          receiverId: data?.receiverId,
          senderId: personalData?.customerId,
          assetId: data?.assetId,
          readCount: data?.readCount
        });
      }
    });
  }, [socket]);

  const getChatHistory = useCallback((query: Json = {}, options: any,chatId: string) => {
    setSelectedChatHistory((prev: any) => {
      return {
        loading: true,
      };
    });
    getAssetChatHistory(`${APIS.ASSET_CHAT_HISTORY}/${chatId}` + getJsonToParams(query)).then((res) => {
      if (res) {
        if (options.updateNewData === false) {
          setSelectedChatHistory({
            loading: false,
            data: [...res?.data],
            offset: query.offset,
            limit: query.limit,
          });
        } else {
          setSelectedChatHistory((prev: any) => ({
            ...prev,
            loading: false,
            data: [...prev?.data, ...res?.data],
            offset: query.offset,
            limit: query.limit,
          }));
        }
      }
    });
  }, []);

  interface IChatInitiatedParams {
    assetId: string,
    receiverId: string,
    senderId: string,
  }

  const getChatInitiated = useCallback((payload: IChatInitiatedParams) => {
    try {
      chatInitiated(`${APIS.chatInitiate}`, payload, { apiResponse: true }).then((res) => {
        if (res.message === "ok") {
          if (!res?.data?.isChatPresent) {
            setInitiateChatConfirmation(payload);
          } else {
            setSelectedUserChat(null);
            socket?.emit("chat", {
              type: "JOINROOM",
              chatId: res?.data?.chatId,
              customerId: payload?.senderId,
            });
          }
        } else {
          errorNotification(res.message || "Something went wrong!");
        }
      });
    } catch (e: any) {
      errorNotification(e?.message || "Something went wrong!");
    }
  }, [socket]);

  return {
    getUserWiseAssetsList,
    getAssetWiseChatList,
    getChatHistory,
    assetChatHistoryLoading,
    assetChatLoading,
    updateReadChat,
    chatInitiatedLoading,
    getChatInitiated,
    selectedChatHistory
  };
};

export default UseChatroomApi;
