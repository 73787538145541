import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import React, { useCallback, useMemo } from "react";
import { Json } from ".././../../../types/common";
import { Image } from "@storybook";
import { NotificationVisibleState } from "@views/Notification/store/state";
import UseNotificationApi from "@views/Notification/store/useNotificaitonApi";

import "./bankNotification.scss";

const BankNotification = (data: Json) => {
  const setVisibleNotification = useSetRecoilState(NotificationVisibleState);

  const { updateMarkAsRead } = UseNotificationApi();
  const navigate = useNavigate();

  const maskDots = useCallback((listsToRender: number) => {
    return Array(listsToRender)
      .fill(1)
      .map((index) => <span className="bankNotification__bankDots"></span>);
  }, []);

  const notificationData = useMemo(() => {
    return {
      title: `Bank account ${
        data?.data?.actionStatus === "ADDED" ? "added" : "removed"
      }`,
      type: data?.data?.actionStatus,
      time: data?.data?.createdAt
        ? `${new Date(data?.data?.createdAt).getDate()} ${new Date(
            data?.data?.createdAt
          ).toLocaleDateString("en-US", {
            month: "short",
          })}, ${new Date(data?.data?.createdAt).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}`
        : "-",

      userName: data?.data?.metadata?.name,
      readStatus: data?.data?.readStatus,
      userAvatar: data?.data?.metadata?.profileUrl,
      transactionId: data?.data?.metadata?.transactionId,
      notificationId: data?.data?._id,
      bank: data?.data?.metadata?.bankName,
      accountNumber: data?.data?.metadata?.accountNumber,
      amount: data?.data?.metadata?.amount,
    };
  }, [
    data?.data?._id,
    data?.data?.actionStatus,
    data?.data?.createdAt,
    data?.data?.metadata?.accountNumber,
    data?.data?.metadata?.amount,
    data?.data?.metadata?.bankName,
    data?.data?.metadata?.name,
    data?.data?.metadata?.profileUrl,
    data?.data?.metadata?.transactionId,
    data?.data?.readStatus,
  ]);

  const handleNotificationClick = useCallback((id: string) => {
    setVisibleNotification(false);
    if(notificationData?.readStatus==="UNREAD"){
    updateMarkAsRead({ id: id });
    }
    navigate("/wallet/accounts");
  }, [navigate, notificationData?.readStatus, setVisibleNotification, updateMarkAsRead]);

  return (
    <div
      className="notificationSection"
      onClick={() => handleNotificationClick(notificationData?.notificationId)}
    >
      <div className="bankNotification">
        <div className="bankNotification__left">
          <div className="bankNotification__left__icon">
            <i
              className={`ri-bank-fill ${
                notificationData?.type === "ADDED" ? "add" : "remove"
              }`}
            />
          </div>
          {notificationData?.readStatus === "UNREAD" && (
            <div className="bankNotification__left__readMark"></div>
          )}
          <div>
            <div className="bankNotification__left__title">
              {notificationData?.title}
            </div>
            <div className="bankNotification__left__Subtitle">
              {notificationData.bank} ({maskDots(4)}
              {notificationData?.accountNumber})
            </div>
            <div className="bankNotification__left__userDetail">
              {notificationData?.userAvatar ? (
                <img
                  className="bankNotification__left__userDetail__userImg"
                  src={notificationData?.userAvatar}
                  alt="profile_image"
                />
              ) : (
                <Image
                  className="bankNotification__left__userDetail__userImg"
                  fileName={"icon/avatar.svg"}
                />
              )}
              <div className="bankNotification__left__userDetail__userName">
                {notificationData?.userName}
              </div>
            </div>
          </div>
        </div>

        <div className="bankNotification__right">
          <div className="bankNotification__right__time">
            {notificationData?.time}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankNotification;
