import React, { useCallback, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useCurrency } from "@hooks/currency";
import { getDateWithFormatTime } from "@utils/getTime";
import { userPersonalDetails } from "@states/user";
import { useNotification } from "@hooks/notification";
import { ORDER_TYPE } from "../constants";
import { ActiveCurrencyState } from "states";
import {
  SelectedOrderState,
  VisibleCancelState,
  VisibleModifyState,
} from "@states/Orders";
import classNames from "classnames";
import {
  capitalizeFirstLetterOnly,
} from "@utils/common";

import styles from "./OrderTable.module.sass";
import { assestType, gov_guaranteed_loan, ORDERBOOK_SERIES } from "@views/exchange/constants";
import { getCategoryLabel } from "@utils/format-number";
import { Json } from "../../../../../types/common";

type ITableData = {
  activeTab: string;
  ordersData:Json
};

const TableData = ({ activeTab, ordersData}: ITableData) => {
  const personalData = useRecoilValue(userPersonalDetails);
  const setVisibleModify = useSetRecoilState(VisibleModifyState);
  const setVisibleCancel = useSetRecoilState(VisibleCancelState);
  const setSelectedOrderState = useSetRecoilState(SelectedOrderState);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);

  const { formatCurrencyWithBillion, amountToBondPoints } = useCurrency();
  const { errorNotification } = useNotification();

  const handleCancelOrder = useCallback(
    (id: any) => {
      if (personalData.isTradeEnabled === false) {
        errorNotification("Trading is not allowed");
      } else {
        setVisibleCancel(true);
        const orderData = ordersData["PENDING"].find(
          (item: any) => item.id === id
        );
        setSelectedOrderState(orderData);
      }
    },
    [errorNotification, ordersData, personalData.isTradeEnabled, setSelectedOrderState, setVisibleCancel]
  );

  const handleModifyOrder = useCallback(
    (id: any) => {
      if (personalData.isTradeEnabled === false) {
        errorNotification("Trading is not allowed");
      } else {
        setVisibleModify(true);
        const orderData = ordersData["PENDING"].find(
          (item: any) => item.id === id
        );
        setSelectedOrderState(orderData);
      }
    },
    [errorNotification, ordersData, personalData.isTradeEnabled, setSelectedOrderState, setVisibleModify]
  );
  const getSymbol = (symbol:any)=>{
    const arr=symbol?.split("");
    if(arr?.includes(".")){
      return (symbol)?.split(".")[1]
    }else{
      return symbol;
    }
  }

  const isOrderbook = activeCurrency?.sbaType === ORDERBOOK_SERIES;
  const isGovGuarunteed = activeCurrency?.loanType === gov_guaranteed_loan;

  const orderTable = useMemo(
    () =>
      (ordersData[activeTab] || [])?.map((asset: any, index: number) => (
        <tr className={`${styles.OrderTableRow} `} key={index}>
          <td className={classNames(styles.symbol, styles.openOrderHeading)}>
            {getSymbol(asset?.symbol)}
          </td>

          <td className={classNames(styles.orderType, styles.openOrderHeading)}>
            <div className={styles.OrderTypeContainer}>
              <div
                className={classNames(styles.dotColor, {
                  [styles.buy]: asset?.type === "BUY",
                  [styles.sell]: asset?.type === "SELL",
                })}
              ></div>{" "}
              {capitalizeFirstLetterOnly(asset?.type)}
            </div>
          </td>

          <td className={classNames(styles.assetType, styles.openOrderHeading)}>
            {getCategoryLabel(asset?.category)}
          </td>
          <td className={classNames(styles.assetQty, styles.openOrderHeading)}>
            {asset?.quantity}
          </td>
          {activeCurrency?.type !== "sba7" && (
            <td className={styles.openOrderHeading}>
              {formatCurrencyWithBillion(asset?.price, 4)}
            </td>
          )}
          <td className={styles.openOrderHeading}>
            {asset?.orderdate ? getDateWithFormatTime(asset?.orderdate) : "-"}
          </td>

          <td className={styles.openOrderHeading}>
            {activeCurrency?.type === assestType.SBA7 &&
              Number(amountToBondPoints(
                isOrderbook ?  isGovGuarunteed ? asset?.government_guaranteed_loan_balance : asset?.current_loan_balance
                : activeCurrency?.currentLoanBalance,
                asset?.totalamount
              ))}{" "}
            {activeCurrency?.type === assestType.SBA7
              ? `(${formatCurrencyWithBillion(asset?.totalamount, 4)})`
              : formatCurrencyWithBillion(asset?.totalamount, 4)}
          </td>
          {!ordersData[ORDER_TYPE.CANCELLED] && (
            <td
              className={classNames(
                styles.orderActions,
                styles.openOrderHeading
              )}
            >
              {" "}
              <div className={styles.col}>
                <div className={styles.actions}>
                  {activeCurrency?.type !== "sba7" && (
                    <>
                      <div
                        className={`${styles.link}  ${
                          ordersData[ORDER_TYPE.PENDING] ||
                          asset?.polygonData?.transaction?.hash === ""
                            ? styles.disable
                            : styles.cancel
                        }`}
                        onClick={(e) => {
                          if (
                            !ordersData[ORDER_TYPE.PENDING] &&
                            asset?.polygonData?.transaction?.hash !== ""
                          )
                            window.open(
                              `${asset?.polygonData?.transaction?.url}`,
                              "_blank"
                            );
                        }}
                      >
                        Transaction hash
                      </div>
                      <div
                        className={`${styles.link}  ${
                          !asset?.polygonData?.approval?.hash
                            ? styles.disable
                            : styles.cancel
                        }`}
                        onClick={() => {
                          if (asset?.polygonData?.approval?.hash)
                            window.open(
                              `${asset?.polygonData?.approval?.url}`,
                              "_blank"
                            );
                        }}
                      >
                        Approve hash
                      </div>
                    </>
                  )}
                  {ordersData[ORDER_TYPE.PENDING] && (
                    <>
                      <button
                        className={`${styles.link} ${styles.cancel}`}
                        onClick={() => handleCancelOrder(asset?.id)}
                      >
                        Cancel
                      </button>
                      <button
                        className={`${styles.link} ${styles.modify}`}
                        onClick={() => handleModifyOrder(asset?.id)}
                      >
                        Modify
                      </button>
                    </>
                  )}
                </div>
              </div>
            </td>
          )}
        </tr>
      )),
    [
      activeCurrency?.currentLoanBalance,
      activeCurrency?.type,
      activeTab,
      amountToBondPoints,
      formatCurrencyWithBillion,
      handleCancelOrder,
      handleModifyOrder,
      ordersData,
    ]
  );

    return (
    <table>
      <thead className={styles.orderTable}>
        <tr>
          <th className={classNames(styles.symbol, styles.openOrderHeading)}>
            Symbol
          </th>
          <th className={classNames(styles.orderType, styles.openOrderHeading)}>
            Order type
          </th>
          <th className={classNames(styles.assetType, styles.openOrderHeading)}>
            Asset type
          </th>
          <th className={classNames(styles.assetQty, styles.openOrderHeading)}>
            Qty{" "}
          </th>
          {activeCurrency?.type !== "sba7" && (
            <th className={styles.openOrderHeading}>Price (USD)</th>
          )}

          <th className={styles.openOrderHeading}>Time</th>

          <th className={styles.openOrderHeading}>
            {activeCurrency?.type === "sba7"
              ? "Price in bond points (USD)"
              : "Total (USD)"}
          </th>

          {!ordersData[ORDER_TYPE.CANCELLED] && (
            <>
              {!(
                activeCurrency?.type === assestType?.SBA7 &&
                ordersData[ORDER_TYPE.EXECUTED]
              ) && (
                <th
                  className={classNames(
                    styles.orderActions,
                    styles.openOrderHeading
                  )}
                >
                  Actions
                </th>
              )}
            </>
          )}
        </tr>
      </thead>
      <tbody>{ordersData[activeTab]?.length > 0 && orderTable}</tbody>
    </table>
  );
};

export default TableData;
