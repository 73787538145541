import React, { useState, useEffect, useMemo } from "react";

const StaticFileServe = () => {
  const [fileContent, setFileContent] = useState<any>();

  useEffect(() => {
    const fetchFileContent = async () => {
      try {
        const response = await fetch("/.well-known/apple-app-site-association");
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.text();
        setFileContent(data);
      } catch (error) {
        console.error("Error fetching file content:", error);
      }
    };

    fetchFileContent();
  }, []);

  const render = useMemo(() => {
    return <>{fileContent}</>;
  }, [fileContent]);

  return <div>{render}</div>;
};

export default StaticFileServe;
