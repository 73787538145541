import { useCallback } from "react";

import { INDEXED_DB_CONFIGURATION, objectStores } from "./constant";
import { createObjectStore, useIndexedDBInstance } from "./indexed-db";
import { DB, Store } from "./types";
import { validateBeforeTransaction } from "./utils";

interface IIndexedDB {
  dbName?: string;
  version?: number;
  currentStore: Store;
}

interface IndexedDBProps {
  name: DB;
  dbVersion: number;
}

export const initDB = ({ name, dbVersion }: IndexedDBProps) => {
  Object.freeze(INDEXED_DB_CONFIGURATION);
  createObjectStore(name, dbVersion, objectStores);
};

export const useIndexedDB = ({
  currentStore,
  dbName = INDEXED_DB_CONFIGURATION.dbName,
  version = INDEXED_DB_CONFIGURATION.version,
}: IIndexedDB) => {
  const {
    openDatabase,
    createReadWriteDatabaseTransaction,
    createReadonlyDatabaseTransaction,
  } = useIndexedDBInstance(dbName, version);

  // CRUD operation for INDEXED DB
  const get = useCallback(
    <T>(key: string) => {
      // get all
      return new Promise<T>((resolve, reject) => {
        openDatabase().then((db) => {
          return new Promise((_, rej) => {
            validateBeforeTransaction(db, currentStore, rej);
            const { store } = createReadonlyDatabaseTransaction(
              db,
              currentStore,
              resolve,
              reject
            );
            const request = store.get(key);

            request.onerror = (error) => reject(error);

            request.onsuccess = (event: any) => {
              resolve(event.target.result);
            };
          });
        });
      });
    },
    [createReadonlyDatabaseTransaction, currentStore, openDatabase]
  );

  const add = useCallback(
    <T>(value: T, key?: string) => {
      return new Promise<T>((resolve, reject) => {
        openDatabase().then((db) => {
          const { store } = createReadWriteDatabaseTransaction(
            db,
            currentStore,
            resolve as any,
            reject
          );

          const request = store.add(value, key);

          request.onerror = (error) => reject(error);

          request.onsuccess = (event: any) => {
            const resolveKey = event.target.result;
            resolve(resolveKey);
          };
        });
      });
    },
    [createReadWriteDatabaseTransaction, currentStore, openDatabase]
  );

  const update = useCallback(
    <T>(value: T, key?: string) => {
      return new Promise<T>((resolve, reject) => {
        openDatabase().then((db) => {
          return new Promise((_, rej) => {
            validateBeforeTransaction(db, currentStore, rej);
            const { transaction, store } = createReadWriteDatabaseTransaction(
              db,
              currentStore,
              resolve as any,
              reject
            );

            transaction.oncomplete = (event: any) => resolve(event);

            store.put(value, key);
          });
        });
      });
    },
    [createReadWriteDatabaseTransaction, currentStore, openDatabase]
  );

  const clear = useCallback(() => {
    return new Promise<any>((resolve, reject) => {
      openDatabase().then((db) => {
        return new Promise((_, rej) => {
          validateBeforeTransaction(db, currentStore, rej);
          objectStores.forEach((objectStore) => {
            const { store, transaction } = createReadWriteDatabaseTransaction(
              db,
              objectStore.store,
              resolve as any,
              reject
            );

            transaction.oncomplete = () => resolve("");

            store.clear();
          });
        });
      });
    });
  }, [createReadWriteDatabaseTransaction, currentStore, openDatabase]);

  const deleteKey = useCallback(
    (key: string) =>
      new Promise<any>((resolve, reject) => {
        openDatabase().then((db) => {
          return new Promise((_, rej) => {
            validateBeforeTransaction(db, currentStore, reject);
            const { store } = createReadWriteDatabaseTransaction(
              db,
              currentStore,
              resolve as any,
              reject
            );
            const request = store.delete(key);

            request.onsuccess = (event) => resolve(event);
          });
        });
      }),
    [openDatabase, currentStore, createReadWriteDatabaseTransaction]
  );

  // CRUD operation for INDEXED DB
  const getAllIndexDbKeys = useCallback(<T>() => {
    // get all
    return new Promise<T>((resolve, reject) => {
      openDatabase().then((db) => {
        return new Promise((_, rej) => {
          validateBeforeTransaction(db, currentStore, rej);
          const { store } = createReadonlyDatabaseTransaction(
            db,
            currentStore,
            resolve,
            reject
          );
          const request = store.getAllKeys();
          request.onerror = (error) => reject(error);

          request.onsuccess = (event: any) => {
            resolve(event.target.result);
          };
        });
      });
    });
  }, [createReadonlyDatabaseTransaction, currentStore, openDatabase]);

  // TODO
  const getById = useCallback(() => {
    // get by id
  }, []);
  const openCursor = useCallback(() => {
    // open cursor
  }, []);
  const getByIndex = useCallback(() => {
    // get by index
  }, []);

  return {
    add,
    get,
    update,
    clear,
    deleteKey,
    getAllIndexDbKeys,
  };
};
