export const AssetsInfoMusic = [
  {
    key: "streams",
    header_label: "Streams on popular platforms",
    data: [
      {
        key: "spotify_count",
        label: "Spotify",
      },
      {
        key: "youtube_count",
        label: "Youtube",
      },
      {
        key: "itunes_count",
        label: "Apple Music",
      },
      {
        key: "prime_music_count",
        label: "Amazon Music",
      },
    ],
  },
  {
    key: "states",
    header_label: "States",
    data: [
      {
        key: "artistIncome",
        label: "Total amount paid to artist",
      },
      {
        key: "investorCount",
        label: "Number of Investors",
      },
      {
        key: "paidToInvestor",
        label: "Total amount paid to investors",
      },
      {
        key: "changeInLastMonth",
        label: "% change in last month",
      },
    ],
  },
  {
    key: "dividend",
    header_label: "Dividend",
    data: [
      {
        key: "2021_Q1",
        label: "2021 Q1",
      },
      {
        key: "2021_Q2",
        label: "2021 Q2",
      },
      {
        key: "2021_Q3",
        label: "2021 Q3",
      },
      {
        key: "2021_Q4",
        label: "2021 Q4",
      },
      {
        key: "2022_Q1",
        label: "2022 Q1",
      },
      {
        key: "2022_Q2",
        label: "2022 Q2",
      },
      {
        key: "2022_Q3",
        label: "2022 Q3",
      },
      {
        key: "2022_Q4",
        label: "2022 Q4",
      },
    ],
  },
];
