import { useEffect, useState, FC, useCallback, MouseEvent } from "react";
import { useRecoilValue } from "recoil";

import { useWatchlist } from "hooks";
import { WatchlistState } from "states";

import styles from "./addWatchlist.module.sass";

type IPorps = {
  item: any;
  assetType?: string;
  from?: string;
};

export const AddWatchlist: FC<IPorps> = ({ item, assetType, from }) => {
  //globle state
  const watchlistData = useRecoilValue(WatchlistState);

  //local State
  const [isAdded, setAdded] = useState(false);

  //hook
  const { addToWatchlist, removeFromWatchlist } = useWatchlist();

  const { symbol } = item ?? {};

  useEffect(() => {
    if (watchlistData) {
      const symbolList = watchlistData.map((item) => item.symbol);
      const indexOfSymbol = symbolList.indexOf(symbol);
      if (indexOfSymbol !== -1) {
        return setAdded(true);
      }
      return setAdded(false);
    }
  }, [watchlistData, symbol]);

  const [asset_id, setAsset_id] = useState("");

  useEffect(() => {
    const asset = watchlistData?.find(
      (Item: { symbol: string }) => Item.symbol === symbol
    );
    if (asset && asset._id) {
      setAsset_id(asset._id);
    }
  }, [symbol, watchlistData]);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (!isAdded) {
        addToWatchlist(item, assetType, setAdded, from);
      } else {
        removeFromWatchlist(asset_id, setAdded);
      }
    },
    [
      addToWatchlist,
      assetType,
      asset_id,
      from,
      isAdded,
      item,
      removeFromWatchlist,
    ]
  );

  return (
    <>
      <div className={styles.addWatchlistIcon} onClick={handleClick}>
        <i
          className={`ri-star-s-fill ${styles.icon} ${
            isAdded ? styles.iconAdded : styles.iconRemoved
          }`}
        />
      </div>
    </>
  );
};
