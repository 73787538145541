import { IDropdown } from "./type";

import { useState, FC, useRef } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";

import { Icon } from "components";

import styles from "./Dropdown.module.sass";
import { Json } from "types";
import InfiniteScroll from "react-infinite-scroll-component";
import { v4 as uuidv4 } from "uuid";

export const Dropdown: FC<IDropdown> = ({
  className,
  classLabel,
  value,
  setValue,
  options = [],
  label,
  classDropdownHead,
  classDropdownArrow,
  classDropdownBody,
  classDropdownOption,
  positionDropDown = "bottom",
  autoDirection = true,
  handleFetchMore = ()=> {},
  offsetValue = 0,
  filters = {},

}) => {
  const [visible, setVisible] = useState(false);
  const [positionStyle, setPositionStyle] = useState<string>("");
  const bodyRef = useRef<any>(null);

  const handleClick = (value: string | number) => {
    setValue(value);
    setVisible(false);
  };
  const uniqueID = uuidv4();
  const getOptions = () => {
    const data = options?.map((option: any, index: number) => {
      if (typeof option === "object") {
        const { label, value: optionValue } = option;
        return (
          <div
            className={cn(classDropdownOption, styles.option, {
              [styles.selectioned]: optionValue === value,
            })}
            onClick={() => handleClick(optionValue)}
            key={index}
          >
            {label}
          </div>
        );
      } else {
        return (
          <div
            className={cn(classDropdownOption, styles.option, {
              [styles.selectioned]: option === value,
            })}
            onClick={() => handleClick(option)}
            key={index}
          >
            {option}
          </div>
        );
      }
    });
    return data;
  };

  const getDropDownValue = () => {
    if (typeof options?.[0] === "object") {
      return (options as Json)?.find((item: any) => item.value === value)
        ?.label;
    } else {
      return value;
    }
  };

  const handleToggleOptions = () => {
    let style = "bottom";
    let element = bodyRef.current;
    if (
      autoDirection &&
      window.innerHeight - element.getBoundingClientRect().bottom <
      element.scrollHeight
    ) {
      style = "top";
    }
    setPositionStyle(style);
    setVisible(!visible);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && <div className={cn(classLabel, styles.label)}>{label}</div>}
      <div
        className={cn(styles.dropdown, className, {
          [styles.active]: visible,
        })}
      >
        <div
          className={cn(classDropdownHead, styles.head)}
          onClick={() => handleToggleOptions()}
        >
          <div className={styles.selection}>{getDropDownValue()}</div>
          <div className={cn(styles.arrow, classDropdownArrow)}>
            <Icon name="arrow-down" size="24" />
          </div>
        </div>
        <div
          ref={bodyRef}
          className={cn(classDropdownBody, styles.body, {
            [styles.bodyTop]:
              positionStyle === "top" || positionDropDown === "top",
          })}
          id= {uniqueID}
        >
          <InfiniteScroll
              dataLength={options?.length || 0}
              next={handleFetchMore}
              hasMore={
                options?.length >=
                offsetValue * filters?.limit + filters?.limit
              }
              loader={""}
              scrollableTarget={uniqueID}
          >
              {getOptions()}
          </InfiniteScroll>
        </div>
      </div>
    </OutsideClickHandler>
  );
};
