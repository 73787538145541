import { FullStory } from '@fullstory/browser';

export const useFullStoryTrackEvent = () => {
  const trackEvent = (eventName: string, properties: Record<string, any>): void => {
    FullStory('trackEvent', {
      name: eventName,
      properties,
    });
  };

  const trackPageEvent = (properties: Record<string, any>): void => {
    FullStory('setProperties', {
      type: 'page',
      properties: properties
    });
  };
  const setUserIdentifire =( uid: string, properties: Record<string, any> ): void => {
    FullStory('setIdentity', {
      uid: uid,
      properties
    });
  }

  return { trackEvent, trackPageEvent, setUserIdentifire };
};
