import * as XLSX from 'xlsx';

export const jsonToBase64 = async (jsonData: any, fileName: string) => {
  const worksheet = XLSX?.utils.json_to_sheet(jsonData);
  const workbook = XLSX?.utils.book_new();
  XLSX?.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const wbout = XLSX?.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([wbout], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  return new File([blob], fileName, {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
};

export const convertFileToJson = async (file: any) => {
  const arrayBuffer = await file.arrayBuffer();
  const data = new Uint8Array(arrayBuffer);
  const workbook = XLSX.read(data, { type: 'array' });
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];
  const json = XLSX.utils.sheet_to_json(worksheet);
  return json;
};

export const downloadExcel = async (data: any, fileName = 'BidSheet.xlsx', addKeys: boolean = false) => {
  if (!data) {
    console.error("Invalid data");
    return;
  } else {    
    const worksheet = XLSX?.utils.json_to_sheet(data);
    const workbook = XLSX?.utils.book_new();
    XLSX?.utils.book_append_sheet(workbook, worksheet, fileName);

    const excelBuffer = XLSX?.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    downloadToDevice(excelBuffer, fileName)
  }
};


export const downloadToDevice = (excelBuffer: any, fileName: string) => {
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const DownloadFile = (linkurl: string = "") => {
    const link = document.createElement('a');
    link.href = linkurl; // Replace with your GCP link
    link.setAttribute('download', 'filename.ext'); // Replace 'filename.ext' with the desired file name and extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

// Function to remove class from all cells
export const removeClassFromAllCells = (table: any, className: string) => {
    for (let row of table.rows) {
      for (let cell of row.cells) {
        cell.classList.remove(className);
      }
    }
  };
  
  // Function to add class to the second column cells
  export const disableColumn = (table: any, className: string, columnNumber: number) => {
    try{
      for (let row of table.rows) {
        if (row.cells.length > 1) {
          row.cells[columnNumber].classList.add(className);
        } else {
          console.log(`Row ${row.rowIndex + 1} does not have a second cell.`);
        }
      }
    }
    catch{
      console.log("err in disable column")
    }
    
  };

  export const disableRow = (table: any, className: string, rowIndex: number) => {
    let row = table.rows[rowIndex];
    try{
      if (row && row.cells.length > 1) {
        for (let cell of row.cells) {
          cell.classList.add(className);
        }
      }
    }
    catch{
      console.log("err in disable row")
    }
  };