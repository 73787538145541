import { useEffect, useState } from "react";

import { AuctionDataRoom } from "@views/AuctionConsumer/components";
import { BidSheetLoan } from "@views/auction/create-auction/components/BidSheetLoan";

import "./sbaData.scss";
import { ActiveCurrencyState } from "@states/exchange";
import { useRecoilValue } from "recoil";

export const SbaDataRoom = () => {
  const [auctionData, setAuctionData] = useState<any>({});
  const activeCurrency = useRecoilValue(ActiveCurrencyState);

  useEffect(() => {
    setAuctionData({
      data: { ...activeCurrency, dataRoom: activeCurrency?.dataRoomFiles },
    });
  }, [activeCurrency]);
  const { data: auctionDetails = {} } = auctionData;

  return (
    <div className="auction-details-container sba-details-container">
      <AuctionDataRoom auctionDetails={auctionData} sba={true} />
      {!!activeCurrency?.bidSheetJSON && !!activeCurrency?.sba1502JSON && (
        <BidSheetLoan auctionDetails={auctionDetails} />
      )}
    </div>
  );
};
