import { APIS } from "constant";
import { useNetwork } from "hooks";
import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { AllTransactionsData, FortressAccountInfo } from "../state";
import { Json } from "../../../../types/common";
import { getJsonToParams } from "@utils/common";

export const UseTransaction = () => {
  const { get: getAllTransactions, loading: transactionLoader } = useNetwork();
  const { get: getAccountInfo } = useNetwork();
  const setAllTransaction = useSetRecoilState(AllTransactionsData);
  const setFortressAccountInfo = useSetRecoilState(FortressAccountInfo);

  const getTransactions = useCallback((query: Json = {}, options?: any,setOrdersData?:any) => {
    setAllTransaction((prev: any) => {
      return {
        ...prev,
        data: !options?.updateNewData? [] : prev.data,
        loading:true
      };
    });

    getAllTransactions(APIS.TRANSACTION + getJsonToParams(query), {signal: options?.signal}).then((res: Json) => {
      if (res) {
        if (!options?.updateNewData) {
          setAllTransaction({
            loading: false,
            data: [...res?.data],
            offset: query.offset,
            limit: query.limit,
          });
        } else {
          setAllTransaction((prev: any) => ({
            ...prev,
            loading: false,
            data: [...prev?.data, ...res?.data],
            offset: query.offset,
            limit: query.limit,
          }));
        }
      }
    });
  }, [getAllTransactions, setAllTransaction]);

  const getFortressAccountInfo = useCallback(() => {
    getAccountInfo(`${APIS.ACCOUNTINFO}`).then((res) => {
      if (res) {
        setFortressAccountInfo(res?.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    getTransactions,
    transactionLoader,
    getFortressAccountInfo,
  };
};
