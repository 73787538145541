import { atom } from "recoil";

export interface IWaitlist {
  phone: string;
  countryCode: string | number;
  email: string;
}

export const WaitlistState = atom<IWaitlist>({
  key: "waitlist-state",
  default: {
    phone: "",
    countryCode: "+1",
    email: "",
  },
});
