export const ORDERS_PAGE_TABS = [
  { title: "Open", type: "PENDING" },
  { title: "Executed", type: "EXECUTED" },
  { title: "Cancelled", type: "CANCELLED" },
];

export const ORDER_TYPE: any = {
  PENDING: "PENDING",
  EXECUTED: "EXECUTED",
  CANCELLED: "CANCELLED",
};

export const emptyOrder = {
  NO_OPEN_ORDER: "No Open Orders",
  NO_EXECUTED_ORDER: "No Executed Orders",
  NO_CANCELLED_ORDER: "No Cancelled Orders",
};

export const getTransactionIcon: any = {
  PENDING: "ri-time-fill",
  EXECUTED: "ri-checkbox-circle-fill",
  CANCELLED: "ri-close-circle-fill",
};
