import { ISelectedFundRecipientBank, ISelectedFundRecipientBankAccount } from "./type";

import { atom } from "recoil";

export const AccountState = atom<any[]>({
  key: "account-state",
  default: [],
});

export const CardState = atom<any[]>({
  key: "card-state",
  default: [],
});

export const DepositAmountState = atom<number | string>({
  key: "deposit-amount-state",
  default: "",
});

export const DepositNoteState = atom<string>({
  key: "deposit-note-state",
  default: "",
});

export const WithdrawAmountState = atom<number | string>({
  key: "withdraw-amount-state",
  default: "",
});

export const BalanceAmountState = atom<number>({
  key: "balance-amount-state",
  default: 10098.36,
});

export const SelectedFundRecipientBankAccount = atom<ISelectedFundRecipientBankAccount | undefined>(
  {
    key: "selected-fund-recipient-bank-account",
    default: undefined,
  },
);

export const SelectedFundRecipientBank = atom<ISelectedFundRecipientBank | undefined>({
  key: "selected-fund-recipient-bank",
  default: undefined,
});

export const depositStepState = atom<string>({
  key: "deposit-step-state",
  default: "amount",
});

export const WireTransactionDetailsState = atom<any>({
  key: "WireTransactionDetailsState",
  default: {},
});
export const IsEnoghBalance = atom<any>({
  key: "IsEnoghBalance",
  default: false
});

export const DepositModalOpenState = atom<boolean>({
  key: "DepositModalOpenState",
  default: false,
});

export const WithdrawModalOpenState = atom<boolean>({
  key: "WithdrawModalOpenState",
  default: false,
});