import { Json } from "../../types";

export const USER_BID_LIST_TABS = [
  { title: "My bids", type: "myAuction" },
  { title: "Buy", type: "buy" },
  { title: "Wishlist", type: "wishlist" },
];

export const AUCTION_DATA_ROOM_TABS = [
  { title: "Files", type: "files" },
  { title: "Edit History", type: "editHistory" },
];

export const BID_ACTION_TABS = [
  { title: "Bid now", type: "bidNow" },
  { title: "Buy now", type: "buyNow" },
];

export const USER_BID_STATUS_COLOR: any = {
  live: "#33B87A",
  completed: "#747E99",
  cancelled: "#747E99",
  won: "#3772FF",
  outbid: "#E11414",
  "max-budget-set": "#33B87A",
  "highest-bid": "#33B87A",
};

export const AUCTION_FILTERS: Json[] = [
  {
    header: "Auction Type",
    key: "tradeType",
    options: [
      { label: "Classic", value: "classic" },
      { label: "Dutch", value: "dutch" }
    ],
  }, {
    header: "Status",
    key: "status",
    options: [
      { label: "Yet to start", value: "yet-to-start" },
      { label: "Live", value: "live" },
      { label: "Completed", value: "completed" }
    ],
  }
]

export const USER_BID_TABLE_HEADERS = [
  {
    label: "Product ID & Name",
    key: "productName",
    width: "15%",
    format: "jsx",
  },
  {
    label: "Current Bid",
    key: "currentPrice",
    width: "5%",
    format: "jsx",
  },
  {
    label: "My Bid",
    key: "price",
    width: "5%",
    format: "jsx",
  },
  {
    label: "Time Left",
    key: "timeLeft",
    width: "10%",
    format: "jsx",
  },
  {
    label: "Your Bid Status",
    key: "bidStatus",
    width: "5%",
    format: "jsx",
  },
  {
    label: "Auction Status",
    key: "status",
    width: "5%",
    format: "jsx",
  },
  {
    label: "Actions",
    key: "action",
    width: "12%",
    format: "jsx",
  },
];

export const LOAN_NAME: any = {
  "whole_loans" : "Whole Loans",
  "current_guaranteed_loan_balance": "Gov. Guaranteed Portions"
}
