import { FC, useMemo } from "react";
import useDarkMode from "use-dark-mode";
import cn from "classnames";

import "./skeleton-loader.scss";
import styles from "./Skelton.module.sass";

interface ISkeletonWatchList {
  listsToRender: number;
}

export const SkeletonWallet: FC<ISkeletonWatchList> = ({ listsToRender }) => {
  const darkMode = useDarkMode(false);
  const skeletonLoader = useMemo(() => {
    return Array(listsToRender)
      .fill(1)
      .map((card, index) => (
        <div
          key={`watchList__${index}`}
          className={cn("skeleton_Wallet", styles.skeltonWallet)}
          style={{
            borderBottom: darkMode.value
              ? "1px solid #23262F"
              : "1px solid #e6e8ec",
          }}
        >
          <div
            className={cn("skeleton_Wallet_star", {
              "skeleton-loader-dark": darkMode.value,
              "skeleton-loader-light": !darkMode.value,
            })}
          />
          <div className="skeleton_WatchList_liner">
            <div className="skeleton_Wallet_container">
              <div
                className={cn("skeleton_WatchList_symbol", {
                  "skeleton-loader-dark": darkMode.value,
                  "skeleton-loader-light": !darkMode.value,
                })}
              />
              <div className="skeleton_account_data">
                <div
                  className={cn("skeleton_WatchList_amount", {
                    "skeleton-loader-dark": darkMode.value,
                    "skeleton-loader-light": !darkMode.value,
                  })}
                />
                <div
                  className={cn("skeleton_WatchList_amount", {
                    "skeleton-loader-dark": darkMode.value,
                    "skeleton-loader-light": !darkMode.value,
                  })}
                />
                <div
                  className={cn("skeleton_WatchList_amount", {
                    "skeleton-loader-dark": darkMode.value,
                    "skeleton-loader-light": !darkMode.value,
                  })}
                />
                <div
                  className={cn("skeleton_WatchList_amount", {
                    "skeleton-loader-dark": darkMode.value,
                    "skeleton-loader-light": !darkMode.value,
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      ));
  }, [darkMode.value, listsToRender]);
  return <>{skeletonLoader}</>;
};

export const SkeletonCard: FC<ISkeletonWatchList> = ({ listsToRender }) => {
  const darkMode = useDarkMode(false);
  const skeletonLoader = useMemo(() => {
    return Array(listsToRender)
      .fill(1)
      .map((card, index) => (
        <div
          key={`watchList__${index}`}
          className={cn("skeleton_Wallet", styles.skeltonWallet)}
          style={{
            borderBottom: darkMode.value
              ? "1px solid #23262F"
              : "1px solid #e6e8ec",
          }}
        >
          <div
            className={cn("skeleton_Wallet_star", {
              "skeleton-loader-dark": darkMode.value,
              "skeleton-loader-light": !darkMode.value,
            })}
          />
          <div className="skeleton_WatchList_liner">
            <div className="skeleton_Wallet_container">
              <div
                className={cn("skeleton_WatchList_symbol", {
                  "skeleton-loader-dark": darkMode.value,
                  "skeleton-loader-light": !darkMode.value,
                })}
              />
              <div className="skeleton_account_data">
                <div
                  className={cn("skeleton_WatchList_amount", {
                    "skeleton-loader-dark": darkMode.value,
                    "skeleton-loader-light": !darkMode.value,
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      ));
  }, [darkMode.value, listsToRender]);
  return <>{skeletonLoader}</>;
};
