import { atom } from "recoil";

export interface IInfinityScrollState {
  search: null | string;
  offset: number;
  limit: number;
  loading: boolean;
  firstLoaded: boolean;
}
export interface IInfinityScrollStateorder {
  order: null | string;
  offset: number;
  limit: number;
  loading: boolean;
  firstLoaded: boolean;
}

export interface ISStateAtom {
  key: string;
  default: IInfinityScrollState;
}

const infinityDefault = {
  search: null,
  offset: 1,
  limit: 20,
  loading: false,
  firstLoaded: false,
};
const infinityDefaultHistory = {
  search: null,
  offset: 1,
  limit: 10,
  loading: false,
  firstLoaded: false,
};
const infinityDefaultorder = {
  order: null,
  offset: 1,
  limit: 10,
  loading: false,
  firstLoaded: false,
};

// this will be the config state that needs to be used everytime using the infinity scroll
export const ISMusicConfigState = atom<IInfinityScrollState>({
  key: "is-musics-config-state",
  default: { ...infinityDefault },
});

export const ISStockConfigState = atom<IInfinityScrollState>({
  key: "is-stocks-config-state",
  default: { ...infinityDefault },
});

export const ISCriptoConfigState = atom<IInfinityScrollState>({
  key: "is-crypto-config-state",
  default: { ...infinityDefault },
});

export const ISCommodityConfigState = atom<IInfinityScrollState>({
  key: "is-commodities-config-state",
  default: { ...infinityDefault },
});

export const ISPrivateConfigState = atom<IInfinityScrollState>({
  key: "is-privates-config-state",
  default: { ...infinityDefault },
});

export const ISForexConfigState = atom<IInfinityScrollState>({
  key: "is-forex-config-state",
  default: { ...infinityDefault },
});
export const ISOrderTableOpenConfigState = atom<IInfinityScrollState>({
  key: "is-order-table-open-config-state",
  default: { ...infinityDefaultHistory },
});
export const ISOrderTableExecuteConfigState = atom<IInfinityScrollState>({
  key: "is-order-table-execute-config-state",
  default: { ...infinityDefaultHistory },
});
