import { FC, ReactNode } from "react";

interface IAuthLayout {
  width: number;
  minHeight: number;
  children: ReactNode;
}

export const AuthLayout: FC<IAuthLayout> = ({ width, minHeight, children }) => {
  return (
    <div className="AuthLayout">
      <div className={`AuthLayout-Card`} style={{ width, minHeight }}>
        {children}
      </div>
    </div>
  );
};
