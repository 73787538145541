import { createRef, FC } from "react";
import type { ISideDrawer } from "./type";
import { useOutsideClick } from "hooks";

import "./drawer.scss";

export const SideDrawer: FC<ISideDrawer> = ({
  visible,
  setVisible,
  body,
  logo,
}) => {
  const ref = createRef<HTMLDivElement>();

  useOutsideClick(ref, () => {
    setVisible(false);
  });
  return (
    <div className={`drawer ${visible ? "drawer--open" : ""} `} ref={ref}>
      <div className="drawer__logo">{logo}</div>
      <div className="drawer__close-btn" onClick={() => setVisible(false)}>
        <i className="ri-menu-fold-line" />
      </div>
      <div className="drawer__body">{body}</div>
    </div>
  )
    ;
};
