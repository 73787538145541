import { useEffect } from "react";
import useDarkMode from "use-dark-mode";

export const BarChartMusic = ({title,subtitle,seriesbardata}:any) => {
  const darkMode = useDarkMode();

  useEffect(() => {
    (window as any).Highcharts?.chart("chartName", {
      chart: {
        type: "column",
        backgroundColor: "transparent",
        zooming: {
          mouseWheel: {
            enabled: false,
          },
        },
      },
      
      credits: {
        enabled: false,
      },
      exporting: { enabled: false },

      colors: ["#458BF5", "#F5AF45"],

      title: {
        text: title,
        align: "left",
        style: {
          color: darkMode.value ? "#FFFFFF" : "#000000",
          fontWeight: "600",
          fontSize: "14px",
        },
      },

      subtitle: {
        text: subtitle,
        align: "left",
        style: {
          color: "#878C99",
          fontWeight: "500",
          fontSize: "12px",
        },
      },

      xAxis: {
        categories: ["Q1", "Q2", "Q3", "Q4"],
        title: {
          text: "Date",
          style: {
            color: darkMode.value ? "#BCC3D6" : "#17191F",
          },
        },

        labels: {
          style: {
            color: darkMode.value ? "#878C99" : "#17191F",
          },
        },
        gridLineWidth: 0.6,
        gridLineColor: !darkMode.value
          ? "#BCC3D6"
          : "rgba(255, 255, 255, 0.06)",
      },

      yAxis: {
        min: 0,
        tickPixelInterval: 50,
        title: {
          text: "Amount Paid Per Share (USD)",
          style: {
            color: darkMode.value ? "#BCC3D6" : "#17191F",
          },
        },
        gridLineWidth: 0.6,
        gridLineColor: !darkMode.value
          ? "#BCC3D6"
          : "rgba(255, 255, 255, 0.06)",
        stackLabels: {
          enabled: false,
        },
        labels: {
          style: {
            color: darkMode.value ? "#878C99" : "#17191F",
          },
        },
      },

      legend: {
        symbolRadius: 0,
        title: {
          text: "Year",
          align: "left",
          style: {
            color: "#878C99",
            fontWeight: "500",
            fontSize: "12px",
          },
        },
        itemStyle: {
          color: darkMode.value ? "#FFFFFF" : "#000000",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "16px",
        },
        itemHoverStyle: {
          color: "#878C99",
        },
      },

      tooltip: {
        valueSuffix: "USD",
        backgroundColor: darkMode.value ? "#36383D" : "#F0F4FF",
        style: {
          color: darkMode.value ? "#FFFFFF" : "#17191F",
        },
      },

      navigation: {
        menuStyle: {
          border: "0px",
          boxShadow: "none",
        },
        buttonOptions: {
          theme: {
            "stroke-width": 1,
            r: 5,
            fill: "transparent",
            states: {
              hover: {
                fill: "rgb(167 172 160)",
              },
            },
          },
        },
      },

      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          borderRadius: 0,
          states: {
            inactive: {
              enabled: false,
            },
            hover: {
              enabled: false,
            },
          },
        },
        series: {
          maxPointWidth: 24,
          events: {
            legendItemClick: function (this: any) {
              return this.chart.series.some((item: any) =>
                this === item ? !item.visible : item.visible
              );
            },
          },
        },
      },

      series: seriesbardata
    });
  }, [darkMode.value,title,subtitle,seriesbardata]);

  return (
    <div
      style={{
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
        overflowX: "auto",
      }}
    >
      <div id="chartName"></div>
    </div>
  );
};
