import { User } from "components/Header/component";
import Theme from "components/Theme";

import styles from "./adminHeader.module.sass";
import { useCallback, useEffect, useState } from "react";
import { useNetwork } from "hooks";
import { useRecoilState } from "recoil";
import { userSelectedCurrencyState } from "states";
import { APIS } from "constant";
import Notification from "@views/Notification/Notification";
import NotificationBell from "@components/NotificationBell/NotificationBell";

interface IHeader {
  title: string;
  ref?: any;
  children?: JSX.Element;
  className?: string;
}

export const AdminHeader = ({ title, children, className }: IHeader) => {
  const [currency, setSelectedCurrency] = useRecoilState(
    userSelectedCurrencyState
  );
  const [visibleNotification, setVisibleNotification] = useState(false);

  const { get: getUser, data: userData } = useNetwork();

  const NotificationProps = {
    visibleNotification,
    setVisibleNotification,
  };

  const notification = <Notification />;

  useEffect(() => {
    if (!currency.code) {
      getUser(APIS.Users);
    }
  }, [currency.code, getUser]);

  useEffect(() => {
    if (userData?.data) {
      const { settings = {} } = userData.data;
      setSelectedCurrency(settings.currency ?? {});
    }
  }, [setSelectedCurrency, userData]);

  const handleClickNotification = useCallback(() => {
    return setVisibleNotification(true);
  }, []);

  return (
    <div className={`${styles.header} ${className || ""}`}>
      <div className={styles.titleAndNav}>
        <div className={styles.headerName}>{title}</div>
        {children}
      </div>
      <div className={styles.user_container}>
        {/* <div className={styles.headerBell}>
          <i className="ri-notification-3-line" />
        </div> */}
        <NotificationBell />
        <Theme className={styles.theme} icon small={undefined} />
        <User className={styles.user} />
      </div>
    </div>
  );
};
