import React, { useCallback, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import { Skeleton } from "components";
import { ActiveCurrencyState, AssetLoaderState } from "states";
import { AssetsInfoMusic } from "./constants";
import { useCurrency } from "hooks";

import styles from "./musicOverview.module.sass";

export const MusicOverview = () => {
  // globle states
  const {
    symbol,
    artist,
    album,
    statistics,
    dividend,
    totalInvestorPayment,
    totalArtistPayment,
    percentageChangeLastMonth,
    investorCount,
  } = useRecoilValue(ActiveCurrencyState);
  const assestLoaderState = useRecoilValue(AssetLoaderState);

  //local states
  const [showMore, setShowMore] = useState<boolean>(false);

  const { formatCurrencyWithBillion } = useCurrency();

  const showCell = (label: string, value: any) => {
    return (
      <div className={`${styles.cellbody}`}>
        <div style={{ display: "flex", gap: "4px" }}>
          <div className={`${styles.subHeading}`}>{label}</div>
        </div>

        <div className={`${styles.cellValue}`}>{value ?? "--"}</div>
      </div>
    );
  };
  const showCellStream = (label: string, value: any) => {
    return (
      <div className={`${styles.cellbody}`}>
        <div style={{ display: "flex", gap: "4px" }}>
          <i
            className={`ri-${label.toLowerCase()}-fill ${
              styles[label.toLowerCase()]
            }`}
          ></i>
          <div className={`${styles.subHeading}`}>{label}</div>
        </div>

        <div className={`${styles.cellValue}`}>{value ?? "--"}</div>
      </div>
    );
  };

  const getMillon = useCallback((value: number | string) => {
    if (isNaN(Number(value))) return "0.00";
    if (Number(value) >= 1000000) return Number(value) / 1000000 + "M";
    if (Number(value) < 1000000 && Number(value) >= 1000)
      return Number(value) / 1000 + "K";
    return value;
  }, []);

  const musicInfo = useCallback(
    (key: string) => {
      switch (key) {
        case "artistIncome":
          return getMillon(totalArtistPayment);
        case "investorCount":
          return getMillon(investorCount);
        case "paidToInvestor":
          return getMillon(totalInvestorPayment);
        case "changeInLastMonth":
          return `${percentageChangeLastMonth}%`;
        default:
          return "";
      }
    },
    [
      getMillon,
      totalArtistPayment,
      investorCount,
      totalInvestorPayment,
      percentageChangeLastMonth,
    ]
  );

  const quarterlyData = useMemo(() => {
    const result: { [key: string]: string } = {};

    dividend &&
      Object.entries(dividend).forEach(([year, quarters]: any) => {
        Object.entries(quarters || {}).forEach(([quarter, value]) => {
          const newKey = `${year}_${quarter.toUpperCase()}`;

          result[newKey] = formatCurrencyWithBillion(Number(value), 2);
        });
      });

    return result;
  }, [dividend, formatCurrencyWithBillion]);

  const renderMusicOverView = useMemo(
    () =>
      // TODO: simplify the following condition
      AssetsInfoMusic.map(({ key, header_label, data }) =>
        key === "streams" ? (
          <React.Fragment key={key}>
            <div className={`${styles.cellHead}`}>{header_label}</div>
            <div className={`${styles.cellWrapper}`}>
              {Object.keys(statistics || {})?.map((platform) => (
                <React.Fragment key={platform}>
                  {showCellStream(
                    platform[0].toUpperCase() + platform?.slice(1),
                    symbol === "" || assestLoaderState.IsLoading ? (
                      <Skeleton />
                    ) : statistics?.[platform]?.["plays"] ? (
                      getMillon(statistics?.[platform]?.["plays"])
                    ) : (
                      "--"
                    )
                  )}
                </React.Fragment>
              ))}
            </div>
          </React.Fragment>
        ) : key === "states" ? (
          <React.Fragment key={key}>
            <div className={`${styles.cellHead}`}>{header_label}</div>
            <div className={`${styles.cellWrapper}`}>
              {data.map(({ key: itemKey, label }) => (
                <React.Fragment key={label}>
                  {showCell(
                    label,
                    symbol === "" || assestLoaderState.IsLoading ? (
                      <Skeleton />
                    ) : (
                      musicInfo(itemKey)
                    )
                  )}
                </React.Fragment>
              ))}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={`${styles.cellHead}`}>{header_label}</div>
            <div className={`${styles.cellWrapper}`}>
              {data.map(({ key: itemKey, label }) => (
                <React.Fragment key={label}>
                  {showCell(
                    label,
                    symbol === "" || assestLoaderState.IsLoading ? (
                      <Skeleton />
                    ) : (
                      <span>
                        {quarterlyData[itemKey]}
                        <span className={styles.subData}>/share</span>
                      </span>
                    )
                  )}
                </React.Fragment>
              ))}
            </div>
          </React.Fragment>
        )
      ),
    [
      assestLoaderState.IsLoading,
      getMillon,
      musicInfo,
      statistics,
      quarterlyData,
      symbol,
    ]
  );

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={`${styles.heading}`}>{artist?.name || "--"}</div>
        <div className={`${styles.subHeading}`}>
          {symbol || "--"} <i className="ri-checkbox-blank-circle-fill" />{" "}
          {symbol === "" || assestLoaderState.IsLoading ? (
            <Skeleton />
          ) : (
            album?.[0]?.name || "--"
          )}
        </div>
        <div className={`${showMore ? styles?.description : styles?.sortDesc}`}>
          {artist?.description?.replace(/<[^>]+>/g, "") ?? ""}
        </div>
        {artist?.description?.length > 0 && (
          <button
            onClick={() => setShowMore(!showMore)}
            className={`${styles.readmore}`}
          >
            {showMore ? "show less" : "show more"}
          </button>
        )}
        {renderMusicOverView}
      </div>
    </>
  );
};
