import { FC, useMemo } from "react";
import useDarkMode from "use-dark-mode";
import cn from "classnames";

import "./skeleton-loader.scss";

interface ISkeletonTable {
  listsToRender: number;
  numberColumn: number;
  className?: string;
}

export const SkeletonTable: FC<ISkeletonTable> = ({
  listsToRender,
  numberColumn,
  className
}) => {
  const darkMode = useDarkMode(false);
  const skeletonLoader = useMemo(() => {
    return Array(listsToRender)
      .fill(1)
      .map((card, index) => (
        <div
          key={`table__${index}`}
          className={`skeleton_table ${className}`}
          style={{
            borderBottom: darkMode.value
              ? "1px solid #23262F"
              : "1px solid #e6e8ec",
          }}
        >
          {Array(numberColumn)
            .fill(1)
            .map((card, idx) => (
              <div
                key={`sub-table__${idx}`}
                className={cn("skeleton_table_symbol", {
                  "skeleton-loader-dark": darkMode.value,
                  "skeleton-loader-light": !darkMode.value,
                })}
              />
            ))}
        </div>
      ));
  }, [darkMode.value, listsToRender, numberColumn]);

  return <div className="skeleton_body">{skeletonLoader}</div>;
};
