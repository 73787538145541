import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCookie, useNetwork, useNotification, usePrevious } from "hooks";
import { ROUTES } from "routes";
import { ConfirmIdentity } from "./confirm-identity";
import styles from "./compliance.module.sass";
import {
  complianceDataState,
  signUpInfoState,
  loadingState,
  linkedDeviceState,
  userPersonalDetails,
  isStartVerificationNewState,
  reinitiateKycState,
  isIframeState,
} from "states";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { URL_HOST } from "helpers";
import { signupFormMessage } from "./constants";
import { APIS } from "constant";
import { KycReview } from "./kyc-review";
import { KycStart } from "./kyc-start";
import { Agree } from "./agree";

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
}

interface IDisabledFormData {
  firstName?: boolean;
  lastName?: boolean;
  email?: boolean;
}

interface IShowFormDataError {
  isFirstNameError: boolean;
  isLastNameError: boolean;
  isEmailError: boolean;
}

interface ICompliance {
  isEditingUserDetails?: boolean;
  handleSuccess?: () => void;
  handleClose?: () => void;
  disabledData?: IDisabledFormData;
}

export const Compliance = ({
  isEditingUserDetails = false,
  handleSuccess,
  disabledData,
  handleClose
}: ICompliance) => {
  const complianceData = useRecoilValue(complianceDataState);
  const devicelist = useRecoilValue(linkedDeviceState);
  const [reinitiateKyc, setReinitiateKyc] = useRecoilState(reinitiateKycState);
  const formData = useRecoilValue(signUpInfoState);
  const [isStartVerification, setIsStartVerification] = useRecoilState(
    isStartVerificationNewState
  );
  const prevUserData = usePrevious(complianceData);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const setUserLoading = useSetRecoilState(loadingState);
  const [personalData, setPersonalData] = useRecoilState(userPersonalDetails);
    const parentRef = useRef<HTMLDivElement | null>(null);
  const setIframeExists = useSetRecoilState(isIframeState);
  const [showFormDataError, setShowFormDataError] =
    useState<IShowFormDataError>({
      isFirstNameError: false,
      isLastNameError: false,
      isEmailError: false,
    });

  const { LOGIN } = ROUTES;
  const { deleteCookie } = useCookie();
  const { get: getCookieData } = useCookie();
  const { patch, data, loading } = useNetwork();
  const { errorNotification, successNotification } = useNotification();
  const { patch: removeLinkedDevice } = useNetwork();

  const logoutAction = () => {
    const registrations = JSON.parse(localStorage.exchageAppRegistration || "[]");
    localStorage.clear();
    deleteCookie("userDetails");
    deleteCookie("userDetails");
    window.location.href = LOGIN;
  };
  
    useEffect(() => {
      if (parentRef.current) {
        const iframe = parentRef.current.querySelector("iframe");
        setIframeExists(!!iframe);
      }
      else{
         setIframeExists(false);
      }
    }, [complianceData]);

  const onLogout = useCallback(() => {
    //remove all local storage expect but dont want to exchageAppRegistration
    //can not done one by one so keep in one varibale before delelating all localstorage
    //and create again localstorage with same key
    const userDetails = getCookieData("userDetails");
    
    const currentDeviceDetail = devicelist.find((dev: any) => dev?.deviceToken === userDetails?.token);
    const payload = { ids: [currentDeviceDetail?._id], origin: "web" };
    removeLinkedDevice(`${APIS.UsersDeviceLogin}/${currentDeviceDetail?.userId}`,payload, {apiResponse: true})
    .then(resp => {
      logoutAction();
    })
    .catch(err => {
      logoutAction();
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicelist]);

  const {
    firstNameMessage,
    lastNameMessage,
    emailMessage,
    InvalidEmailMessage,
  } = useMemo(() => signupFormMessage, []);

  const validateName = (name: string) => {
    const regex = /^[a-zA-Z]+$/i;
    return !(!name || regex.test(name) === false);
  };

  const validateEmail = (email: string) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return !(!email || regex.test(email) === false);
  };


  useEffect(() => {
    if (data) {
      if (data?.success) {
        setUserLoading(false);
        !!handleSuccess && handleSuccess();
        successNotification(data?.message);
      } else {
        setUserLoading(false);
        if (data?.message) {
          errorNotification(data?.message ?? "Something went wrong");
        } else {
          setIsConfirmed((prev) => !prev);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleProceed = useCallback(() => {
    const { firstName= "", lastName= "", email } = formData;

    // Initialize an array to store error messages
    const errorMessages = [];

    // if (firstName) {
    //   console.log("firstName", firstName)
    //   if (!validateName(firstName.trim())) {
    //     errorMessages.push("Please enter alphabets only");
    //     setShowFormDataError((prev) => ({
    //       ...prev,
    //       isFirstNameError: true,
    //     }));
    //   } else {
    //     setShowFormDataError((prev) => ({
    //       ...prev,
    //       isFirstNameError: false,
    //     }));
    //   }
    // } else {
    //   setShowFormDataError((prev) => ({
    //     ...prev,
    //     isFirstNameError: true,
    //   }));
    //   errorMessages.push(firstNameMessage);
    // }

    // if (lastName) {
    //   if (!validateName(lastName.trim())) {
    //     errorMessages.push("Please enter alphabets only");
    //     setShowFormDataError((prev) => ({
    //       ...prev,
    //       isLastNameError: true,
    //     }));
    //   } else {
    //     setShowFormDataError((prev) => ({
    //       ...prev,
    //       isLastNameError: false,
    //     }));
    //   }
    // } else {
    //   setShowFormDataError((prev) => ({
    //     ...prev,
    //     isLastNameError: true,
    //   }));
    //   errorMessages.push(lastNameMessage);
    // }

    if (email) {
      if (!validateEmail(email)) {
        errorMessages.push(InvalidEmailMessage);
        setShowFormDataError((prev) => ({
          ...prev,
          isEmailError: true,
        }));
      } else {
        setShowFormDataError((prev) => ({
          ...prev,
          isEmailError: false,
        }));
      }
    } else {
      setShowFormDataError((prev) => ({
        ...prev,
        isEmailError: true,
      }));
      errorMessages.push(emailMessage);
    }

    if (errorMessages.length > 0) {
      // errorNotification(errorMessages.join("\n"));
    } else {
      const payload = {
        firstName: firstName,
        lastName: lastName,
        email: email.toLocaleLowerCase(),
      };
      patch(`${APIS.Users}/${complianceData.id}`, payload);
      setUserLoading(true);
    }
    // setIsStartVerification(true)
  }, [
    InvalidEmailMessage,
    complianceData.id,
    emailMessage,
    errorNotification,
    firstNameMessage,
    formData,
    lastNameMessage,
    patch,
    setUserLoading,
  ]);

  const {
    isOnboardingComplete,
    status,
  } = complianceData.onboardingData;
  
  const { onboardingData, phone, countryCode} = complianceData;
  
  const renderComponent = useMemo(() => {
    const url = `${onboardingData?.complexUrl}&allow=true&phone=${phone}&countryCode=${countryCode}${personalData?.email && personalData?.isVerifiedEmail ? `&email=${personalData?.email}`: ''}&redirect=false`;
    // const agreeModal = localStorage.getItem("AgreeModal");
    return !isStartVerification &&
      status === "pending" &&
      !isEditingUserDetails ? (
      <div className={styles.main_container}>
        <KycStart />
        <button
          className={`${styles.proceed_btn} ${styles.kyc_btnStart}`}
          onClick={() => {
            setIframeExists(true);
            setIsStartVerification(true);
          }}
        >
          Start verification
        </button>
      </div>
    ) : !isEditingUserDetails ? (
      (status === "completed" && !reinitiateKyc) ||
      isOnboardingComplete ||
      (prevUserData?.onboardingData?.status === "pending" &&
        onboardingData?.status === "completed") ? (
        <div className={styles.main_container}>
          {/* {agreeModal !== "false" && isOnboardingComplete ? (
            <Agree />
          ) : (
            <> */}
          <KycReview onLogout={onLogout} setReinitiateKyc={setReinitiateKyc} />
        </div>
      ) : (
        <>
          <div className={styles.main_container} ref={parentRef}>
            <iframe
              src={url}
              title="Compliance"
              className={styles.compliance_iframe}
              allow="camera; microphone"
            />
          </div>
        </>
      )
    ) : (
      <>
        <div className={styles.main_container}>
          <ConfirmIdentity
            disabledData={disabledData}
            proceed={handleProceed}
            loading={loading}
            showFormDataError={showFormDataError}
            setShowFormDataError={setShowFormDataError}
          />
          <div className="compliance-btn-groups">
            <button
              className={`btn-cancel ${styles.proceed_btn} ${styles.kyc_btn}`}
              onClick={() => !!handleClose && handleClose()}
            >
              Cancel
            </button>
            <button
              className={`btn-save-verify ${styles.proceed_btn} ${styles.kyc_btn}`}
              onClick={handleProceed}
            >
              Save and verify
            </button>
          </div>
        </div>
      </>
    );
  }, [onboardingData?.complexUrl, onboardingData?.status, phone, countryCode, personalData?.email, personalData?.isVerifiedEmail, isStartVerification, reinitiateKyc, status, isEditingUserDetails, isOnboardingComplete, prevUserData?.data?.onboardingData?.status, onLogout, setReinitiateKyc, disabledData, handleProceed, loading, showFormDataError, setIsStartVerification, handleClose]);

  return <>{renderComponent}</>;
};

function elseIf(arg0: boolean) {
  throw new Error("Function not implemented.");
}

