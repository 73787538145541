import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import cn from "classnames";

import { ActiveCurrencyState, CollapseState } from "states";
import { navItems } from "./constants";
import { OrderTable } from "./components";

import styles from "./Orders.module.sass";
import UseOrdersApi from "./store/hook";
import { ActiveTabState, ExecutedOrderToasterState, OrderPlacedState } from "./store/state";

export const Orders = () => {
  // globle states
  const initialQuery={ limit: 20, offset: 0 }
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const [collapse, setCollapse] = useRecoilState(CollapseState);
  const [filters, setFilters] = useState(initialQuery);
  const [updateNewData, SetUpdateNewData] = useState(false);
  const orderExecutedToaster=useRecoilValue(ExecutedOrderToasterState)

  const { fetchOrders } = UseOrdersApi();
  const [activeTab, setActiveTab] = useRecoilState(ActiveTabState);
  const { id: activeCurrencyId }: any = activeCurrency ?? {};
  const orderPlaced=useRecoilValue(OrderPlacedState)
  const [ordersData, setOrdersData] = useState({});

  useEffect(() => {
        if (activeCurrencyId) {
      const options = { updateNewData };
      const query = {
        ...filters,
        assetId: activeCurrencyId,
        type: activeTab.key,
      };
      fetchOrders(query, activeTab.key, options,setOrdersData);
      
    }
  }, [ filters,orderExecutedToaster]);

    const handleFetchMore = useCallback(() => {
      SetUpdateNewData(true)
      setFilters((prev) => ({ ...prev, offset: prev.offset + 1 }));
    }, []);


    useEffect(() => {
    SetUpdateNewData(false)
    setFilters(initialQuery);
  }, [ activeTab,orderPlaced]);
  

  useEffect(() => {
    setActiveTab({
      key: "PENDING",
      index: 0,
    });
  }, [activeCurrency?.id]);

  const handleClickNavigation = useCallback((key: string, index: number) => {
    setActiveTab({ key, index });
  }, []);

  const handleCollapse = useCallback(() => {
    setCollapse((pre) => !pre);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderNavigation = useMemo(() => {
    return (
      <div className={`${styles.nav} ${styles.nav_header}`}>
        <div className={styles.nav}>
          {navItems.map((key, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeTab.index,
              })}
              onClick={() => handleClickNavigation(key.type, index)}
              key={index}
            >
              {key.title}
            </button>
          ))}
        </div>
        <div
          onClick={handleCollapse}
          className={cn(styles.collapse, {
            [styles.collapse_true]: collapse,
            [styles.collapse_false]: !collapse,
          })}
        >
          <i className="ri-arrow-down-s-line" />
        </div>
      </div>
    );
  }, [activeTab.index, collapse, handleClickNavigation, handleCollapse]);

  return (
    <div
      className={cn({
        [styles.orders]: collapse,
        [styles.collapse_orders]: !collapse,
      })}
    >
      <div className={styles.head}>{renderNavigation}</div>
      <OrderTable activeTab={activeTab.key} handleFetchMore={handleFetchMore} filters={filters} ordersData={ordersData} setOrdersData={setOrdersData}/>
    </div>
  );
};
