import { IActiveTabData } from "../type";

import { FC, useCallback, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import cn from "classnames";

import { AddWatchlist } from "components";
import { ActiveCurrencyState, PrivatesState } from "states";
import { Price } from "../Price";

import styles from "./../Currency/Currency.module.sass";
import { assestType } from "../../constants";
import React from "react";
import { useNavigate } from "react-router-dom";
import { limitFirstPart, mergeStringWithSymbol } from "@utils/getString";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ACCREDITATION_LABEL } from "@views/exchange/components/Main/constants";

interface IPrivateItem {
  index: number;
  symbol: string;
  id: string;
  name: string;
  marketPrice: number;
  change: number;
  changesPercentage: number;
  image: string;
  issuerDetails?: any
  accreditationType?: string
}

interface ILogo {
  symbol: string;
  image: string;
}

export const PrivateItem: FC<IPrivateItem> = ({
  index,
  symbol,
  id,
  name,
  marketPrice,
  change,
  changesPercentage,
  image,
  issuerDetails,
  accreditationType
}) => {
  const privates = useRecoilValue(PrivatesState);
  const [activeCurrency, setActiveCurrency] =
    useRecoilState(ActiveCurrencyState);
  const navigate = useNavigate();
  const {issuerName} = issuerDetails || {}
  const handleRowClick = useCallback(
    async (index: number, data: IActiveTabData[]) => {
      navigate(`/exchange/${data[index]?.id}?type=${assestType.privates}`);
    },
    [navigate]
  );
  
  const [isError, setIsError] = useState(false);

  const item = privates.find((privateItem: IActiveTabData) => {
    return privateItem.symbol === symbol;
  });

  const getLogo = useCallback(
    ({ symbol, image }: ILogo) => {
      return image && !isError ? (
        <img
          src={image}
          alt=""
          onError={() => setIsError(true)}
          className={styles.assetImage}
        />
      ) : (
        <div className={styles.assetLetter}>
          {symbol?.charAt(0).toUpperCase() ?? "?"}
        </div>
      );
    },
    [isError]
  );

  return (
    <div
      onClick={() => handleRowClick(index, privates)}
      className={cn(styles.row, {
        [styles.active]:
          id === activeCurrency?.id && symbol === activeCurrency?.symbol,
      })}
      key={index}
    >
      <div className={`${styles.left}`}>
        <div className={styles.assetImageContain}>
          {getLogo({ symbol, image })}
          {!!ACCREDITATION_LABEL[accreditationType || ""] &&
            <img className={styles.accreditationLabel} src={`/media/icon/${accreditationType}.svg`} alt="Accreditation" />}
        </div>
        <div className={styles.symbol} data-tooltip-id={id}>
          {limitFirstPart(
            mergeStringWithSymbol(issuerName, symbol, "."),
            8,
            "."
          ) ?? "-"}
        </div>
        <ReactTooltip
          className="theme-tooltip"
          id={id}
          place="bottom"
          content={mergeStringWithSymbol(issuerName, symbol, ".")}
        />
        <div className={styles.compony_name}>{ mergeStringWithSymbol(issuerName, name, " ") ?? "NA"} </div>
      </div>
      <Price
        marketPrice={marketPrice}
        change={change}
        changesPercentage={changesPercentage}
        symbol={symbol}
      />
      <AddWatchlist item={item} assetType={assestType.privates} />
    </div>
  );
};
