import { Env, Json } from "../types";

export const getJsonToParams = (obj: Json) => {
  return "?" + new URLSearchParams(obj || {}).toString();
};

export const getJsonFromParams = (paramsString: string) => {
  return Object.fromEntries(new URLSearchParams(paramsString));
};

export const getObjectFromUseSearchParams = (searchParams: any = []) => {
  const params: Json = {};
  searchParams.forEach((value: string, key: string) => {
    params[key] = value;
  });

  return params;
};

export const formatFileSize = (bytes: number, decimalPoint = 2) => {
  if (bytes == 0) return "0 Bytes";
  const k = 1000,
    sizes = ["Bytes", "KB", "MB", "GB", "TB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint)) + " " + sizes[i]
  );
};

export const base64URLStringToBuffer = (base64URLString: any) => {
  // Convert from Base64URL to Base64
  const base64 = base64URLString.replace(/-/g, "+").replace(/_/g, "/");
  /**
   * Pad with '=' until it's a multiple of four
   * (4 - (85 % 4 = 1) = 3) % 4 = 3 padding
   * (4 - (86 % 4 = 2) = 2) % 4 = 2 padding
   * (4 - (87 % 4 = 3) = 1) % 4 = 1 padding
   * (4 - (88 % 4 = 0) = 4) % 4 = 0 padding
   */
  const padLength = (4 - (base64.length % 4)) % 4;
  const padded = base64.padEnd(base64.length + padLength, "=");

  // Convert to a binary string
  const binary = atob(padded);

  // Convert binary string to buffer
  const buffer = new ArrayBuffer(binary.length);
  const bytes = new Uint8Array(buffer);

  for (let i = 0; i < binary.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }

  return buffer;
};

export const bufferToBase64URLString = (buffer: any) => {
  const bytes = new Uint8Array(buffer);
  let str = "";

  for (const charCode of bytes as any) {
    str += String.fromCharCode(charCode);
  }

  const base64String = btoa(str);

  return base64String.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
};

const attachments = ["cross-platform", "platform"];

export const toAuthenticatorAttachment = (attachment: any) => {
  if (!attachment) {
    return;
  }

  if (attachments.indexOf(attachment) < 0) {
    return;
  }

  return attachment;
};

export const asterisksString = (str: string) => {
  if (!str) return ""
  const temp = str?.split(" ");
  temp?.forEach((s, i) => {
    temp[i] = s[0] + "*".repeat((s?.length || 1) - 1)
  });
  return temp.join(" ") || ""
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0)?.toUpperCase() + str.slice(1);
};

// This will change "BUY" in to "Buy"
export const capitalizeFirstLetterOnly = (input: string): string => {
  if (input?.length === 0) {
    return input;
  }
  return input?.charAt(0).toUpperCase() + input?.slice(1).toLowerCase();
};

export const downloadGoogleapisFile = (url: string, fileName: string) => {
  if (!url) return;
  fileName = fileName?.split(".")[0];
  fetch(url).then((response) => response.blob()).then((blob) => {
    const blobURL = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobURL;
    a.target = "_blank";
    a.download = fileName || `${(new Date()).getTime()}`;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }).catch((error) => {
    console.error(error);
  });
};


export const checkAspectRatio = async (file: any, imgWidth: number, imgHeight: number, lowerLimit = true) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  const result = await new Promise((resolve) => {
    reader.onload = (e) => {
      const img: any = new Image();
      img.src = e?.target?.result;
      img.onload = () => {
        const aspectRatio = Math.ceil(img.width / img.height);
        if (lowerLimit) {
          resolve(img.width >= imgWidth && img.height >= imgHeight);
        } else {
          resolve(img.width <= imgWidth && img.height <= imgHeight);
        }
      };
    };
  });

  return result;
};

export const isValidFileSize = async (fileInput: any, maxFileSize: number) => {
  const file = await fileInput.files[0];
  const fileSizeInBytes = file.size;
  const fileSizeInMb = fileSizeInBytes / (1024 * 1024);
  return fileSizeInMb > maxFileSize ? false : true
}

export const getEnv = (url: string): Env => {
  if (/localhost/gi.test(window.location.href)) {
    return 'local';
  }else if (/stage/gi.test(url)) {
    return 'stage';
  } else if (/pp/gi.test(url)) {
    return 'pp';
  } else if (/beta/gi.test(url)) {
    return 'beta';
  } else if (/api.satschel.com/gi.test(url)) {
    return 'prod';
  } else {
    return 'stage'
  }
}

export const getFileObjectFromBlobURL = (dataURL:string) => new Promise(resolve => {
  const img = new Image()
  img.onload = () => {
    resolve(img)
  }
  img.src = dataURL
})
