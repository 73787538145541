import { useNetwork } from "@hooks/network";
import { useNotification } from "@hooks/notification";
import { Json } from "types";
import { APIS } from "constant";
import { useState } from "react";

export const useMobileAuthentication = () => {
  const [loading, setLoading] = useState(false);
  const { post: postUserLogin } = useNetwork();
  const { post: checkLinkStatus } = useNetwork();
  const { errorNotification } = useNotification();

  type IgenerateVerificationLink = {
    type: string;
    countryCode: string | number;
    phone: string;
  };

  const generateVerificationLink = async ({
    type,
    countryCode,
    phone,
  }: IgenerateVerificationLink) => {
    setLoading(true);
    try {
      const response = await postUserLogin(
        APIS.userVerifyLink,
        {
          type,
          countryCode,
          phone,
        },
        { apiResponse: true }
      );
      if (response?.data?.success) {
        return response?.data?.verificationId ?? "";
      } else {
        errorNotification(response?.message ?? "Something went wrong");
      }
    } catch (err: any) {
      console.log(err?.message);
    } finally {
      setLoading(false);
    }
  };

  const checkMobileVerificationStatus = async (payload: Json) => {
    try {
      const response = await checkLinkStatus(APIS.userVerifyLink, payload, {
        apiResponse: true,
      });
      return response;
    } catch (err: any) {
      console.log(err?.message);
    }
  };

  const { post: mobileVerifyLinkAction } = useNetwork();
  const mobileLinkAction = async (payload: Json) => {
    setLoading(true);
    try {
      const response = await mobileVerifyLinkAction(
        APIS.userVerifyLink,
        payload,
        { apiResponse: true }
      );
      if (
        response.message === "link expired." ||
        response?.message === "Invalid Request"
      ) {
        return false;
      } else {
        return true;
      }
    } catch (err: any) {
      console.log(err?.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    generateVerificationLink,
    checkMobileVerificationStatus,
    mobileLinkAction,
    loading,
  };
};
