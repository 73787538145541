import { useState, useEffect } from 'react';

interface WindowSize {
  width: number;
  height: number;
}

export const useCalculateWindowSize = (throttleTime: number = 200): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout> | undefined;

    const updateSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    const handleResize = () => {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(updateSize, throttleTime);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [throttleTime]);

  return windowSize;
};
