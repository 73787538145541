import type { IReactSwitch } from './type';

import { FC } from 'react';
import Switch from 'react-switch';

export const ReactSwitch: FC<IReactSwitch> = ({
	id,
	checked = false,
	handleChange,
	width,
	height,
	onColor,
	offColor,
	diameter,
	isDisabled = false,
	className = '',
}) => {
	return (
		<Switch
			id={id}
			checked={checked}
			disabled={isDisabled}
			checkedIcon={false}
			onColor={onColor ?? '#33B868'}
			offColor={offColor ?? '#C9CDD6'}
			uncheckedIcon={false}
			height={height ?? 24}
			width={width ?? 48}
			className={className}
			handleDiameter={diameter ?? 16}
			onChange={handleChange}
		/>
	);
};
