export const tableHeaders = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "artist",
    label: "Artist",
  },
  {
    key: "albom",
    label: "Album",
  },
  {
    key: "year",
    label: "Year",
  },
];
