// import React from "react";
// import ReactDOM from "react-dom";
// import { BrowserRouter } from "react-router-dom";
// import { RecoilRoot } from "recoil";

// import App from "./App";

import "remixicon/fonts/remixicon.css";
import "./index.css";

// ReactDOM.render(
//   <RecoilRoot>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </RecoilRoot>,
//   document.getElementById("root")
// );

import React from "react";
import * as ReactDOM from "react-dom/client";
import reactToWebComponent from "react-to-webcomponent";
import { OnboardedDashboard } from "web-components";
import { init as initFullStory } from '@fullstory/browser';

initFullStory({ orgId: '18W55M' });

const OnboardedDashboardContainer = reactToWebComponent(
  OnboardedDashboard as any,
  React as any,
  ReactDOM as any
);

customElements.define("exchange-dashboard", OnboardedDashboardContainer as any);
