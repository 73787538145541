import { atom } from "recoil";
import { IUser } from "./type";

export const profilTabState = atom<string>({
  key: "profile-tab-state",
  default: "profile",
});

export const invitedUsersState = atom<IUser[]>({
  key: "invited-users-state",
  default: [],
});
