export const NOTIFICATION_TABS = [
  { title: "All", type: "ALL" },
  { title: "Orders", type: "ORDERS" },
  { title: "Invite", type: "INVITE" },
  { title: "Miscellaneous", type: "MISCELLANEOUS" },
];
export const NOTIFICATION_TYPE: any = {
  ALL: "ALL",
  ORDERS: "ORDERS",
  INVITE: "INVITE",
  MISCELLANEOUS: "MISCELLANEOUS",
};
export const emptyNotification = {
  NO_NOTIFICATION: "No notifications",
  NO_ORDERS_NOTIFICATION: "No orders notifications",
  NO_INVITE_NOTIFICATION: "No invite notifications",
  NO_MISCELLANEOUS_NOTIFICATION: "No miscellaneous notifications",
};
