import { useSetRecoilState } from "recoil";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Json } from ".././../../../types/common";
import { capitalizeFirstLetterOnly } from "@utils/common";
import { NotificationVisibleState } from "@views/Notification/store/state";
import UseNotificationApi from "@views/Notification/store/useNotificaitonApi";

import "./authorisedNotification.scss";
import { Button } from "@components/button";
import { useNetwork } from "@hooks/network";
import { APIS } from "constant";
import { useNotification } from "@hooks/notification";
import { Loader } from "@components/Loader2";

const AuthorisedNotification = (data: Json) => {
  const {
    updateCoownerStatus,
    data: {
      actionStatus,
      metadata: { id: inviteId },
    },
  } = data ?? {};

  const { updateMarkAsRead } = UseNotificationApi();
  const { put: loginInvite, loading } = useNetwork();
  const { errorNotification } = useNotification();

  const acceptInvite = useCallback((e: any, id: string) => {
    e.stopPropagation();
    loginInvite(`${APIS.COOWNER}/${inviteId}`).then((res: any) => {
      if (res?.token) {
        updateCoownerStatus(inviteId);
        if(notificationData?.readStatus==="UNREAD"){
          updateMarkAsRead({ id: id });
        }
      } else {
        errorNotification(`Something went wrong`);
      }
    });
  }, [data, updateCoownerStatus]);

  const getStatusString = (status: string) => {
    if(status === 'REMOVAL_PENDING') return 'requested to remove'
    else if(status === 'REMOVED') return 'removed'
    else return 'invited'
  }

  const notificationData = useMemo(() => {
    return {
      title: `${capitalizeFirstLetterOnly(
        data?.data?.metadata?.userType
      )} invite`,
      subtitle: `${data?.data?.metadata?.ownerName} ${getStatusString(data?.data?.actionStatus)} you as ${
        data?.data?.metadata?.userType === "co-owner" ? "a" : "an"
      } ${capitalizeFirstLetterOnly(data?.data?.metadata?.userType)}.`,
      status: data?.data?.actionStatus === 'REMOVAL_PENDING' ? 'Removal Pending' : data?.data?.actionStatus,
      notificationId: data?.data?._id,
      readStatus: data?.data?.readStatus,
      time: data?.data?.createdAt
        ? `${new Date(data?.data?.createdAt).getDate()} ${new Date(
            data?.data?.createdAt
          ).toLocaleDateString("en-US", {
            month: "short",
          })}, ${new Date(data?.data?.createdAt).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}`
        : "-",
    };
  }, [
    data?.data?._id,
    data?.data?.actionStatus,
    data?.data?.createdAt,
    data?.data?.metadata?.ownerName,
    data?.data?.metadata?.userType,
    data?.data?.readStatus,
  ]);

  const statusColor: any = {
    PENDING: "processing",
    CANCELLED: "cancelled",
    ACCEPTED: "completed",
    REJECTED: "failure",
    "Removal Pending": "failure",
    REMOVED: "failure"
  };

  return (
    <div className="notificationSection">
      <div className="AuthorisedNotification">
        <div className="AuthorisedNotification__left">
          <div className="AuthorisedNotification__left__icon">
            <i className="ri-user-add-fill" />
          </div>
          {notificationData?.readStatus === "UNREAD" && (
            <div className="AuthorisedNotification__left__readMark"></div>
          )}
          <div>
            <div className="AuthorisedNotification__left__title">
              {notificationData?.title}
            </div>
            <div className="AuthorisedNotification__left__Subtitle">
              {notificationData?.subtitle}
            </div>
            {actionStatus === "PENDING" && (
              <div className="AuthorisedNotification__left__action">
                <Button
                  disabled={loading}
                  label={
                    loading ? (
                      <Loader className="loader-white" dimension={12} />
                    ) : (
                      "Accept"
                    )
                  }
                  handleClick={(e: any) =>
                    acceptInvite(e, notificationData?.notificationId)
                  }
                  type="button__filled button__filled--secondary button__small coowner-btn-action"
                />
              </div>
            )}
          </div>
        </div>
        <div className="AuthorisedNotification__right">
          <div
            className={`AuthorisedNotification__right__dot ${
              statusColor[notificationData?.status]
            }`}
          ></div>
          <div className="AuthorisedNotification__right__status">
            {capitalizeFirstLetterOnly(notificationData?.status)}
          </div>
        </div>
      </div>
      <div className="AuthorisedNotification__right__time">
        {notificationData?.time}
      </div>
    </div>
  );
};

export default AuthorisedNotification;
