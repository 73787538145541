import styles from "./compliance.module.sass";
import { Image } from "@storybook";
import { useCallback, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { complianceDataState,darkThemeState, userPersonalDetails, openComplianceState, TriggerUserApi, reOpenKycSignupModalState } from "states";

interface IkycReview {
  onLogout: () => void;
  setReinitiateKyc: (value: boolean) => void;
}

export const KycReview = ({ onLogout, setReinitiateKyc }: IkycReview) => {
  const complianceData = useRecoilValue(complianceDataState);
  const themeDark = useRecoilValue(darkThemeState);
  const personalData = useRecoilValue(userPersonalDetails);
  const setOpenCompliance = useSetRecoilState(openComplianceState);
    const setTriggerUserApi = useSetRecoilState(TriggerUserApi);
  const setReOpenKycSignupModal = useSetRecoilState(reOpenKycSignupModalState);

  const {
    kycStatus,
    kybStatus,
    isOnboardingComplete,
    amlStatus,
    questionnaireStatus,
    accreditationStatus,
    signDocStatus,
  } = complianceData.onboardingData;
  const isAnyStatusPending = [
    kycStatus,
    kybStatus,
    amlStatus,
  ].some((status) => status === "pending" || status === "processing");
  const statusArray = [kycStatus, kybStatus];

  const isAnyRejected = [
    kycStatus,
    kybStatus,
    amlStatus,
    questionnaireStatus,
    accreditationStatus,
    signDocStatus,
  ].some((status) => status === "rejected");

  const rejectedCount = statusArray.filter(
    (status) =>
      status === "rejected" || status === "processing" || status === "pending"
  ).length;

  const renderLogoutButton = useMemo(() => {
    return (
      <button
        className={`${styles.logout_btn} ${styles.kyc_btn}`}
        onClick={onLogout}
      >
        Logout
        <i className={`ri-logout-box-r-line ${styles.buttonIcon}`}></i>
      </button>
    );
  }, []);


   const handleCloseModal = useCallback(() => {
     setTriggerUserApi((prev) => !prev);
    //  setIsSuccessModal(false);
     setOpenCompliance(false);
     setReOpenKycSignupModal(false);
   }, []);

  const renderBothButton = useCallback((btnText: string) => {
    // Commented code as per suggested by shubham sir
    return (
      <div className={styles.kyc_btn}>
        {/* {renderLogoutButton} */}
        <button
          className={`${styles.proceed_btn}`}
          onClick={handleCloseModal}
          // onClick={()=>setReinitiateKyc(true)}
        >
          {/* {`Reinitiate ${btnText}`} */}
          Continue
        </button>
      </div>
    );
  }, []);

  const renderUserDetail = useMemo(() => {
    return (
      <div className={styles.kyc_userDetail}>
        <div>
          <span className={styles.kyc_userDetailHead}>Name &nbsp;&nbsp;:</span>{" "}
          &nbsp;
          <span>
            {personalData?.firstName &&
              personalData?.firstName + "  " + personalData?.lastName}
          </span>
        </div>
        <div>
          <span className={styles.kyc_userDetailHead}>
            Email &nbsp;&nbsp;&nbsp;:
          </span>{" "}
          &nbsp;<span>{personalData?.email && personalData?.email}</span>
        </div>
        <div>
          <span className={styles.kyc_userDetailHead}>Phone &nbsp;&nbsp;:</span>{" "}
          &nbsp;
          <span>
            {personalData?.phone &&
              personalData?.countryCode + "  " + personalData?.phone}
          </span>
        </div>
      </div>
    );
  }, [personalData]);
// TODE : We maynneed this later if not need i will remove it
//   const renderKycStatus = useMemo(() => {
//     return   !isOnboardingComplete ? (
//       isAnyRejected ?(
//         rejectedCount >= 2 ? (
//         <div className={styles.kyc}>
//           {themeDark ? (
//             <Image fileName={"images/kyc-kyb-rejected.svg"} height={"220px"} />
//           ) : (
//             <Image
//               fileName={"images/kyc-kyb-rejected-light.svg"}
//               height={"220px"}
//             />
//           )}
//           <h2 className={styles.kyc_heading}>
//             Identity and business verification rejected
//           </h2>
//           <p className={styles.kyc_para}>
//             We regret to inform you that your identity and business verification
//             has been rejected.
//             {/* Please <a href= "">view the report</a> for details. */}
//           </p>
//           {renderUserDetail}
//           {renderBothButton("")}
//         </div>
//       ) : kybStatus === "rejected"  ? (
//         <div className={styles.kyc}>
//           {themeDark ? (
//             <Image fileName={"images/kyb-rejected.svg"} height={"220px"} />
//           ) : (
//             <Image
//               fileName={"images/kyb-rejected-light.svg"}
//               height={"220px"}
//             />
//           )}
//           <h2 className={styles.kyc_heading}>Business verification rejected</h2>
//           <p className={styles.kyc_para}>
//             We regret to inform you that your business verification has been
//             rejected. Your case has been flagged for a manual review, which our
//             team will attend to within 24-48 hours. Any updates will be
//             communicated to you.
//             {/* Please <a href= "">view the report</a> for details. */}
//           </p>
//           {renderUserDetail}
//           {renderBothButton("KYB")}
//         </div>
//       ) : (
//         <div className={styles.kyc}>
//           {themeDark ? (
//             <Image fileName={"images/kyc-rejected.svg"} height={"220px"} />
//           ) : (
//             <Image
//               fileName={"images/kyc-rejected-light.svg"}
//               height={"220px"}
//             />
//           )}
//           <h2 className={styles.kyc_heading}>Identity verification rejected</h2>
//           <p className={styles.kyc_para}>
//             We regret to inform you that your identity verification has been
//             rejected. Your case has been flagged for a manual review, which our
//             team will attend to within 24-48 hours. Any updates will be
//             communicated to you.
//             {/* Please <a href= "">view the report</a> for details. */}
//           </p>
//           {renderUserDetail}
//           {renderBothButton("KYC")}
//         </div>
//       )
//       ) :(

//         isAnyStatusPending && (
//       <div className={styles.kyc}>
//         {themeDark ? (
//           <Image fileName={"images/kyc-review.svg"} height={"220px"} />
//         ) : (
//           <Image fileName={"images/kyc-review-light.svg"} height={"220px"} />
//         )}
//         <h2 className={styles.kyc_heading}>Verification Under Review</h2>
//         <p className={styles.kyc_para}>
//           Your account verifications is currently under review. This process may
//           take some time. We will notify you once the verification is complete.
//           Thank you for your patience.
//         </p>
//         {/* {renderLogoutButton} */}
//         {renderBothButton("")}
//       </div>
//     )
//       ) 
      
//     ) : (
//       <div className={styles.kyc}>
//         {themeDark ? (
//           <Image fileName={"images/kyc-success.svg"} height={"220px"} />
//         ) : (
//           <Image fileName={"images/kyc-success-light.svg"} height={"220px"} />
//         )}
//         <h2 className={styles.kyc_heading}>Verification done successfully</h2>
//         <p className={styles.kyc_para}>
//           Your verification has been done successfully, now you can <br /> trade
//           and access your wallet
//         </p>
//         <button
//           className={`${styles.proceed_btn}`}
//           onClick={() => setOpenCompliance(false)}
//         >
//           Start trading
//         </button>
//       </div>
//     );
//   }, [isOnboardingComplete, isAnyRejected, rejectedCount, themeDark, renderUserDetail, renderBothButton, kybStatus, isAnyStatusPending, setOpenCompliance]);


const kycRow = useCallback((type:string,status:string)=>{
    return (
      <>
        {" "}
        {status && (
          <div className={styles.kycRow}>
            <div
              className={`${styles.kyc_dot} ${
                status === "completed" && styles.complete} ${status === "rejected" && styles.reject
              }`}
            ></div>
            <div className={styles.kycRow_head}>
              {type}
              <div className={styles.kycRow_status}>
                {status === "processing" || status === "pending"
                  ? "Under review"
                  : status}
              </div>
            </div>
          </div>
        )}
      </>
    );

},[])

 const renderKycStatus = useMemo(() => {
    return (
      <div className={styles.kyc_review}>
        <h2 className={styles.kyc_heading_review}>Submitted for review</h2>
        <p className={styles.kyc_para_review}>
          We'll review your information. Once your account get verified you can
          start using the platform.
        </p>
        <div className={styles.kyc__review}>
          <div className={styles.kyc_review_list}>
            {kycRow("KYC", kycStatus)}
            {kycRow("KYB", kybStatus)}
            {kycRow("AML", amlStatus)}
            {kycRow("Accreditation", accreditationStatus)}
            <button
              className={`${styles.proceed_btn_review}`}
              onClick={handleCloseModal}
            >
              Explore Liquidity
            </button>
          </div>
          <div className={styles.kyc_review_progress}>
            <h3 className={styles.heading}>Verification Timeline</h3>
            <div className={styles.progressContainer}>
              <div className={styles.progress_bar}>
                <div>
                  <div className={`${styles.kyc_dot} ${styles.complete}`}></div>
                  <div
                    className={`${styles.progressline} ${styles.complete} `}
                  ></div>
                </div>
                <div>
                  {" "}
                  <h3>Completed verification</h3>
                  <p>Submitted for review</p>
                </div>
              </div>
              <div
                className={`${styles.progress_bar} ${
                  isOnboardingComplete ? styles.complte : styles.inProgress
                }`}
              >
                <div>
                  <div className={`${styles.kyc_dot}`}></div>
                  <div className={styles.progressline}></div>
                </div>
                <div>
                  {" "}
                  <h3>In review</h3>
                  <p>Approx. 1-4 business days</p>
                </div>
              </div>
              <div
                className={`${styles.progress_bar} ${
                  isOnboardingComplete ? styles.complte : styles.notDone
                }`}
              >
                <div>
                  <div className={styles.kyc_dot}></div>
                  <div className={styles.progressline}></div>
                </div>
                <div>
                  {" "}
                  <h3>Account ready</h3>
                  <p>
                    You can trade, deposit, withdraw and use all other features.
                  </p>
                </div>
              </div>
            </div>
            <h3>Have any questions?</h3>
            <p>Our support team can help you.</p>
            <div className={styles.mail}>
              <i className="ri-mail-line"></i>
              <a href="mailto:support@liquidity.io">support@liquidity.io</a>
            </div>
          </div>
        </div>
      </div>
    );
 }, [accreditationStatus, amlStatus, handleCloseModal, isOnboardingComplete, kybStatus, kycRow, kycStatus]);

  return <>{renderKycStatus}</>;
};