import { FC, useMemo } from "react";
import useDarkMode from "use-dark-mode";
import cn from "classnames";

import "./skeleton-loader.scss";

interface ISkeletonWatchList {
  listsToRender: number;
}

export const SkeletonWatchList: FC<ISkeletonWatchList> = ({
  listsToRender,
}) => {
  const darkMode = useDarkMode(false);
  const skeletonLoader = useMemo(() => {
    return Array(listsToRender)
      .fill(1)
      .map((card, index) => (
        <div
          key={`watchList__${index}`}
          className="skeleton_WatchList"
          style={{
            borderBottom: darkMode.value
              ? "1px solid #23262F"
              : "1px solid #e6e8ec",
          }}
        >
          <div
            className={cn("skeleton_WatchList_star", {
              "skeleton-loader-dark": darkMode.value,
              "skeleton-loader-light": !darkMode.value,
            })}
          />
          <div className="skeleton_WatchList_liner">
            <div className="skeleton_WatchList_container">
              <div
                className={cn("skeleton_WatchList_symbol", {
                  "skeleton-loader-dark": darkMode.value,
                  "skeleton-loader-light": !darkMode.value,
                })}
              />
              <div
                className={cn("skeleton_WatchList_amount", {
                  "skeleton-loader-dark": darkMode.value,
                  "skeleton-loader-light": !darkMode.value,
                })}
              />
            </div>
            <div className="skeleton_WatchList_container_last">
              <div
                className={cn("skeleton_WatchList_price", {
                  "skeleton-loader-dark": darkMode.value,
                  "skeleton-loader-light": !darkMode.value,
                })}
              />
              <div
                className={cn("skeleton_WatchList_change", {
                  "skeleton-loader-dark": darkMode.value,
                  "skeleton-loader-light": !darkMode.value,
                })}
              />
            </div>
          </div>
          <div
            className={cn("skeleton_WatchList_star_left", {
              "skeleton-loader-dark": darkMode.value,
              "skeleton-loader-light": !darkMode.value,
            })}
          />
        </div>
      ));
  }, [darkMode.value, listsToRender]);
  return <>{skeletonLoader}</>;
};
