export const navItems = ["Overview", "News"];
export const navItemsMusic = ["Overview"];


export const AssetsInfo = [
  {
    key: "valuation",
    header_label: "Valuation",
    data: [
      {
        key: "market_cap",
        label: "Market Cap",
        operation: "convertToCurrency"
      },
      {
        key: "enterprise_value",
        label: "Enterprise Value",
        operation: "convertToCurrency"
      },
      {
        key: "total_shares_outstanding",
        label: "Total Shares Outstanding",
        operation: "convertToCurrency"
      },
      {
        key: "price_revenue",
        label: "Price/Revenue",
        operation: "formatCurrency"
      },
      {
        key: "price_book",
        label: "Price/Book",
        operation: "formatCurrency"
      },
      {
        key: "price_earning",
        label: "Price/Earning",
        operation: "formatCurrency"
      },
    ],
  },
  {
    key: "balance_sheet",
    header_label: "Balance Sheet",
    data: [
      {
        key: "net_debt",
        label: "Net Debt",
        operation: "convertToCurrency"
      },
      {
        key: "total_debt",
        label: "Total Debt",
        operation: "convertToCurrency"
      },
      {
        key: "total_assets",
        label: "Total Assets",
        operation: "convertToCurrency"
      },
    ],
  },
  {
    key: "dividend",
    header_label: "Dividend",
    data: [
      {
        key: "dividend_yield",
        label: "Dividend Yield",
        operation: "numberWithComma"
      },
      {
        key: "price_to_earn",
        label: "Price To Earn",
        operation: "formatCurrency"
      }
    ],
  },
];

