import { IForm } from "../type";

import { FC, useCallback, useMemo, useState } from "react";
import cn from "classnames";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { Icon } from "components";
import { Action } from "./Action";
import {
  ActiveCurrencyState,
  SelectedOrderState,
  defaultFormDatastate,
  userSelectedCurrencyState,
} from "states";

import styles from "./Form.module.sass";
import { assestType } from "@views/exchange/constants";
import { useCurrency } from "@hooks/currency";

export const Form: FC<IForm> = ({
  activeTabIndex,
  contentBuy,
  contentSell,
  limit,
  visible,
  setVisible,
  isOrderModifying,
}) => {
  const selectedOrder = useRecoilValue(SelectedOrderState);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const currencyDetails = useRecoilValue(userSelectedCurrencyState);
  const setData = useSetRecoilState(defaultFormDatastate);

  const { amountToBondPoints } = useCurrency();

  const { marketPrice, symbol, amount }: any = activeCurrency ?? {};
  const assetPrice =
    activeCurrency?.type === assestType.SBA7
      ? amountToBondPoints(activeCurrency?.currentLoanBalance, amount)
      : marketPrice || amount;

  const [activeBtnIndex, setActiveBtnIndex] = useState(
    selectedOrder?.type === "SELL" ? 1 : 0
  );

  const isTablet = false;
  const buttons = useMemo(() => ["BUY", "SELL"], []);

  const handleClick = useCallback(
    (index: any) => {
      setActiveBtnIndex(index);
      setData((prev: any) => {
        return {
          ...prev,
          symbol,
          price: isOrderModifying
            ? (
                Number(
                  activeCurrency?.type === assestType.SBA7
                    ? amountToBondPoints(
                        activeCurrency?.currentLoanBalance,
                        selectedOrder?.price
                      )
                    : selectedOrder?.price
                ) * currencyDetails?.rate
              ).toFixed(4)
            : (assetPrice * currencyDetails?.rate).toFixed(4),
          qty: isOrderModifying ? Math.round(selectedOrder?.quantity) : "1",
        };
      });
    },

    [
      activeCurrency?.currentLoanBalance,
      activeCurrency?.type,
      amountToBondPoints,
      assetPrice,
      currencyDetails?.rate,
      isOrderModifying,
      selectedOrder?.price,
      selectedOrder?.quantity,
      setData,
      symbol,
    ]
  );

  const renderButtons = useMemo(() => {
    return (
      <div className={styles.buttonContainer}>
        {buttons.map((button, index) => {
          return (
            <button
              className={cn(styles.button, {
                [styles.active]: index === activeBtnIndex,
              })}
              onClick={() => handleClick(index)}
              key={index}
            >
              {button}
            </button>
          );
        })}
      </div>
    );
  }, [activeBtnIndex, buttons, handleClick]);

  const colClass = useMemo(() => {
    return cn(styles.col, {
      [styles.colModal]: isOrderModifying,
    });
  }, [isOrderModifying]);

  return (
    <div className={styles.form}>
      {isTablet ? (
        <>
          {visible ? (
            <>
              <div className={styles.head}>
                <div className={styles.title}>Place order</div>
                <button
                  className={styles.close}
                  onClick={() => setVisible(false)}
                >
                  <Icon name="close-circle" size="24" />
                </button>
              </div>
              <Action
                title="Buy"
                type="BUY"
                activeTabIndex={activeTabIndex}
                content={contentBuy}
                StopLimit={limit}
                classButton="button-green"
                buttonText="Buy"
                isOrderModifying={isOrderModifying}
              />
            </>
          ) : (
            <>
              <div className={styles.head}>
                <div className={styles.title}>Place order</div>
                <button
                  className={styles.close}
                  onClick={() => setVisible(false)}
                >
                  <Icon name="close-circle" size="24" />
                </button>
              </div>
              <Action
                title="Sell"
                type="SELL"
                activeTabIndex={activeTabIndex}
                content={contentSell}
                StopLimit={limit}
                classButton="button-red"
                buttonText="Sell"
                isOrderModifying={isOrderModifying}
              />
            </>
          )}
        </>
      ) : (
        <>
          {!isOrderModifying && renderButtons}
          <div className={styles.row}>
            <div className={colClass}>
              {activeBtnIndex === 0 ? (
                <Action
                  title="Buy"
                  type="BUY"
                  activeTabIndex={activeTabIndex}
                  content={contentBuy}
                  StopLimit={limit}
                  classButton="button-green"
                  buttonText="BUY"
                  isOrderModifying={isOrderModifying}
                />
              ) : (
                <Action
                  title="Sell"
                  type="SELL"
                  activeTabIndex={activeTabIndex}
                  content={contentSell}
                  StopLimit={limit}
                  classButton="button-red"
                  buttonText="SELL"
                  isOrderModifying={isOrderModifying}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
