import { useRecoilValue, useSetRecoilState } from "recoil";
import { useCallback } from "react";
import { useNetwork } from "@hooks/network";
import { APIS } from "constant";
import { getJsonToParams } from "@utils/common";
import { Json } from "../../../types/common";
import { NotificationDataState, activeNotifcationTabState } from "./state";
import { userPersonalDetails } from "@states/user";
import { INotificationData } from "./type";

const UseNotificationApi = () => {
  const { get: getAllNotification, loading: AllNotificationLoading } =
    useNetwork();
  const { put: updatedRead, loading: updatedReadLoading } = useNetwork();
  const userDetails = useRecoilValue(userPersonalDetails);
  const activeTab = useRecoilValue(activeNotifcationTabState);

  const setNotificationData = useSetRecoilState(NotificationDataState);

  const fetchNotification = useCallback(
    (query: Json = {}, activeTab: string, options?: any) => {
      setNotificationData((prev: INotificationData) => {
        return {
          ...prev,
          loading: true,
        };
      });
      const resData: Json = { loading: false };
      getAllNotification(APIS.NOTIFICATION + getJsonToParams(query)).then(
        (res: Json) => {
          if (res) {
            if (options.updateNewData === false) {
              setNotificationData({ [activeTab]: [...res?.data] } as any);
            } else if (options?.replaceAndUpdateData) {
              setNotificationData((prev: INotificationData) => ({
                ...prev,
                [activeTab]: res?.data,
              }));
            } else if (options.updateNewData === true) {
              resData[activeTab] = res.data;
              setNotificationData((prev: any) => ({
                ...prev,
                loading: false,
                [activeTab]: [...prev[activeTab], ...res?.data],
              }));
            }
          }
        }
      );
    },
    [getAllNotification, setNotificationData]
  );

  const updateMarkAsRead = useCallback(
    (payload: Json) => {
      updatedRead(`${APIS.NOTIFICATION}/${userDetails?.id}`, payload).then(
        (res: any) => {
          if (res) {
            const options = {
              updateNewData: false,
              replaceAndUpdateData: true,
            };
            const query = {
              limit: 20,
              offset: 0,
              category: activeTab === "ALL" ? "" : activeTab,
            };
            fetchNotification(query, activeTab, options);
          }
        }
      );
    },
    [activeTab, fetchNotification, updatedRead, userDetails?.id]
  );

  return {
    fetchNotification,
    AllNotificationLoading,
    updateMarkAsRead,
    updatedReadLoading,
  };
};

export default UseNotificationApi;
