import { useCallback } from "react";
import { useNetwork } from "./network";
import { useCookie } from "./cookie";
import { APIS } from "../constant";
import { useSetRecoilState } from "recoil";
import { PortfolioAPIKey, PortfolioListState } from "../states";
import { Json } from "../types";
import { useNotification } from "./notification";
import { getJsonToParams } from "@utils/common";

export const usePortfolioApis = () => {
  const { get: getTokenFromCookie } = useCookie();
  const user = getTokenFromCookie("userDetails");
  const { get: fetchPortfolio } = useNetwork({ updateState: false });
  const { errorNotification } = useNotification();
  const setPortfolioListState = useSetRecoilState(PortfolioListState);

  const fetchPortfolioList = useCallback(
    async (query: Json = {}, options: Json = {}): Promise<void> => {
      if (!user?.token) return;
      setPortfolioListState((prev: any) => {
        if (options?.appendNewData) {
          return {
            ...prev,
            loading: true,
          };
        } else {
          return {
            ...prev,
            loading: true,
            data: {},
          };
        }
      });
      const resData: Json = { loading: false };
      try {
        resData.data = await fetchPortfolio(
          APIS.ExchangePortfolio + getJsonToParams(query)
        );
      } catch (e: any) {
        errorNotification(e?.message || "Something went wrong!");
      } finally {
        if (query.offset === 0 || !options?.appendNewData) {
          setPortfolioListState((prev: any) => ({ ...prev, ...resData }));
        } else {
          setPortfolioListState((prev: any) => {
            let prevData = JSON.parse(JSON.stringify(prev?.data?.data || {}));
            let newData = JSON.parse(JSON.stringify(resData?.data?.data || {}));

            Object.keys(newData)?.forEach((key: any) => {
              const {
                currentValue,
                gainLoss,
                gainLossPercentage,
                investedValue,
              } = newData?.[key]?.summary || {};
              const prevSummary = prevData[key]?.summary || {};
              newData[key].assets = [
                ...(prevData[key]?.assets || []),
                ...newData?.[key]?.assets,
              ];
              newData[key].summary = {
                currentValue: (prevSummary?.currentValue || 0) + currentValue,
                gainLoss: (prevSummary?.gainLoss || 0) + gainLoss,
                gainLossPercentage:
                  (prevSummary?.gainLossPercentage || 0) + gainLossPercentage,
                investedValue:
                  (prevSummary?.investedValue || 0) + investedValue,
              };
            });

            return { ...resData, data: { ...resData?.data, data: newData } };
          });
        }
      }
    },
    []
  );

  return {
    fetchPortfolioList,
  };
};
