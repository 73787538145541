import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Image } from "@storybook";
import image from "../../assets/images/locked.svg";
import lightImage from "../../assets/images/lockedLight.svg";
import { useCookie } from "hooks/cookie";
import { ROUTES } from "@routes/constants";
import { useLocalStorage } from "@hooks/local-storage";
import { useNetwork } from "@hooks/network";
import { APIS } from "constant";

import "./accessDenied.scss";
import { accountLockedType, userDetailsState } from "@states/user";
import { useRecoilValue } from "recoil";
import useDarkMode from "use-dark-mode";

export const AccessDenied = () => {
  const { deleteCookie } = useCookie();
  const { LOGIN } = ROUTES;
  const { get: getLocalStorage } = useLocalStorage();
  const { put: switchUser } = useNetwork();
  const navigate = useNavigate();
  const userLockdType = useRecoilValue(accountLockedType);
  const darkMode = useDarkMode(false);

  const handleLogout = useCallback(() => {
    const isDark: any = localStorage?.getItem("darkMode") ?? "";
    localStorage.clear();
    if (isDark === "true") localStorage?.setItem("darkMode", "true");
    deleteCookie("userDetails");
    window.location.href = LOGIN;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCookie]);

  const handleBackAccount = useCallback(() => {
    const payload = { isPrimary: true };
    switchUser(`${APIS.SWITCHACCOUNT}/${userLockdType?.userId}`, payload).then(
      (res) => {
        if (res.success) {
          navigate("/exchange");
          window.location.reload();
        }
      }
    );
  }, [getLocalStorage, navigate, switchUser]);

  const primaryUser: any =
    getLocalStorage("dropdown")?.["isPrimary" as any] ?? "";

  return (
    <div className="access-denied">
      <div className="logo">
        <div className="logo1">
          <Image
            className="brand_logo"
            fileName={`images/Liquidity_Blue_Logo.png`}
          />
        </div>
      </div>
      <div className="access--denied">
        <div>
          <img
            className="access-img"
            src={darkMode.value ? image : lightImage}
            alt="logo"
          />
          {userLockdType.userAccountLocked ? (
            <h2>Your account is locked</h2>
          ) : (
            <h2>This account is locked</h2>
          )}

          <p>
            Account temporarily locked for security, please contact our support
            team at{" "}
            <a href="mailto:support@liquidity.io">support@liquidity.io</a> to
            facilitate the unlocking process.
          </p>
          {/* Implementation after api changes */}
          {userLockdType.userAccountLocked ? (
            <button onClick={handleLogout}>Back to login</button>
          ) : (
            <button onClick={handleBackAccount}>Back to my Account</button>
          )}
        </div>
      </div>
    </div>
  );
};
