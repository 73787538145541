import { useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { useRecoilValue, useRecoilState } from "recoil";
import { sub, format } from "date-fns";

import useDarkMode from "use-dark-mode";
import { useNetwork, usePrevious } from "hooks";
import { ActiveCurrencyState, activeTypeState, perDayState, SocketTriggeredOnAssetIdState } from "states";
import { Dropdown, Loader } from "components";

import {
  chartDropDown,
  CHART_INTERVALS_TYPE,
  CHART_TABS,
  mapApiUrl,
  MOMENT_TYPES,
  navigation,
} from "./constant";

import styles from "./Charts.module.sass";
import { Image } from "@storybook";
import { CandleChart, AreaChart } from "components/Charts";

export const Charts = () => {
  const assetType = useRecoilValue(activeTypeState);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const [chartPerDay, setChartPerDay] = useRecoilState(perDayState);
  const [activeIndexDates, setActiveIndexDates] = useState(0);
  const [activeIndexNav, setActiveIndexNav] = useState(1);
  const socketTriggeredOnChartValue = useRecoilValue(SocketTriggeredOnAssetIdState);
  const prevSocketTrigger = usePrevious(socketTriggeredOnChartValue);

  const [date, setDate] = useState<string | number>(CHART_TABS[0]);

  const { symbol, id, type: assetT } = activeCurrency;
  const darkMode = useDarkMode(false);

  const {
    get: getChart,
    data: chartData,
    loading: chartLoading,
  } = useNetwork();

  useEffect(() => {
    if (socketTriggeredOnChartValue?.type === "CHART" && prevSocketTrigger?.timeStamp !== socketTriggeredOnChartValue?.timeStamp) {
      setChartPerDay((_data) => [..._data, socketTriggeredOnChartValue?.chartData])
    }
  }, [socketTriggeredOnChartValue]);

  const handleChangeChartInterval = useCallback(
    (tab: string | number, index: number) => {
      const toDate = sub(new Date(), { days: 1 });
      const momentType: any = MOMENT_TYPES[tab];

      let tabObj: { [key: string]: number } = {
        // to be used if 2 years data is required
        // "2Y": 2,
        "5Y": 5,
      };
      const count = tabObj[tab] ?? 1;
      const fromDate = sub(toDate, { [momentType]: count });
      const from = format(fromDate, "yyyy-MM-dd");
      const to = format(toDate, "yyyy-MM-dd");
      const urlSymbol = /privates/gi.test(assetType) ? id : symbol;

      let urlData = { urlSymbol, assetType };
      setChartPerDay([]);

      const apiQuery =
        count === 1
          ? `interval=${CHART_INTERVALS_TYPE[tab]}`
          : `from=${from}&to=${to}`;

      if ((mapApiUrl[assetType] ?? assetType) && symbol) {
        getChart(
          `/${mapApiUrl[urlData.assetType] ?? urlData.assetType}-history/${urlData.urlSymbol
          }?${apiQuery}
          `
        );
      }
      setActiveIndexDates(index);
    },
    [assetType, getChart, id, symbol, activeCurrency]
  );

  useEffect(() => {
    setActiveIndexDates(0)
  }, [activeCurrency?.id])

  useEffect(() => {
    if ((mapApiUrl[assetT] ?? assetT) && symbol) {
      const urlSymbol = /privates/gi.test(assetT) ? id : symbol;
      let urlData = { urlSymbol, assetT };
      setChartPerDay([]);

      getChart(
        `/${mapApiUrl[urlData.assetT] ?? urlData.assetT}-history/${urlData.urlSymbol
        }?interval=day`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetT, symbol, id]);

  useEffect(() => {
    if (chartData?.data) {
      setChartPerDay(chartData.data);
    }
  }, [chartData, setChartPerDay]);

  const tabsClass = useCallback((index: number, active: number) => {
    return cn(styles.link, {
      [styles.active]: index === active,
    });
  }, []);

  const chartClass = useCallback((index: number, active: number) => {
    return cn(styles.chartLink, {
      [styles.active]: index === active,
    });
  }, []);

  const renderChartTabs = useMemo(() => {
    return (
      <div className={styles.nav}>
        {CHART_TABS.map((tab, index) => (
          <button
            className={tabsClass(index, activeIndexDates)}
            onClick={() => handleChangeChartInterval(tab, index)}
            key={index}
          >
            {tab}
          </button>
        ))}
      </div>
    );
  }, [activeIndexDates, handleChangeChartInterval, tabsClass]);

  const renderChartType = useMemo(() => {
    return (
      <div className={styles.nav}>
        {navigation.map((nav, index) => (
          <button
            className={chartClass(index, activeIndexNav)}
            onClick={() => setActiveIndexNav(index)}
            key={index}
          >
            <Image fileName={nav} />
          </button>
        ))}
      </div>
    );
  }, [activeIndexNav, chartClass]);

  const selectedDate = useMemo(() => {
    if (activeIndexDates === 0) {
      return "1D";
    }
    if (activeIndexDates === 1) {
      return "1W";
    }
    if (activeIndexDates === 2) {
      return "1M";
    }
    if (activeIndexDates === 3) {
      return "1Y";
    }
    if (activeIndexDates === 4) {
      return "2Y";
    }
    return;
  }, [activeIndexDates]);

  const handleChangeInterval = useCallback(
    (interval: string | number) => {
      const ind = chartDropDown[interval];
      setDate(interval);
      handleChangeChartInterval(interval, ind);
    },
    [handleChangeChartInterval]
  );

  return (
    <div className={styles.charts}>
      <div className={styles.head}>
        <div className={styles.group}>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={date}
            setValue={handleChangeInterval}
            options={CHART_TABS}
          />
          {renderChartTabs}
        </div>

        {renderChartType}
      </div>
      {chartPerDay.length === 0 && !chartLoading ? (
        <div className={styles.ChartContainer_height}>
          <Image
            fileName={`images/${darkMode.value ? "no-chart.svg" : "no-chart-light.svg"
            }`}
            className={styles.Charts_no_data}
          />
          <div className={styles.ChartContainer_detail}>
            Chart is not available at the moment
          </div>
        </div>
      ) : (
        <>
          {activeIndexNav === 0 && (
            <div className={styles.inner}>
              <div className={styles.iframe}>
                {chartLoading || chartPerDay.length === 0 ? (
                  <div className={styles.loaderContainer}>
                    <Loader className="loader-grey" dimension={60} />
                  </div>
                ) : (
                  <CandleChart
                    chartPerDay={chartPerDay}
                    isLoading={chartLoading || chartPerDay.length === 0}
                  />
                )}
              </div>
            </div>
          )}
          {activeIndexNav === 1 && (
            <div className={styles.inner}>
              <div className={styles.iframe}>
                {chartLoading || chartPerDay.length === 0 ? (
                  <div className={styles.loaderContainer}>
                    <Loader className="loader-grey" dimension={60} />
                  </div>
                ) : (
                  <AreaChart
                    chartPerDay={chartPerDay}
                    activeTab={selectedDate}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
