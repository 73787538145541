import { useEffect } from "react";
import useDarkMode from "use-dark-mode";

export const PieChart = ({title,subtitle,series}:any) => {
  const darkMode = useDarkMode();

  useEffect(() => {
    (window as any).Highcharts?.chart(title, {
      chart: {
        type: "pie",
        backgroundColor: "transparent",
        animation: true,
        zooming: {
          mouseWheel: {
            enabled: false,
          },
        },
      },

      exporting: { enabled: false },

      title: {
        text: title,
        align: "left",
        style: {
          color: darkMode.value ? "#FFFFFF" : "#000000",
          fontWeight: "600",
          fontSize: "14px",
        },
      },

      subtitle: {
        text: subtitle,
        align: "left",
        style: {
          color: "#878C99",
          fontWeight: "500",
          fontSize: "12px",
        },
      },

      credits: {
        enabled: false,
      },

      accessibility: {
        enabled: false,
      },

      colors: ["#1EB87B", "#EF4343", "#eeeeee", "#5388D8"],

      legend: {
        labelFormatter: function (this: any) {
          return `${this.name} </br><span style="color:#BCC3D6; fontSize:12px"> ${this.y} M</span>`;
        },
        symbolRadius: 0,
        title: {
          text: "Platform",
          align: "left",
          style: {
            color: "#878C99",
            fontWeight: "500",
            fontSize: "12px",
          },
        },
        itemStyle: {
          color: darkMode.value ? "#FFFFFF" : "#000000",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "16px",
        },
        itemHoverStyle: {
          color: "#878C99",
        },
      },

      plotOptions: {
        pie: {
          states: {
            inactive: {
              enabled: false,
            },
          },
          innerSize: "75%",
          borderWidth: 0,
          showInLegend: true,
          borderRadius: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },

      tooltip: {
        valueSuffix: "M",
        backgroundColor: darkMode.value ? "#36383D" : "#F0F4FF",
        style: {
          color: darkMode.value ? "#FFFFFF" : "#17191F",
        },
      },

      navigation: {
        menuStyle: {
          border: "0px",
          boxShadow: "none",
        },
        buttonOptions: {
          theme: {
            "stroke-width": 1,
            r: 5,
            fill: "transparent",
            states: {
              hover: {
                fill: "rgb(167 172 160)",
              },
            },
          },
        },
      },

      series,
    });
  }, [darkMode.value,title,subtitle,series]);

  return (
    <div
      style={{
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
        overflowX: "auto",
      }}
    >
      <div id={title}></div>
    </div>
  );
};
