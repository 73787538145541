// @ts-ignore
import { Json } from "@types/common";

interface CheckResult {
  status: boolean;
  key?: string;
}

export function checkAllValues(obj: Record<string, any>): CheckResult {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (!obj[key]) {
        return { status: false, key };
      }
    }
  }
  return { status: true };
}

export function removeDuplicatesAndEmptyIds(array: Json[]) {
  const uniqueObjects: any = {};
  const result: any = [];

  (array || [])?.forEach((obj: any) => {
    if (obj.id !== "" && !uniqueObjects[obj.id]) {
      uniqueObjects[obj.id] = true;
      result.push(obj);
    }
  });

  return result;
}
