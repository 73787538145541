import { useNetwork } from "@hooks/network";
import { LoginPhoneNumberState, MobileVerificationState } from "@states/user";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { OptionsResponseState } from "../stores";
import { useNotification } from "@hooks/notification";
import { useCallback } from "react";
import { APIS } from "constant";

export const useBiometricAuthentication = () => {
  const { post } = useNetwork();
  const [verificatioId, setVerificationId] = useRecoilState(
    MobileVerificationState
  );
  const loginPhoneNumber = useRecoilValue(LoginPhoneNumberState);
  const { phone, countryCode } = loginPhoneNumber;
  const setOptionsResponse = useSetRecoilState(OptionsResponseState);
  const { errorNotification } = useNotification();

  const authenticateOptions = useCallback(async (payload: any) => {
    const response = await post(APIS.CLIENT_WEBAUTHN, payload);
    return response;
  }, []);

  const registrationApproval = useCallback(
    async (payload: any) => {
      const response = await post(APIS.CLIENT_WEBAUTHN, payload);
      const { verificationId } = response ?? "";
      setVerificationId(verificationId);
      return response;
    },
    [post, setVerificationId]
  );

  const registrationOptions = useCallback(
    async ({ authenticationType }: any) => {
     
      let optionPayload: any;
      if (authenticationType === "login") {
        optionPayload = {
          type: "registrationOpts",
          verificationId: verificatioId,
          origin: window.location.hostname ?? "exchange.stage.satschel.com"
        };
      } else {
        optionPayload = {
          type: "registrationOptsUID",
          phone,
          countryCode,
          origin: window.location.hostname ?? "exchange.stage.satschel.com"
        };
      }

      const response = await post(APIS.CLIENT_WEBAUTHN, optionPayload);
      const { message, id, registrationOptions } = response ?? {};
      if (id && registrationOptions) {
        setOptionsResponse(response);
      } else {
        errorNotification(message ?? "Something went wrong");
      }
    },
    [verificatioId]
  );

  const verifyRegistration = useCallback(
    async (payload: any) => {
      const response = await post(APIS.CLIENT_WEBAUTHN, payload);
      return response;
    },
    [post]
  );

  const verifyAuthentication = useCallback(
    async (payload: any) => {
      const response = await post(APIS.CLIENT_WEBAUTHN, payload);
      return response;
    },
    [post]
  );

  return {
    registrationOptions,
    registrationApproval,
    authenticateOptions,
    verifyRegistration,
    verifyAuthentication,
  };
};
