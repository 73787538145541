import { FC, useCallback, useMemo } from "react";
import styles from "./compliance.module.sass";
import { Input, Loader } from "components";
import { AuthLayout } from "./components";

import "./confirm-identity.scss";
import { signUpInfoState, loadingState } from "states";
import { useRecoilState, useRecoilValue } from "recoil";

interface IShowFormDataError {
  isFirstNameError: boolean;
  isLastNameError: boolean;
  isEmailError: boolean;
}

interface IDisabledFormData {
  firstName?: boolean;
  lastName?: boolean;
  email?: boolean;
}

interface IConfirmIdentity {
  proceed: () => void;
  loading: boolean;
  showFormDataError: IShowFormDataError;
  setShowFormDataError: any;
  disabledData?: IDisabledFormData;
}

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
}

export const ConfirmIdentity: FC<IConfirmIdentity> = ({
  proceed,
  loading,
  showFormDataError,
  setShowFormDataError,
  disabledData,
}) => {
  const [formData, setFormData] = useRecoilState<IFormData>(signUpInfoState);
  const userLoading = useRecoilValue(loadingState);

  // const proceesLabel = useMemo(() => {
  //   if (userLoading) return <Loader dimension={16} className="loader-white" />;
  //   return "Proceed";
  // }, [userLoading]);

  const changeInputHandle = useCallback(
    (
      name: string,
      isShowError: string,
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const { value } = e.target;
      setFormData((pre: any) => {
        let preState = { ...pre, [name]: value };
        return preState;
      });
      setShowFormDataError((prev: any) => {
        return { ...prev, [isShowError]: false };
      });
    },
    [setFormData, setShowFormDataError]
  );

  return (
    <div className={styles.compliance_container}>
      <AuthLayout minHeight={510} width={832}>
        {/* <div className="signup-inputs">
          <div className="signup-row-1">
            <div className="FIRST-NAME">
            <Input
              disabled={!!disabledData?.firstName}
              label="First Name"
              inputType="text"
              placeholder="First Name"
              handleChange={(e) =>
                changeInputHandle("firstName", "isFirstNameError", e)
              }
              isError={showFormDataError.isFirstNameError}
              value={formData.firstName}
            />
           {showFormDataError?.isFirstNameError && <div className="error-msg-1">Please enter alphabets only</div>}
           </div>
           <div className="SECOND-NAME">
            <Input
              disabled={!!disabledData?.lastName}
              label="Last Name"
              inputType="text"
              placeholder="Last Name"
              handleChange={(e) =>
                changeInputHandle("lastName", "isLastNameError", e)
              }
              isError={showFormDataError.isLastNameError}
              value={formData.lastName}
            />
            
          
          {showFormDataError.isLastNameError && <div className="error-msg-2">Please enter alphabets only</div>}
          </div>
          </div>
          </div> */}
          <div className="signup-row-2">
            <Input
              disabled={!!disabledData?.email}
              label="Email address"
              inputType="text"
              placeholder="johndoe@example.com"
              className="emailInput"
              handleChange={(e) =>
                changeInputHandle("email", "isEmailError", e)
              }
              isError={showFormDataError.isEmailError}
              value={formData.email}
            />
           {showFormDataError.isEmailError &&  <div className="error-msg-3">Invalid Email </div>}
        </div>
      </AuthLayout>
      {/* <button className={styles.proceed_btn} onClick={proceed}>
        {proceesLabel}
      </button> */}
    </div>
  );
};
