import React, { useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import styles from "./Settings.module.sass";
import OutsideClickHandler from "react-outside-click-handler";
import { Icon } from "components";
import { CURRENCIES, LANGUAGES } from "./constants";
import { useNetwork } from "hooks";
import { APIS } from "constant";
import { useRecoilState, useRecoilValue } from "recoil";
import { userAllDetailsState, userPersonalDetails, userSelectedCurrencyState } from "states";

export const Settings = ({ className }: any) => {
  // Global states
  const [userSelectedCurrency, setUserSelectedCurrency] = useRecoilState(
    userSelectedCurrencyState
  );
  const userDetails = useRecoilValue(userAllDetailsState);
  const personalData = useRecoilValue(userPersonalDetails);

  // Local states
  const [visible, setVisible] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<string>(
    userSelectedCurrency?.code ?? ""
  );

  // Hooks
  const { patch, data } = useNetwork();

  // Constants
  const theme = "dark";

  const handleSelectedCurrency = useCallback(
    (currency: string) => {
      if(currency !== "USD") return;
      // TODO: uncomment this code if we start using other currency.
      // const { id } = userDetails ?? {};
      // setSelectedCurrency(currency);
      // const payload = {
      //   settings: {
      //     currency: currency,
      //     language: "en",
      //     theme,
      //   },
      // };
      // patch(`${APIS.Users}/${id}`, payload);
      setVisible(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userDetails]
  );

  useEffect(() => {
    const { id } = userDetails ?? {};
    const { currency } = personalData ?? {};
    if (currency !== "USD" && id && currency  ) {
      const payload = {
        settings: {
          currency: "USD",
          language: "en",
          theme,
        },
      };
      patch(`${APIS.Users}/${id}`, payload);
    }
  }, [userDetails?.id]);

  useEffect(() => {
    setSelectedCurrency(userSelectedCurrency?.code);
  }, [userSelectedCurrency]);

  useEffect(() => {
    if (data) {
      const { currency } = data.settings ?? {};
      setUserSelectedCurrency(currency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderLanuguage = useMemo(() => {
    return (
      <div className={styles.col}>
        <div className={styles.category}>Language</div>
        <div className={styles.menu}>
          {LANGUAGES.map((language, index) => (
            <div
              className={cn(styles.language, {
                [styles.active]: index === 0,
              })}
              key={index}
            >
              <span className={styles.flag}>{language.flag}</span>
              {language.title}
            </div>
          ))}
        </div>
      </div>
    );
  }, []);

  const renderCurrencies = useMemo(() => {
    return (
      <div className={styles.col}>
        <div className={styles.category}>Currency</div>
        <div className={styles.menu}>
          {CURRENCIES.map((x, index) => (
            <div
              className={cn(styles.currency, {
                [styles.active]: x.value === selectedCurrency,
                [styles.disabled]: x.value !== "USD",
              })}
              key={index}
              onClick={() => handleSelectedCurrency(x.value)}
            >
              {x.value}
            </div>
          ))}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrency]);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(className, styles.settings, {
          [styles.active]: visible,
        })}
      >
        <button
          className={styles.head}
          onClick={() => setVisible((prev) => !prev)}
        >
          {selectedCurrency}
          <Icon name="arrow-down" size="16" />
        </button>
        {visible && (
          <div className={styles.body}>
            {/* this will be used in future for changing language */}
            {/* <div className={styles.row}> */}
            {/* {renderLanuguage} */}
            {renderCurrencies}
          </div>
        )}
        {/* </div> */}
      </div>
    </OutsideClickHandler>
  );
};
