// @ts-ignore
import { Json } from "@types/common";

export const items = [
  {
    title: "Open",
    icon: "clock",
    color: "#00C076",
    key: "open",
  },
  {
    title: "24h high",
    icon: "arrow-top",
    key: "dayHigh",
  },
  {
    title: "24h low",
    icon: "arrow-bottom",
    key: "dayLow",
  },

  {
    title: "Volume",
    icon: "chart",
    key: "volume",
  },
];

export const itemsMusic = [
  {
    title: "Open",
    icon: "clock",
    color: "#00C076",
    key: "open",
  },
  {
    title: "24h high",
    icon: "arrow-top",
    key: "dayHigh",
  },
  {
    title: "24h low",
    icon: "arrow-bottom",
    key: "dayLow",
  },

  {
    title: "Prev. Close",
    icon: "clock",
    key: "previousClose",
  },
];

export const assetsTobeRemoved = [
  "stocks",
  "privates",
  "forex",
  "crypto",
  "commodities",
  "marketIndexes",
];

export const newWatchlistKey: any = {
  stocks: "stock",
  music: "music",
  crypto: "crypto",
  commodities: "commodity",
  privates: "privates",
  forex: "forex",
  marketIndexes: "marketIndex",
  sba7: "sba7",
};

export const LOAN_NAME: any = {
  "whole_loans" : "Whole Loans",
  "current_guaranteed_loan_balance": "Gov. Guaranteed Portions"
}

export const ACCREDITATION_TYPES: Json = {
  RETAIL: "RETAIL",
  B506: "506B",
  C506: "506C",
}

export const ACCREDITATION_LABEL: Json = {
  [ACCREDITATION_TYPES.B506]: true,
  [ACCREDITATION_TYPES.C506]: true,
}
