import { FC, ReactNode, useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";

import { Icon } from "../Icon";

import styles from "./Modal.module.sass";

type IModal = {
  outerClassName?: string;
  visible: boolean;
  onClose: () => void;
  children: ReactNode;
  closeBtn?: boolean;
  maskClosable?: boolean;
  title?: string | JSX.Element;
};

export const ReactModal: FC<IModal> = ({
  outerClassName,
  visible,
  maskClosable = true,
  onClose,
  children,
  title,
  closeBtn,
}) => {
  const escFunction = useCallback(
    (e: { keyCode: number }) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal");
      disableBodyScroll(target as HTMLElement | Element);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [visible]);

  return createPortal(
    visible && (
      <div id="modal" className={styles.modal}>
        <div className={cn(styles.outer, outerClassName)}>
          <OutsideClickHandler
            onOutsideClick={() => !!maskClosable && onClose()}
          >
            {title && (
              <div className={cn("h4", styles.title)}>
                <Icon name="arrow-left" size="32" />
                {title}
              </div>
            )}
            {children}
            {closeBtn ? (
              <button
                className={cn("close-btn", styles.close)}
                onClick={onClose}
              >
                <Icon name="close" size="16" />
              </button>
            ) : (
              <></>
            )}
          </OutsideClickHandler>
        </div>
      </div>
    ),
    document.body
  );
};

export default ReactModal;
