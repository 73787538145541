import { useState } from "react";


export const useDownloadGoogleapisFile = () => {
    const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({});


    const downloadGoogleapisFile = async (url: string, fileName: string) => {
        if (!url) return;
        try {
            setLoadingStates(prevState => ({ ...prevState, [url]: true }));

            fileName = fileName?.split(".")[0];
            const response = await fetch(url);
            const blob = await response.blob();

            const blobURL = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = blobURL;
            a.target = "_blank";
            a.download = fileName || `${(new Date()).getTime()}`;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error(error);
        } finally {
            // Ensure that loading state is reset no matter what happens
            setLoadingStates(prevState => ({ ...prevState, [url]: false }));
        }
    };

    return {
        downloadGoogleapisFile,
        loadingStates,
    }
};