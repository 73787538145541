import { atom } from "recoil";

export const OrderDetailsState = atom<any>({
  key: "order-details-state",
  default: { loading: false, data: {} },
});

export const ActiveTabState = atom<any>({
  key: "active-tab-state",
  default: {
    key: "PENDING",
    index: 0,
  },
});
export const OrderPlacedState = atom<any>({
  key: "OrderPlacedState",
  default: false
});
export const OrderPlacedExecutedCheckState = atom<any>({
  key: "order-placed-executed-check-state",
  default: false
});
export const AssetPortfolioState = atom<any>({
  key: "asset-qty-state",
  default: {loading:false, data:{} }
});
export const ExecutedOrderToasterState = atom<any>({
  key: "executed-order-toaster-state",
  default: false
});
