import React, { useCallback, useState } from 'react';
// @ts-ignore
import { Json } from "@types/common";
import { format } from "date-fns";
import UseChatroomApi from "@views/Chat/store/chatroomApi";
import { useRecoilValue } from "recoil";
import { userPersonalDetails } from "@states/user";
import { Loader } from "@components/Loader2";


interface BidderItemProps {
  details: Json,
  assetId: string
}

export const ChatBidderItem: React.FC<BidderItemProps> = ({ details = {}, assetId }) => {
  const { getChatInitiated, chatInitiatedLoading } = UseChatroomApi();
  const personalData = useRecoilValue(userPersonalDetails);

  const openModals = useCallback(() => {
    getChatInitiated({
      assetId: assetId || "",
      receiverId: details?.customerId || "",
      senderId: personalData?.customerId || "",
    })
  }, [getChatInitiated])

  return (
    <div className="bidder-item">
      <div className="initials">{details?.initials}</div>
      <div className="details">
        <div>{details?.customerId?.substring(0, details?.customerId?.length - 4) + '*'.repeat(4)}</div>
        <div className="timestamp">
          {!!details?.created_at && format(new Date(details?.created_at), "HH:mm:ss, MMM dd, yyyy")}</div>
      </div>
      <div>
        <div>
          {details?.customerId !== personalData?.customerId &&
            <button className="chat-button" onClick={openModals} disabled={chatInitiatedLoading}>
              {chatInitiatedLoading ?
                <Loader dimension={17} /> : <><i className="ri-chat-voice-fill"></i>Chat</>}
            </button>}
        </div>
      </div>

    </div>
  );
};
