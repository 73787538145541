import { useNetwork } from '@hooks/network'
import { useNotification } from '@hooks/notification'
import { APIS } from 'constant'
import  { useCallback } from 'react'

const UseBuySellApi = () => {
    const {patch:CancelOrderVerify}=useNetwork()
    const {successNotification,errorNotification}=useNotification()

    const CancelOrderVerifcation=useCallback((id:string|number)=>{
        CancelOrderVerify(`${APIS.ORDERS_EXCHANGE}/${id}`,{orderType: "ORDER_DECLINED", "signature": "","isCancel": true}).then((res)=>{
         if(res?.success){
          successNotification(res?.message)
         }else{
          errorNotification(res?.message)
         }
        })
    },[CancelOrderVerify, errorNotification, successNotification])
    
  return (
   {CancelOrderVerifcation}
  )
}

export default UseBuySellApi