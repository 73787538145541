import Checkbox from "components/Checkbox";
import "./data-filter.scss";
import React, { useMemo, useState } from "react";
import { Button } from "../button";
import OutsideClickHandler from "react-outside-click-handler";
import { Json } from "../../types";

interface IDataFilter {
  data: Json[];
  position?: "left" | "right";
  selectedValue: Json;
  onChangeFilter: (obj: Json) => void;
  onClickReset: () => void;
  isLoading?: boolean;
}

export const DataFilter = ({
  data,
  selectedValue,
  position = "left",
  onChangeFilter,
  onClickReset,
  isLoading,
}: IDataFilter) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeFilterOptions, setActiveFilterOptions] = useState("");
  const toggleFilter = () => {
    setActiveFilterOptions("");
    setIsFilterOpen((prev) => !prev);
  };

  const handleCloseFilter = () => {
    setActiveFilterOptions("");
    setIsFilterOpen(false);
  };

  const toggleFilterOptions = (key: string) => {
    if (activeFilterOptions === key) {
      setActiveFilterOptions("");
    } else {
      setActiveFilterOptions(key);
    }
  };

  const handleClickOnCheckbox = (key: string, value: string) => {
    if (selectedValue?.[key]?.length) {
      const valuesSet = new Set(selectedValue?.[key]?.split(","));
      if (valuesSet.has(value)) {
        valuesSet.delete(value);
        return onChangeFilter({ [key]: [...valuesSet].join(",") });
      }
      onChangeFilter({ [key]: [...valuesSet, value].join(",") });
    } else {
      onChangeFilter({ [key]: value });
    }
  };

  const totalSelectedNumber = useMemo(() => {
    let count = 0;
    data?.map((items: any) => {
      if (selectedValue?.[items.key]?.length) {
        count += selectedValue?.[items.key]?.split(",")?.length;
      }
    })
    return count;
  }, [selectedValue]);

  return (
    <div className="filter-container">
      <OutsideClickHandler onOutsideClick={handleCloseFilter}>
        <Button
          handleClick={toggleFilter}
          label={totalSelectedNumber ? totalSelectedNumber + " Selected" : "Filters"}
          type="btn-grey btn-h-auto"
          prefixIcon="ri-filter-3-line"
        />
        {isFilterOpen && (
          <div className={`filter-container-body-wrapper ${position}`}>
            <div className="filter-container-header">
              <p>Filters</p>
              <Button
                label="Reset All"
                type="button-white btn-h-auto"
                handleClick={onClickReset}
              />
            </div>

            <div className="filter-container-body">
              {data?.map((items: any) => {
                return (
                  <div className="filter-container-main" key={items.key}>
                    <div
                      onClick={() => toggleFilterOptions(items.key)}
                      className="filter-container-main-header"
                    >
                      <p>
                        {items.header}
                        {!!selectedValue?.[items.key]?.length && (
                          <span>
                            {selectedValue?.[items.key]?.split(",")?.length}{" "}
                            Selected
                          </span>
                        )}
                      </p>
                      <span
                        className={`ri ${
                          activeFilterOptions === items.key
                            ? "ri-arrow-up-s-line"
                            : "ri-arrow-down-s-line"
                        }`}
                      ></span>
                    </div>
                    {activeFilterOptions === items.key && (
                      <div className="filter-container-options">
                        {items?.options?.map((option: any) => {
                          return (
                            <Checkbox
                              disabled={isLoading}
                              key={option.value}
                              note=""
                              className={""}
                              value={
                                !!selectedValue?.[items.key]?.includes(
                                  option.value
                                )
                              }
                              onChange={() =>
                                handleClickOnCheckbox(items.key, option.value)
                              }
                              content={option.label}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};
