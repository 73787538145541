import { atom } from "recoil";
import { IInfoState } from "./type";
export const InfoState = atom<IInfoState>({
  key: "info-state",
  default: {
    address: "",
    balance: 0,
    changePercent24Hr: 0,
    priceUsd: 0,
    currentPrice: 0,
  },
});
