export const SuccessIcon = () => {
	return (
		<div className="wrapper">
			<svg
				className="approve_icon"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 52 52"
			>
				<circle
					className="approve_icon_circle"
					cx="26"
					cy="26"
					r="25"
					fill="none"
				/>
				<path
					className="approve_icon_check"
					fill="none"
					d="M14.1 27.2l7.1 7.2 16.7-16.8"
				/>
			</svg>
		</div>
	);
};
