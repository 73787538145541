import React, { useCallback, useEffect, useMemo, useState } from "react";

import "./auction-data-room.scss";
import { APIS, FILE_EXTENSION_URL } from "../../../../constant";
import {
  formatFileSize,
  getDateWithTime,
} from "../../../../utils";
import { Json } from "../../../../types";
import { Button, SkeletonSearch } from "../../../../components";
import { AUCTION_DATA_ROOM_TABS } from "../../constant";
import { Image } from "../../../../@storybook";
import { useNetwork, useNotification, usePrevious } from "../../../../hooks";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useDownloadGoogleapisFile } from "@hooks/download-googleapis-file";
import { Loader } from "../../../../components/Loader2";

type IAuctionDataRoom = {
  auctionDetails: Json;
  sba?: boolean;
};
export const AuctionDataRoom = ({ auctionDetails, sba }: IAuctionDataRoom) => {
  const { data: auctionData = {}, loading } = auctionDetails;
  const { errorNotification } = useNotification();
  const {
    get: fetchDataRoomHistory,
    data: dataRoomHistory,
    loading: loadingHistory,
  } = useNetwork();
  const prevLoadingHistory = usePrevious(loadingHistory);
  const [activeTab, setActiveTab] = useState("files");
  const { downloadGoogleapisFile, loadingStates} = useDownloadGoogleapisFile();

  const fileImageMap: Json = {
    create: "fileAdded",
    delete: "fileDeleted",
    update: "fileEdited",
  };

  useEffect(() => {
    if (auctionData.folderId)
      fetchDataRoomHistory(
        `${sba ? APIS.sbaDataRoomLog : APIS.auctionDataRoomLog}/${
          auctionData.folderId
        }`
      );
  }, [auctionData.folderId, fetchDataRoomHistory, sba]);

  useEffect(() => {
    setActiveTab('files')
  }, [auctionData.folderId])

  useEffect(() => {
    if (
      dataRoomHistory?.message !== "ok" &&
      !loadingHistory &&
      prevLoadingHistory
    ) {
      errorNotification(dataRoomHistory?.message);
    }
  }, [dataRoomHistory]);

  const handleOpenDataRoom = useCallback((url: string) => {
    window.open(url, "_blank");
  }, []);

  const renderEditHeading = useCallback((history: Json) => {
    switch (history?.operation) {
      case "create":
        return (
          <>
            {history?.createdName} has <b>added</b> {history?.fileData?.length > 1 ? `${history?.fileData?.length} new files` : "a new file"}
          </>
        );
      case "update":
        return (
          <>
            {history?.createdName} <b>changed the file name</b> from{" "}
            {history?.fileData?.[0]?.oldName} to{" "}
            {history?.fileData?.[0]?.docName}
          </>
        );
      case "delete":
        return (
          <>
            {history?.createdName}  <b>deleted</b> {history?.fileData?.length > 1 ? `${history?.fileData?.length} files` : "a file"} 
          </>
        );
    }
  }, []);

  return (
    <div className="auction-data-room-container">
      <h2 className="auction-details-heading">Data Room</h2>

      <div className="line-tabs mt-20 mb-10">
        {AUCTION_DATA_ROOM_TABS.map((tab) => (
          <div
            key={tab.type}
            className={`line-tabs_item ${
              activeTab === tab.type ? "active" : ""
            }`}
            onClick={() => setActiveTab(tab.type)}
          >
            {tab.title}
          </div>
        ))}
      </div>

      {activeTab === "files" && (
        <>
          {loading && (
            <SkeletonSearch className="data-room-files" listsToRender={2} />
          )}
          {!loading && !!auctionData?.dataRoom?.length && (
            <div className="data-room-files">
              {auctionData?.dataRoom?.map((file: Json) => (
                <div className="data-room-item" key={file?.id}>
                  <div className="data-room-img">
                    <img
                      src={
                        FILE_EXTENSION_URL[file?.fileName?.split(".")?.[1]] 
                      }
                      alt={file?.docName}
                      onClick={() => handleOpenDataRoom(file?.fileUrl)}
                    />
                  </div>
                  <div className="data-room-details">
                    <div
                      className="pointer"
                      onClick={() => handleOpenDataRoom(file?.fileUrl)}
                    >
                      {file?.docName}
                    </div>
                    <span>{formatFileSize(file?.fileSize || 0)}</span>
                  </div>
                  <Button
                    type="btn-outline-blue"
                    label={loadingStates?.[file?.fileUrl] ? <Loader dimension={12} /> : "Download"}
                    handleClick={() => downloadGoogleapisFile(file?.fileUrl, file?.fileName)}
                  />
                </div>
              ))}
            </div>
          )}
          {!auctionData?.dataRoom?.length && !loading && (
            <div className="no-data-found">
              <Image fileName="images/no_data_light.svg" />
              No data available
            </div>
          )}
        </>
      )}

      {activeTab === "editHistory" && (
        <>
          {!loadingHistory && !!dataRoomHistory?.data?.length && (
            <div className="edit-history-container">
              {dataRoomHistory?.data?.map((history: Json) => (
                <div key={history?.createdAt} className="history-item">
                  <Image
                    className="edit-img"
                    fileName={`icon/${fileImageMap[history.operation]}.svg`}
                  />
                  <div className="edit-detail">
                    <div className="edit-history-heading">
                      {renderEditHeading(history)}
                    </div>
                    <div className="edit-history-time">
                      {!!history?.time ? getDateWithTime(
                        history?.time,
                        "MMMM dd, yyyy hh:mm a"
                      ) : "--"}
                    </div>
                    {history?.fileData?.map((file: Json) => (
                      <div className="history-file" key={file?.id}>
                        <Image
                          className="edit-img"
                          url={
                            FILE_EXTENSION_URL[file?.fileName?.split(".")?.[1]]
                          }
                        />
                        <div className="file-name">
                          <span className="file-docname" data-tooltip-id={file?.docName}>{file?.docName}</span>
                          <span className="file-size">
                            {formatFileSize(file?.fileSize || 0)}
                          </span>
                          <ReactTooltip
                            id={file?.docName}
                            place="bottom"
                            content={file?.docName}
                            className={"theme-tooltip"}
                            style={{ zIndex: "999999" }}
                          />
                        </div>
                        <Button
                          type="view-btn btn-h-auto"
                          label="View"
                          handleClick={() => handleOpenDataRoom(file?.fileUrl)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
          {!loadingHistory && !dataRoomHistory?.data?.length && (
            <div className="no-data-found">
              <Image fileName="images/no_data_light.svg" />
              No data available
            </div>
          )}
        </>
      )}
    </div>
  );
};
