import React, { useCallback, useMemo } from "react";
import users from "../../../../../../assets/images/Users.svg";
import UsersLight from "../../../../../../assets/images/UsersLight.svg";
import classNames from "classnames";

import styles from "./verificationIncomplete.module.sass";
import {
  isStartVerificationNewState,
  openComplianceState,
  reOpenKycSignupModalState,
  reinitiateKycState,
  userDetailsState,
} from "@states/user";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useDarkMode from "use-dark-mode";

interface IVerificationIncomplete{
  setIsKycDone:any
  text:string
  handleClosePreviousModal?:any
}

const VerificationIncomplete = ({
  setIsKycDone,
  text,
  handleClosePreviousModal,
}: IVerificationIncomplete) => {
  const setReinitiateKyc = useSetRecoilState(reinitiateKycState);
  const setIsStartVerification = useSetRecoilState(isStartVerificationNewState);
  const setOpenCompliance = useSetRecoilState(openComplianceState);
  const setReOpenKycSignupModal = useSetRecoilState(reOpenKycSignupModalState);
  const darkmode = useDarkMode(false);
  const userDetails = useRecoilValue(userDetailsState);

  const { kycStatus, amlStatus,kybStatus, accreditationStatus } =
  userDetails?.data?.onboardingData || {};
  
  const handleOpenKycModal = useCallback(() => {
    handleClosePreviousModal();
    setIsKycDone(false);
    setReinitiateKyc(true);
    setIsStartVerification(true);
    setOpenCompliance(true);
    setReOpenKycSignupModal(true);
  }, []);

  const renderKycStatus = useMemo(() => {
    return (
      <div className={styles.renderKycStatus}>
        <div className={styles.renderKycStatus__usersImg}>
          <img src={darkmode.value ? users : UsersLight} alt="" />
        </div>
        <div className={styles.renderKycStatus__Texts}>
          <div className={styles.renderKycStatus__Texts__heading}>
            Verification incomplete
          </div>
          <div className={styles.renderKycStatus__Texts__subHeading}>
            You haven't completed verification. Please complete your
            verification to make {text}.
          </div>
        </div>
        <div className={styles.renderKycStatus__Status}>
          <div className={styles.renderKycStatus__Status__upper}>
            <div
              className={classNames(
                styles.renderKycStatus__Status__Kyc,
                styles.renderKycStatus__Status__border
              )}
            >
              {kycStatus === "completed" ? (
                <i
                  className={classNames(
                    `ri-checkbox-circle-fill`,
                    styles.renderKycStatus__Status__checkboxIcon
                  )}
                />
              ) : (
                <span className={classNames(styles.renderKycStatus__Status__circle,{[styles.renderKycStatus__Status__red]:kycStatus==="rejected"},{[styles.renderKycStatus__Status__yellow]: kycStatus=== "processing"})}></span>
              )}
              Identity Verification
            </div>
            <div
              className={classNames(
                styles.renderKycStatus__Status__aml,
                styles.renderKycStatus__Status__border
              )}
            >
              {amlStatus === "completed" ? (
                <i
                  className={classNames(
                    `ri-checkbox-circle-fill`,
                    styles.renderKycStatus__Status__checkboxIcon
                  )}
                />
              ) : (
                <span className={classNames(styles.renderKycStatus__Status__circle,{[styles.renderKycStatus__Status__red]:amlStatus ==="rejected"},{[styles.renderKycStatus__Status__yellow]: amlStatus === "processing"})}></span>
              )}
              AML
            </div>
          </div>
          {kybStatus && <div
            className={classNames(
              styles.renderKycStatus__Status__accreditation,
              styles.renderKycStatus__Status__border
            )}
          >
            {kybStatus === "completed" ? (
              <i
                className={classNames(
                  `ri-checkbox-circle-fill`,
                  styles.renderKycStatus__Status__checkboxIcon
                )}
              />
            ) : (
              <span className={classNames(styles.renderKycStatus__Status__circle ,{[styles.renderKycStatus__Status__red]:kybStatus ==="rejected"},{[styles.renderKycStatus__Status__yellow]: kybStatus === "processing" } )}></span>
            )}
           KYB
          </div>}
          {!kybStatus && <div
            className={classNames(
              styles.renderKycStatus__Status__accreditation,
              styles.renderKycStatus__Status__border
            )}
          >
            {accreditationStatus === "completed" ? (
              <i
                className={classNames(
                  `ri-checkbox-circle-fill`,
                  styles.renderKycStatus__Status__checkboxIcon
                )}
              />
            ) : (
              <span className={classNames(styles.renderKycStatus__Status__circle ,{[styles.renderKycStatus__Status__red]:accreditationStatus ==="rejected"},{[styles.renderKycStatus__Status__yellow]: accreditationStatus === "processing" } )}></span>
            )}
           Accreditation 506(b) 
          </div>}
        </div>
        <button
          className={styles.renderKycStatus__btn}
          onClick={handleOpenKycModal}
        >
          Complete verification
        </button>
      </div>
    );
  }, []);
  return <>{renderKycStatus}</>;
};

export default VerificationIncomplete;
