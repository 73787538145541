import React, { FC, useCallback } from "react";
import { INotificationDrawer } from "./type";
import OutsideClickHandler from "react-outside-click-handler";
import UseNotificationApi from "@views/Notification/store/useNotificaitonApi";
import { Loader } from "..";

import "./notificationDrawer.scss";
import { activeNotifcationTabState } from "@views/Notification/store/state";
import { useSetRecoilState } from "recoil";

const NotificationDrawer: FC<INotificationDrawer> = ({
  visibleNotification,
  setVisibleNotification,
  notificationBody,
}) => {
  const { updateMarkAsRead, updatedReadLoading } = UseNotificationApi();
  const setActiveTab = useSetRecoilState(activeNotifcationTabState);

  const handleAllRead = useCallback(() => {
    updateMarkAsRead({ readStatus: "ALL" });
  }, [updateMarkAsRead]);

  const handleOutsideNotificationClick = useCallback(() => {
    setVisibleNotification(false);
    setActiveTab("ALL");
  }, []);

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideNotificationClick}>
      {
        <div
          className={`NotificationDrawer ${
            visibleNotification ? "NotificationDrawer--open" : ""
          }`}
        >
          <div className="NotificationDrawerTop">
            <div className="NotificationDrawer__heading">Notifications</div>
            <div
              className="NotificationDrawer__markasreadContainer"
              onClick={handleAllRead}
            >
              <span className="NotificationDrawer__markasreadContainer__doubleCheck">
                {updatedReadLoading ? (
                  <Loader dimension={20} />
                ) : (
                  <i className="ri-check-double-line NotificationDrawer__markasreadContainer__doubleChec" />
                )}
              </span>
              <div className="NotificationDrawer__markasreadContainer__text">
                Mark all as read
              </div>
            </div>
          </div>

          <div className="NotificationDrawer__body">{notificationBody}</div>
        </div>
      }
    </OutsideClickHandler>
  );
};

export default NotificationDrawer;
