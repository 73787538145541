import React, { useCallback, useMemo } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { Image } from "@storybook";
import { Json } from ".././../../../types/common";
import { useCurrency } from "@hooks/currency";
import { capitalizeFirstLetterOnly } from "@utils/common";
import { NotificationVisibleState } from "@views/Notification/store/state";
import UseNotificationApi from "@views/Notification/store/useNotificaitonApi";
import { useNotification } from "@hooks/notification";

import "./walletNotification.scss";

const WalletNotification = (data: Json) => {
  const setVisibleNotification = useSetRecoilState(NotificationVisibleState);
  const navigate = useNavigate();
  const { errorNotification } = useNotification();
  const {data: {metadata: {initiatorId, transId}}} = data ?? {};

  const { updateMarkAsRead } = UseNotificationApi();
  const { formatCurrencyWithBillion } = useCurrency();

  const notificationData = useMemo(() => {
    return {
      title: data?.data?.type,
      subTitle: data?.data?.metadata?.transactionId,
      status: data?.data?.actionStatus,
      readStatus: data?.data?.readStatus,
      time: data?.data?.createdAt
        ? `${new Date(data?.data?.createdAt).getDate()} ${new Date(
            data?.data?.createdAt
          ).toLocaleDateString("en-US", {
            month: "short",
          })}, ${new Date(data?.data?.createdAt).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}`
        : "-",
      userName: data?.data?.metadata?.name,
      notificationId: data?.data?._id,
      userAvatar: data?.data?.metadata?.profileUrl,
      bank: data?.data?.metadata?.bankName,
      accountNumber: data?.data?.metadata?.accountNumber,
      amount: data?.data?.metadata?.amount,
    };
  }, [
    data?.data?._id,
    data?.data?.actionStatus,
    data?.data?.createdAt,
    data?.data?.metadata?.accountNumber,
    data?.data?.metadata?.amount,
    data?.data?.metadata?.bankName,
    data?.data?.metadata?.name,
    data?.data?.metadata?.profileUrl,
    data?.data?.metadata?.transactionId,
    data?.data?.readStatus,
    data?.data?.type,
  ]);

  const statusColor: any = {
    PROCESSING: "processing",
    CANCELLED: "cancelled",
    SUCCESS: "completed",
    FAILED: "failure",
  };
  
  const handleNotificationClick = useCallback((id: string) => {
    if(!initiatorId && !transId) {
      errorNotification('initiator Id is mising')
    }
    else{
      setVisibleNotification(false);
      if(notificationData?.readStatus==="UNREAD"){
      updateMarkAsRead({ id: id });
      }
      navigate(`/wallet/transactions/${transId}`);
    }
     }, [initiatorId, transId, errorNotification, setVisibleNotification, notificationData?.readStatus, navigate, updateMarkAsRead]);

  return (
    <div
      className="notificationSection"
      onClick={() => handleNotificationClick(notificationData?.notificationId)}
    >
      <div className="walletNotification">
        <div className="walletNotification__left">
          <div className="walletNotification__left__icon">
            <i
              className={`${
                notificationData?.title === "DEPOSIT"
                  ? "ri-arrow-down-circle-fill deposit "
                  : "ri-arrow-up-circle-fill withdraw"
              }`}
            />
          </div>
          {notificationData?.readStatus === "UNREAD" && (
            <div className="walletNotification__left__readMark"></div>
          )}
          <div>
            <div className="walletNotification__left__title">
              {capitalizeFirstLetterOnly(notificationData?.title)}
            </div>
            <div className="walletNotification__left__Subtitle">
              {notificationData?.subTitle}
            </div>
            <div className="walletNotification__left__userDetail">
              {notificationData?.userAvatar ? (
                <img
                  className="walletNotification__left__userDetail__userImg"
                  src={notificationData?.userAvatar}
                  alt="profile_image"
                />
              ) : (
                <Image
                  className="walletNotification__left__userDetail__userImg"
                  fileName={"icon/avatar.svg"}
                />
              )}
              <div className="walletNotification__left__userDetail__userName">
                {notificationData?.userName}
              </div>
            </div>
            <div className="walletNotification__left__bankDetails">
              {`${notificationData?.bank} (****${notificationData?.accountNumber})`}
            </div>
          </div>
        </div>

        <div className="walletNotification__right">
          <div className="walletNotification__right__amount">
            {formatCurrencyWithBillion(notificationData?.amount, 4)}
          </div>
          <div className="walletNotification__right__status">
            <div
              className={`walletNotification__right__dot  ${
                statusColor[notificationData?.status]
              }`}
            ></div>
            <div>{capitalizeFirstLetterOnly(notificationData?.status)}</div>
          </div>
          <div className="walletNotification__right__time">
            {notificationData?.time}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletNotification;
