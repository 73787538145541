import { Button } from "@components/button";
import "./mpc-setup-status.scss";
import { Image } from "@storybook";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "@routes/constants";
import { useCookie } from "@hooks/cookie";
import { useRecoilValue } from "recoil";
import { LoginUserState } from "@states/user";
import { loginToasterMessages } from "constant";
import { useNotification } from "@hooks/notification";
import { useCallback, useEffect } from "react";

const MpcSetupStatus = ({showButton=true,showLogo=true ,success=true}) =>{
    const navigate = useNavigate();
    const {  EXCHANGE, MPC_QR } = ROUTES;
    const { set: setCookieToken } = useCookie();
    const tempLoginUser = useRecoilValue(LoginUserState);
    const { LOGIN_SUCCESS } = loginToasterMessages;
    const { successNotification } = useNotification();
    const { state: locationState } = useLocation();


    useEffect(()=>{
        if(!locationState?.userId){
            navigate(MPC_QR)
        }

        if(locationState?.isSuccess){
            setTimeout(()=> handleLogin(),5000)
        }
    },[])

    const handleLogin = useCallback(() => {
        setCookieToken("userDetails", tempLoginUser);
        navigate(EXCHANGE);
        successNotification(LOGIN_SUCCESS);
    }, [tempLoginUser]);

    const handleTryAgain = () =>{
        navigate(MPC_QR)
    };

    return(
        <div className="mpc-status-container">
            {showLogo &&<div className="header">
                <Image
                className="brand_logo"
                fileName={`images/Liquidity_Blue_Logo.png`}
                />
            </div>}
            {!locationState?.isSuccess ? 
            <div className="body">
                <div className="iconWrapper">
                    <i className="ri-close-line icon"></i>
                </div>
                <div>
                    <h2 className="heading">Setup unsuccessful</h2>
                    <p className="para">We encountered an issue with configuring and storing your private key. Please try again or contact us at <a href="mailto:contact@liquidity.io">contact@liquidity.io</a> for any assistance.</p>
                </div>
                {showButton && <div>
                    {/* <button className="skipButton">Skip for now</button> */}
                    <Button type="button skipButton" label="Skip for now" handleClick={handleLogin}/>
                    <Button type="button" label="Try again" handleClick={handleTryAgain}/>
                </div>}
            </div>
            :
            <div className="body">
                <div className="iconWrapper iconWrapperGreen">
                    <i className="ri-check-line icon"></i>
                </div>
                <div>
                    <h2 className="heading">Setup successful</h2>
                    <p className="para">Your MPC Wallet has been successfully setup. Your private key is now securely distributed across multiple locations.</p>
                </div>
                <p className="para">Redirecting you to verification</p>
            </div>
            }
        </div>
    );
};
export default MpcSetupStatus;