import { useCallback, useEffect, useRef, useState } from "react";

export const usePrevious = (value) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const useIsTabActive = () => {
  const [isTabVisible, setIsTabVisible] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setIsTabVisible(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isTabVisible;
};

export const useDebounce = (value, data = {}, delay = 1200) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    if (data && data.immediate) {
      clearTimeout(handler);
      setDebouncedValue(value);
    }

    return () => {
      clearTimeout(handler);
    };
  }, [value]);
  return debouncedValue;
}

export const useThrottle = (func, delay) => {
  let lastCall = 0;
  return function (...args) {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return func(...args);
  };
};


export const useRemainingSecPercentage = (startTime, endTime, startInterval = true) => {
  const [percentageRemaining, setPercentageRemaining] = useState(null);
  const timeRef = useRef(null);

  useEffect(() => {
    calculatePercentageRemaining();
    if (startInterval) {
      timeRef.current = setInterval(calculatePercentageRemaining, 1000);
    }
    return () => {
      clearInterval(timeRef.current);
    };
  }, [startTime, endTime, startInterval]);

  const calculatePercentageRemaining = () => {
    const startDateTime = new Date(startTime);
    const endDateTime = new Date(endTime);

    const remainingTime = endDateTime - new Date();
    const totalTime = endDateTime - startDateTime;

    const percentage = (remainingTime / totalTime) * 100;
    if (percentage <= 0) {
      clearInterval(timeRef.current);
      setPercentageRemaining(0);
    } else {
      setPercentageRemaining(percentage.toFixed(2));
    }
  };
  return percentageRemaining;
};
