import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import useDarkMode from "use-dark-mode";
import cn from "classnames";

import {
  VisibleModifyState,
  VisibleCancelState,
  CollapseState,
  ActiveCurrencyState,
  defaultFormDatastate,
  userSelectedCurrencyState, SocketTriggeredOnOrderState,
} from "states";
import { emptyTable } from "../constants";
import { ReactModal, SkeletonTable } from "components";
import { Actions } from "./../../Actions";
import { CancelOrder } from "../../cancel-order/cancel";
import styles from "./OrderTable.module.sass";
import { APIS, Messages } from "constant";
import { Image } from "@storybook";
import { ExecutedOrderToasterState, OrderPlacedExecutedCheckState } from "../store/state";
import { useNetwork, useNotification, usePrevious } from "hooks";
import InfiniteScroll from "react-infinite-scroll-component";
import { Json } from "../../../../../types/common";
import TableData from "./tableData";
import { ORDER_TYPE } from "../constants";
import UseOrdersApi from "../store/hook";

type ITable = {
  activeTab: string;
  handleFetchMore:()=>void;
  filters:Json;
  ordersData:Json;
  setOrdersData:any;
};

const { ORDER_EXECUTED } = Messages;

export const OrderTable = ({ activeTab ,handleFetchMore,filters,ordersData,setOrdersData}: ITable) => {
  const [visibleModify, setVisibleModify] = useRecoilState(VisibleModifyState);
  const [visibleCancel, setVisibleCancel] = useRecoilState(VisibleCancelState);
  const collapse = useRecoilValue(CollapseState);
  const setData = useSetRecoilState(defaultFormDatastate);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const currencyDetails = useRecoilValue(userSelectedCurrencyState);
  const { successNotification } = useNotification();
  const [orderExecutedToaster,setOrderExecutedToaster]=useRecoilState(ExecutedOrderToasterState)
  const orderPlacedExecutedCheck=useRecoilValue(OrderPlacedExecutedCheckState)
  const socketTriggeredOnOrderValue = useRecoilValue(SocketTriggeredOnOrderState);
  const prevSocketTrigger = usePrevious(socketTriggeredOnOrderValue);

  
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const {get:getOrders}=useNetwork()

  const {getUsersAssetPortfolio}=UseOrdersApi()

  const ordersForActiveTab = ordersData[activeTab as keyof typeof ordersData];
  const ordersLength = Array.isArray(ordersForActiveTab)
    ? ordersForActiveTab.length
    : 0;

  const {
    marketPrice,
    symbol,
    amount,
    id: activeCurrencyId,
  }: any = activeCurrency ?? {};
    
  const assetPrice = marketPrice || amount;
  const darkMode = useDarkMode(false); 

  const handleClick = useCallback(() => {
    setVisibleModify(false);
    setData((prev: any) => {
      return {
        ...prev,
        symbol,
        price: (assetPrice * currencyDetails.rate).toFixed(4),
        qty: "1",
      };
    });
  }, [currencyDetails.rate, assetPrice, setData, setVisibleModify, symbol]);

  //  Need to handle this for execution toaster
  useEffect(() => {
    if (socketTriggeredOnOrderValue?.type === "ORDERHISTORY" && activeCurrencyId && prevSocketTrigger?.timeStamp !== socketTriggeredOnOrderValue?.timeStamp) {
      if (socketTriggeredOnOrderValue?.status === "CREATED" || socketTriggeredOnOrderValue?.status === "EXECUTED")
        successNotification(socketTriggeredOnOrderValue?.message);
      getOrders(`${APIS.ORDERS}?assetId=${activeCurrencyId}&type=${activeTab}&offset=0&limit=20`).then((res: any) => {
        if (res) {
          setOrderExecutedToaster(!orderExecutedToaster);
          getUsersAssetPortfolio(activeCurrencyId);
        }
      });
    }
  }, [socketTriggeredOnOrderValue]);

  return (activeTab === "PENDING" && ordersData[activeTab]?.length) ||
    (activeTab === "EXECUTED" && ordersData[activeTab]?.length) ||
    (activeTab === "CANCELLED" && ordersData[activeTab]?.length) ? (
    <div
      className={cn({
        [styles.inner]: collapse,
        [styles.collapse_inner]: !collapse,
      })}
      id="scrollableDiv"
    >
      <div className={styles.table}>
        <InfiniteScroll
          dataLength={ordersLength || 0}
          next={handleFetchMore}
          hasMore={
            ordersLength === filters.offset * filters.limit + filters.limit
          }
          loader={""}
          scrollableTarget="scrollableDiv"
        >
          <TableData activeTab={activeTab} ordersData={ordersData} />
        </InfiniteScroll>
      </div>
      <ReactModal
        visible={visibleModify}
        onClose={() => handleClick()}
        closeBtn={true}
        maskClosable={false}
      >
        <Actions isOrderModifying={visibleModify} />
      </ReactModal>
      <ReactModal
        maskClosable={false}
        visible={visibleCancel}
        onClose={() => setVisibleCancel(false)}
        outerClassName={styles.confirmationModalOuter}
      >
        <CancelOrder ordersData={ordersData} setOrdersData={setOrdersData}/>
      </ReactModal>
    </div>
  ) : ordersData?.loading? (
        <div className={styles.orders}>
          <SkeletonTable listsToRender={collapse ? 7 : 2} numberColumn={6} />
        </div>
      ):
  (
    <div
      className={cn({
        [styles.inner]: collapse,
        [styles.collapse_inner]: !collapse,
      })}
    >
      <div className={styles.emptyState}>
        <Image
          className={styles.emptyImage}
          fileName={
            darkMode.value
              ? "images/no_data_dark.svg"
              : "images/no_data_light.svg"
          }
        />
        {collapse && (
          <div className={styles.noDataText}>
            {activeTab === ORDER_TYPE.PENDING
              ? emptyTable.NO_OPEN_ORDER
              : activeTab === ORDER_TYPE.EXECUTED
              ? emptyTable.NO_EXECUTED_ORDER
              : emptyTable.NO_CANCELLED_ORDER}
          </div>
        )}
      </div>
    </div>
  );
};
