import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";

import { APIS } from "constant";
import { initialQuerySbaState, SbaState } from "states";
import { useNetwork } from "hooks";
import { SkeletonWatchList } from "components";
import { SbaItem } from "./sba-item";
import { Image } from "@storybook";
import useDarkMode from "use-dark-mode";

import styles from "../Currency/Currency.module.sass";
import InfiniteScroll from "react-infinite-scroll-component";
import { getJsonToParams } from "@utils/common";

export const Sba = () => {
    const [filters, setFilters] = useRecoilState(initialQuerySbaState);

  const { get: getSba7Data, loading: loadingISSBA } = useNetwork();

  const [sba, setSba] = useRecoilState(SbaState);
  const { get: getSba7 } = useNetwork();

  const darkMode = useDarkMode(false);
  const refSBAInterval = useRef<any>(null);


  // This code is for exchange explorer api polling
  const NewObjectWithNewIdAdded = useCallback(
    (previousArray: any, currentArray: any) => {
      const previousIds = previousArray.map((obj: any) => obj.id);
      const newSBA7Set = new Set(previousIds);
      if (!newSBA7Set.has(currentArray[0]?.id)) {
        return currentArray[0];
      } else {
        return "";
      }
    },
    []
  );

  useEffect(() => {
    refSBAInterval.current = setInterval(() => {
      getSba7(`${APIS.Explorers}?include=sba7`).then((res: any) => {
        if (res?.message === "ok") {
          const newSBA7Asset = NewObjectWithNewIdAdded(sba, res?.data?.sba7);
          if (newSBA7Asset) {
            setSba((prev: any) => {
              return [newSBA7Asset, ...prev];
            });
          }
        }
      });
    }, 5000);
    return () => clearInterval(refSBAInterval.current);
  }, [sba]);

  const handleFetchMore = useCallback(() => {
    const queryParams = { ...filters, offset: filters.offset + 1 };
    getSba7Data(`${APIS.Explorers}` + getJsonToParams(queryParams)).then((res: any) => {
      if (res) {
        setSba((prev: any) => {
          return [...prev, ...res?.data?.sba7];
        });
      }
    });
    setFilters((prev) => ({ ...queryParams }));
  }, [filters]);

  return (
    <>
      {sba?.length === 0 ? (
        <div className={styles.noData}>
          <div className={styles.noDataContent}>
            <Image
              fileName={`images/${
                darkMode.value ? "no_data_dark.svg" : "no_data_light.svg"
              }`}
            />
            <div>No Data Found</div>
          </div>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={sba.length || 0}
          next={handleFetchMore}
          hasMore={
            sba.length >= filters.offset * filters.limit + filters.limit
          }
          loader={""}
          scrollableTarget="assets-scrollableDiv"
        >
          {sba?.map((data, index) => {
            return <SbaItem key={data?.id} index={index} {...(data ?? {})} />;
          })}
        </InfiniteScroll>
      )}

      {loadingISSBA && <SkeletonWatchList listsToRender={4} />}
    </>
  );
};
