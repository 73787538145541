import { atom, selector } from "recoil";
import { IActiveNewsData } from "./type";

export const newsTabState = atom<string>({
  key: "news-tab-state",
  default: "all",
});
export const AllNewsState = atom<IActiveNewsData[]>({
  key: "all-news-state",
  default: [],
});
export const StocksNewsState = atom<IActiveNewsData[]>({
  key: "stock-news-state",
  default: [],
});
export const CryptoNewsState = atom<IActiveNewsData[]>({
  key: "crypto-news-state",
  default: [],
});
export const PrivatesNewsState = atom<IActiveNewsData[]>({
  key: "private-news-state",
  default: [],
});
export const MusicNewsState = atom<IActiveNewsData[]>({
  key: "music-news-state",
  default: [],
});
export const ForexNewsState = atom<IActiveNewsData[]>({
  key: "forex-news-state",
  default: [],
});

export const CommoditiesNewsState = atom<IActiveNewsData[]>({
  key: "commodity-news-state",
  default: [],
});

export const fullNewsUrlState = atom<string>({
  key: "full-news-url-state",
  default: "",
});

export const newsState = atom<any>({
  key: "news-state",
  default: {
    stocks: [],
    crypto: [],
    private: [],
    commodities: [],
    forex: [],
    marketIndexe: [],
  },
});

export const combinedState = selector({
  key: "combinedState",
  get: ({ get }) => {
    const stocksdata = get(StocksNewsState);
    const forexData = get(ForexNewsState);
    return [...stocksdata, ...forexData];
  },
});
