import useDarkMode from "use-dark-mode";
import cn from "classnames";

import "./skeleton-loader.scss";


interface ISkeleton {
  width?: number;
  height?: number;
}

export const Skeleton = ({ height, width }: ISkeleton) => {
  const darkMode = useDarkMode(false);
  return (
    <div className="skeleton_container" style={{ width: `${width}px` }}>
      <span
        className={cn({
          "skeleton-loader-dark": darkMode.value,
          "skeleton-loader-light": !darkMode.value,
        })}
        style={{ height: `${height}px` }} />
    </div>
  );
};

export const SkeletonHeader = () => {
  const darkMode = useDarkMode(false);
  return (
    <div className="skeleton_Header">
      <div
        className={cn("skeleton_Header_upper", {
          "skeleton-loader-dark": darkMode.value,
          "skeleton-loader-light": !darkMode.value,
        })}
      />
      <div
        className={cn("skeleton_Header_lower", {
          "skeleton-loader-dark": darkMode.value,
          "skeleton-loader-light": !darkMode.value,
        })}
      />
    </div>
  );
};

export const SkeletonLogo = ({ className = "", style = {} }) => {
  const darkMode = useDarkMode(false);
  return (
    <div
      className={cn("skeleton_logo", className, {
        "skeleton-loader-dark": darkMode.value,
        "skeleton-loader-light": !darkMode.value,
      })} style={style}
    />
  );
};
