import { atom } from "recoil";
import { INotificationData } from "./type";

export const NotificationDataState = atom<INotificationData>({
  key: "notification-data-state",
  default: {
    loading: false,
    ALL: [],
    ORDERS: [],
    INVITE: [],
    MISCELLANEOUS: [],
  },
});

export const activeNotifcationTabState = atom({
  key: "active-notifcation-tabstate",
  default: "ALL",
});

export const NotificationVisibleState = atom({
  key: "notification-visible-state",
  default: false,
});
