import React, { useCallback, useEffect, useState } from "react";

import "../../auction-consumer.scss";
import { Pagination } from "../../../../components";
import { BidCard, SkeletonBidCard } from "../index";
import { useAuctionApis, useDebounce, useNotification, usePrevious } from "hooks";
import { Json } from "../../../../types";
import { useRecoilState, useRecoilValue } from "recoil";
import { AuctionBidPostState, AuctionListState } from "../../../../states";
import { Image } from "../../../../@storybook";
import { useSearchParams } from "react-router-dom";
import { getObjectFromUseSearchParams } from "@utils/common";

export const UserWishListAuctions = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFilter = { limit: 20, offset: 0 };
  const { errorNotification } = useNotification();
  const { fetchAuctionList } = useAuctionApis();
  const [{ data: auctionWishlist = {}, loading }, setAuctionWishlistState] = useRecoilState(AuctionListState);
  const prevLoading = usePrevious(loading);
  const { data: bidDetails = {}, loading: bidLoading } = useRecoilValue(AuctionBidPostState);
  const prevBidLoading = usePrevious(bidLoading);
  const [filters, setFilters] = useState<Json>({ ...initialFilter, ...getObjectFromUseSearchParams(searchParams) });
  const debouncedFilters = useDebounce(filters, { immediate: filters.immediate }, 400);

  useEffect(() => {
    handleCallWishlist();
  }, [debouncedFilters]);

  useEffect(() => {
    if (bidDetails?.message !== "ok" && !bidLoading && prevBidLoading) {
      errorNotification(bidDetails?.message);
    } else if (bidDetails?.message === "ok" && !bidLoading && prevBidLoading) {
      const {
        currentBidPrice, endTime, finalPrice, id, status, bidStatus,
        totalBid, userBidPrice, userMaxBidPrice, maxAuctionBidPrice
      } = bidDetails?.data || {};

      setAuctionWishlistState((prev) => {
        let temp = JSON.parse(JSON.stringify(prev?.data?.data));
        const idx = temp.findIndex((_data: Json) => _data?.id === id);
        temp[idx] = {
          ...temp[idx], currentBidPrice, endTime, finalPrice, status, bidStatus,
          totalBid, userBidPrice, userMaxBidPrice, maxAuctionBidPrice
        }
        return { ...prev, data: { ...prev.data, data: [...temp] } }
      });
    }
  }, [bidDetails]);

  const handleCallWishlist = useCallback(() => {
    const { immediate, tab, ..._filters } = filters;
    setSearchParams({ tab, ..._filters })
    fetchAuctionList({ isWatchlist: true, ..._filters });
  }, [filters]);

  const handleFilter = (filterObj: Json, debounce: boolean = true) => {
    setFilters((prev) => ({ ...prev, ...filterObj, immediate: !debounce }));
  };

  const handlePagination = useCallback((val: number, action: string) => {
    let pageChange: Json = {}
    if (action === "perPage") {
      pageChange.limit = val
      pageChange.offset = 0
    }
    if (action === "page") pageChange.offset = val
    handleFilter(pageChange);
  }, []);

  useEffect(() => {
    if (auctionWishlist?.message !== "ok" && !loading && prevLoading) {
      errorNotification(auctionWishlist?.message);
    }
  }, [auctionWishlist]);

  const handleOnWishlist = useCallback((auctionId: string) => {
    setAuctionWishlistState((prev) => {
      const temp = prev?.data?.data?.filter((_data: Json) => _data?.id !== auctionId);
      return { ...prev, data: { ...prev.data, data: [...temp] } }
    });
  }, []);

  return (<>
    <div className="auction-container user-wishlist py-0" style={{ height: "auto" }}>
      {auctionWishlist?.data?.map((detail: Json) => (
        <BidCard key={detail?.id} detail={detail} handleOnWishlist={handleOnWishlist} />
      ))}
      {loading && <SkeletonBidCard listsToRender={4} />}
      {!auctionWishlist?.data?.length && !loading && <div className="no-data-found">
        <Image fileName="icon/emptyCart.svg" />
        Your wishlist is empty
      </div>}
    </div>
    <Pagination showCount={false} listCount={auctionWishlist?.data?.length} page={parseInt(filters.offset)}
                perPage={parseInt(filters.limit)} onChangePage={(page) => handlePagination(page, "page")}
                onChangePerPage={(perPage) => handlePagination(perPage, "perPage")} />
  </>);
};
