import { useCallback, useLayoutEffect, useState } from "react";

export const useScrollSpy = (ids: string[], offset: number = 0) => {
  const [activeId, setActiveId] = useState(0);
  const clamp = useCallback((value: number) => {
    return Math.max(0, value);
  }, []);
  const isBetween = (value: number, top: number, bottom: number) => {
    const range = 200
    return top <= range && bottom >= range;
  }

  useLayoutEffect(() => {
    const wrapper = document.getElementById('exchange--wrapper')
    const listener = () => {
      const scroll = wrapper?.scrollTop ?? 0;

      const positions = ids
        .map((id, index) => {
          const element = document.getElementById(id);

          if (!element) return { id, top: -1, bottom: -1 };

          const rect = element.getBoundingClientRect();
          
          const top = clamp(rect.top);
          const bottom = clamp(rect.bottom);
          return { id, top, bottom, index };
        })
        const position = positions.find(({ top, bottom }) => isBetween(scroll, top, bottom));

        if (position?.index != null) {
          setActiveId(position?.index);
        }
    };

    listener();

    wrapper?.addEventListener("resize", listener);
    wrapper?.addEventListener("scroll", listener);

    return () => {
      wrapper?.removeEventListener("resize", listener);
      wrapper?.removeEventListener("scroll", listener);
    };
  }, [clamp, ids, offset]);

  return activeId;
};
