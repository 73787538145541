import { atom } from "recoil";
import { IAuctionListAPI } from "./types";

export const SbaLoanState = atom<IAuctionListAPI>({
  key: "sba-loan-state",
  default: {
    loading: false,
    data: [
      {
        loan_number: "4196379100",
        borrower_name: "LION TECHNOLOGIES, LLC",
        first_payment_date: "9/1/2022",
        maturity_date: "8/1/2032",
        original_loan_amount: "25,000.00",
        current_loan_balance: "24,512.39",
      },
      {
        loan_number: "4196379100",
        borrower_name: "LION TECHNOLOGIES, LLC",
        first_payment_date: "9/1/2022",
        maturity_date: "8/1/2032",
        original_loan_amount: "25,000.00",
        current_loan_balance: "24,512.39",
      },
      {
        loan_number: "4196379100",
        borrower_name: "LION TECHNOLOGIES, LLC",
        first_payment_date: "9/1/2022",
        maturity_date: "8/1/2032",
        original_loan_amount: "25,000.00",
        current_loan_balance: "24,512.39",
      },
      {
        loan_number: "4196379100",
        borrower_name: "LION TECHNOLOGIES, LLC",
        first_payment_date: "9/1/2022",
        maturity_date: "8/1/2032",
        original_loan_amount: "25,000.00",
        current_loan_balance: "24,512.39",
      },
      {
        loan_number: "4196379100",
        borrower_name: "LION TECHNOLOGIES, LLC",
        first_payment_date: "9/1/2022",
        maturity_date: "8/1/2032",
        original_loan_amount: "25,000.00",
        current_loan_balance: "24,512.39",
      },
      {
        loan_number: "4196379100",
        borrower_name: "LION TECHNOLOGIES, LLC",
        first_payment_date: "9/1/2022",
        maturity_date: "8/1/2032",
        original_loan_amount: "25,000.00",
        current_loan_balance: "24,512.39",
      },
    ],
  },
});
export const AuctionListState = atom<IAuctionListAPI>({
  key: "auction-list-state",
  default: { loading: false, data: {} },
});

export const AuctionProductCategoryState = atom<IAuctionListAPI>({
  key: "auction-product-category-state",
  default: { loading: false, data: {} },
});

export const AuctionBidPostState = atom<IAuctionListAPI>({
  key: "auction-bid-post-state",
  default: { loading: false, data: {} },
});

export const SelectedAuctionState = atom<any>({
  key: "assign-selected-auction-state",
  default: [],
});

export const SelectedauctionsIdState = atom<string[]>({
  key: "selected-auction-table-id-state",
  default: [],
});

export const GroupBulkState = atom<boolean>({
  key: "group-bulk-state",
  default: false,
});
