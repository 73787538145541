import { Image } from "@storybook";
import { useEffect } from "react";

export const BiometricPolicy = () => {
  useEffect(() => {
    const OneTrust = (window as any).OneTrust;
    OneTrust.NoticeApi.Initialized.then(function () {
      OneTrust.NoticeApi.LoadNotices([
        "https://privacyportal-cdn.onetrust.com/f28abd65-17b0-4df7-b558-d20b86121b10/privacy-notices/1020298a-b680-4a0d-87f7-aa01c34ad1f1.json",
      ]);
    });
  }, []);

  return (
    <div className="biometric-policy">
      {/* <div className="biometric-policy__header">
        <Image
          className="biometric-policy__logo"
          fileName={`images/Liquidity_Blue_Logo.png`}
        />
      </div> */}
      <div
        id="otnotice-1020298a-b680-4a0d-87f7-aa01c34ad1f1"
        className="otnotice"
      ></div>
    </div>
  );
};
