import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NewModal from '@components/new-modal/new-modal';
import { ChatBidderItem } from './ChatBidderItem';
// @ts-ignore
import { Json } from "@types/common";
import { APIS } from "../../../constant";
import { useNetwork } from "@hooks/network";
import { useNotification } from "@hooks/notification";
import { getJsonToParams } from "@utils/common";
import { assestType } from "@views/exchange/constants";
import { useCurrency } from "@hooks/currency";
import { defaultValue } from "@views/exchange/components/Balance/constants";
import { Loader } from "@components/Loader2";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRecoilValue } from "recoil";
import { selectedUserChatState } from "@views/Chat/ChatModal/state";
import { usePrevious } from "@hooks/common";

interface IChatBidderListModal {
  open: boolean;
  onClose: () => void;
  details: Json;
}

export const ChatBidderListModal: React.FC<IChatBidderListModal> = ({ open, onClose, details }) => {
  const initialFilter = { limit: 10, offset: 0 };
  const { get: getOrderBookGroup, loading: orderGroupLoading } = useNetwork();
  const { errorNotification } = useNotification();
  const [filters, setFilters] = useState<Json>({ ...initialFilter });
  const [bidderList, setBidderList] = useState<Json[]>([]);
  const { formatCurrencyNumber, formatCurrencyWithBillion, amountToBondPoints } = useCurrency();
  const selectedUserChat = useRecoilValue(selectedUserChatState);
  const prevSelectedUserChat = usePrevious(selectedUserChat);

  useEffect(() => {
    callBidderListAPi();
  }, [filters]);

  useEffect(() => {
    if (prevSelectedUserChat?.chatId !== selectedUserChat?.chatId) {
      onClose();
    }
  }, [selectedUserChat]);

  const callBidderListAPi = useCallback(async () => {
    try {
      const { type, status, assetId, price } = details;
      const params = { type, status, assetId, price, ...filters }
      const res = await getOrderBookGroup(APIS.exchangeOrderBookGroup + getJsonToParams(params))
      if (res.message === "ok") {
        setBidderList((prev) => ([...prev, ...res?.data]));
      } else {
        errorNotification(res.message || "Something went wrong!");
        onClose()
      }
    } catch (e: any) {
      errorNotification(e?.message || "Something went wrong!");
      onClose()
    }
  }, [details, errorNotification, filters, getOrderBookGroup, onClose])

  const handleFetchMore = useCallback(async () => {
    setFilters((prev: any) => ({ ...prev, offset: prev?.offset + 1, }));
  }, []);


  return (
    <NewModal
      isOpen={open}
      closeModal={onClose}
      modalName={""}
      title=""
      isStopOutsideClick={true}
      closeOnEscBtn={false}
      className="new-react-modal chat-bidder-list-model">
      <div className="chat-bidder-header-container">
        <p className="chat-bidder-header">{details?.type === "BUY" ? "Bidder" : "Askers"} List</p>
        <p className="chat-bidder-desc">To chat with any {details?.type === "BUY" ? "bidder" : "asker"}, click on
                                        chat.</p>
      </div>
      <div className={`chat-bid-points ${details?.type !== "BUY" ? "in-red" : ""}`}>
        {details?.type === "BUY" ? "Bid Price " : "Ask"} {details?.assetType === assestType?.SBA7 && "in Bond Points ($)"}
        <span>
          {details?.assetType === assestType?.SBA7 &&
            Number(amountToBondPoints(details?.currentLoanBalance, details?.price)).toFixed(6)}
          {details?.assetType === assestType?.SBA7
            ? ` ( ${formatCurrencyWithBillion(details?.price, 2)})`
            : `$${formatCurrencyNumber(Number(details?.price ?? defaultValue), 2)}`}
        </span>
      </div>
      <div className="chat-bidder-list" id="chat-bidderList-scrollableDiv">
        <InfiniteScroll
          dataLength={bidderList?.length || 0}
          next={handleFetchMore}
          hasMore={
            bidderList?.length >=
            filters?.offset * filters.limit + filters.limit
          }
          loader={""}
          scrollableTarget="chat-bidderList-scrollableDiv"
        >
          {bidderList?.map((bidder, index) => (
            <ChatBidderItem
              key={index}
              assetId={details?.assetId}
              details={bidder}
            />
          ))}
          {orderGroupLoading && <Loader dimension={17} />}
        </InfiniteScroll>
      </div>
    </NewModal>

  );
};


