import React from "react";
import { Json } from "../../types";

import "./radial-separators.scss";

function Separator(props: Json) {
  return (
    <div className={`radial-sticks-box ${props.className || ""}`}
         style={{ transform: `rotate(${props.turns}turn)` }}>
      <div className="radial-stick" />
    </div>
  );
}


interface IRadialSeparators {
  count: number;
  style?: Json;
  className?: string;
}

export function RadialSeparators(props: IRadialSeparators) {
  const turns = 1 / props.count;
  return <>
    {Array(props.count).fill("").map((_, index) =>
      <Separator key={`redial${index}`} turns={index * turns} {...props} />)}
  </>;
}

export default RadialSeparators;
