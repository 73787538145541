import { atom } from "recoil";
import { ITransactionData } from "./type";

export const transactionDataState = atom<ITransactionData[]>({
  key: "transaction-data-state",
  default: [],
});

export const ReportChartsState = atom<any>({
  key: "report-charts-state",
  default: [],
});
