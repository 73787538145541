export const CURRENCIES = [
  { label: "USD", value: "USD" },
  { label: "ARS", value: "ARS" },
  { label: "AUD", value: "AUD" },
  { label: "BHD", value: "BHD" },
  { label: "BRL", value: "BRL" },
  { label: "CAD", value: "CAD" },
  { label: "CNY", value: "CNY" },
  { label: "COP", value: "COP" },
  { label: "CRC", value: "CRC" },
  { label: "HRK", value: "HRK" },
  { label: "CZK", value: "CZK" },
  { label: "DKK", value: "DKK" },
  { label: "HKD", value: "HKD" },
  { label: "HUF", value: "HUF" },
  { label: "ISK", value: "ISK" },
  { label: "INR", value: "INR" },
  { label: "IDR", value: "IDR" },
  { label: "ILS", value: "ILS" },
  { label: "JPY", value: "JPY" },
  { label: "MYR", value: "MYR" },
  { label: "MXN", value: "MXN" },
  { label: "ANG", value: "ANG" },
  { label: "NZD", value: "NZD" },
  { label: "NOK", value: "NOK" },
  { label: "OMR", value: "OMR" },
  { label: "PHP", value: "PHP" },
  { label: "PLN", value: "PLN" },
  { label: "GBP", value: "GBP" },
  { label: "QAR", value: "QAR" },
  { label: "SAR", value: "SAR" },
  { label: "SGD", value: "SGD" },
  { label: "KRW", value: "KRW" },
  { label: "SEK", value: "SEK" },
  { label: "CHF", value: "CHF" },
  { label: "THB", value: "THB" },
  { label: "UAH", value: "UAH" },
  { label: "AED", value: "AED" },
  { label: "EUR", value: "EUR" },
];
