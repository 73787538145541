import { saveNewCredentials } from "@hooks/web-authentication/utils";
import { MobileVerificationState, userPersonalDetails } from "@states/user";
import {
  base64URLStringToBuffer,
  bufferToBase64URLString,
  toAuthenticatorAttachment,
} from "@utils/common";
import { useBiometricAuthentication } from "@views/SignIn/hooks";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useNotification } from "..";
import { useLocation } from "@hooks/location";
import { OptionsResponseState } from "@views/SignIn/stores";

export const useWehAuthn = (handleAfterSuccess?: any, handleAfterFailure?: any) => {
  const [isbiometricLoading, setIsbiometricLoading] = useState(false);
  const [isWebAuthSuppoerted, setIsWebAuthSupported] = useState(true);
  const [isDeviceRegisterd, setIsDeviceRegistered] = useState<boolean>(true);
  const [biometricWaitingForApproval, setBiometricWaitingForApproval] =
    useState(false);
  const [authenticationLoading, setAuthenticationLoading] = useState(false);
  const { phone, countryCode } = useRecoilValue(userPersonalDetails);
  const optionsResponse = useRecoilValue(OptionsResponseState);

  const {
    authenticateOptions,
    verifyAuthentication,
    registrationOptions,
    registrationApproval,
    verifyRegistration,
  } = useBiometricAuthentication();

  const { successNotification, errorNotification } = useNotification();

  const verificationID = useRecoilValue(MobileVerificationState);
  const { locationInfo } = useLocation();
  const [ipAddress, setIPAddress] = useState("");

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIPAddress(data.ip))
      .catch((error) => console.error(error));
  }, []);

  const registerNewCredential = useCallback(
    async (callback: any, registrationOptions: any) => {
     try {
        const {
          rp,
          user,
          attestation,
          pubKeyCredParams,
          challenge,
          authenticatorSelection,
          timeout,
          extensions,
        } = registrationOptions ?? {};

        const { name, id, displayName } = user;
        const publicKey: PublicKeyCredentialCreationOptions = {
          rp: rp ?? {},
          user: {
            name: name ?? "",
            id: base64URLStringToBuffer(id ?? ""),
            displayName: displayName ?? "",
          },
          attestation: attestation ?? "direct",
          pubKeyCredParams: pubKeyCredParams ?? [],
          challenge: base64URLStringToBuffer(challenge ?? ""),
          authenticatorSelection: authenticatorSelection ?? {},
          timeout,
          extensions,
          excludeCredentials: [],
        };

        const res = (await navigator?.credentials?.create({
          publicKey: publicKey,
        })) as any;

        const payload = {
          rawId: bufferToBase64URLString(res?.rawId),
          id: res.id,
          name: name ?? "",
          userId: id,
          displayName: displayName ?? "",
        };

        const successPayload = {
          authenticatorAttachment: toAuthenticatorAttachment(
            res.authenticatorAttachment
          ),
          id: res.id,
          rawId: bufferToBase64URLString(res?.rawId),
          response: {
            attestationObject: bufferToBase64URLString(
              res.response.attestationObject
            ),
            clientDataJSON: bufferToBase64URLString(
              res.response.clientDataJSON
            ),
            transports: ["internal"],
          },
          type: "public-key",
          clientExtensionResults: res.getClientExtensionResults() ?? {},
        };

        saveNewCredentials(payload);
        await callback(successPayload);
      } catch (error: any) {
        console.error({ error });
        if (
          error.name === "NotAllowedError" ||
          error.name === "OperationError" ||
          error.code === 0
        ) {
           handleAfterFailure();
         }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const authenticateDevice = useCallback(
    async (onSuccess: any, resAuthenticateCredentials: any, onfailure: any) => {
      const { id, authenticateOptions } = resAuthenticateCredentials;
      const { challenge, allowCredentials, rpId, timeout, userVerification } =
        authenticateOptions ?? {};

      const registeredCredentials = () => {
        return allowCredentials?.map((registeredDevice: any) => ({
          id: base64URLStringToBuffer(registeredDevice.id),
          type: registeredDevice.type,
          transports: registeredDevice.transports,
        }));
      };
      const publicKey = {
        challenge: base64URLStringToBuffer(challenge ?? ""),
        allowCredentials: registeredCredentials(),
        rpId: rpId ?? "",
        timeout: timeout ?? 60000,
        userVerification: userVerification ?? "required",
      };

      try {
        // browser receives the publicKey object and passes it to WebAuthn "get" API
        const res = (await navigator?.credentials?.get({
          publicKey: publicKey,
        })) as any; //as PublicKeyCredential;
        
        const payload = {
          id,
          authenticateOptions: {
            id: res.id,
            rawId: bufferToBase64URLString(res.rawId),
            response: {
              authenticatorData: bufferToBase64URLString(
                res.response?.authenticatorData
              ),
              clientDataJSON: bufferToBase64URLString(
                res.response?.clientDataJSON
              ),
              signature: bufferToBase64URLString(res.response?.signature),
              userHandle: bufferToBase64URLString(res.response?.userHandle),
            },
            type: "public-key",
            clientExtensionResults: res.getClientExtensionResults(),
            authenticatorAttachment: toAuthenticatorAttachment(
              res.authenticatorAttachment
            ),
          },
        };
        onSuccess(payload);
      } catch (error: any) {
        // eslint-disable-next-line no-console
        // Todo: error.name is not required in this situation,need to remove in future.
        console.error({ error });
        if (
          error.name === "NotAllowedError" ||
          error.name === "OperationError" ||
          error.code === 0
        ) {
           onfailure();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isbiometricLoading]
  );

  const getWebAuthnSupported = () => {
    return (
      window?.PublicKeyCredential !== undefined &&
      typeof window.PublicKeyCredential === "function"
    );
  };

  const handleAuthenticationSuccess = useCallback(
    async (res: any) => {
      if (res) {
        const payload = {
          type: "verifyAuthentication",
          authenticateOptions: res.authenticateOptions ?? {},
          id: res.id ?? "",
        };
        const resp = await verifyAuthentication(payload);
        const { isAuthenticated, token } = resp ?? {};
        if (token && isAuthenticated) {
          handleAfterSuccess();
        } else {
          setIsDeviceRegistered(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleAfterSuccess]
  );

  useEffect(() => {
    if (!isDeviceRegisterd) setAuthenticationLoading(false);
  }, [isDeviceRegisterd]);

  const onAuthenticationFailure = useCallback(() => {
      setIsDeviceRegistered(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWebAuth = useCallback(async () => {
    if (isbiometricLoading) return;
    if (getWebAuthnSupported()) {
      setIsbiometricLoading(true);

      let payloadForAuthenticateCredentials = {};

      payloadForAuthenticateCredentials = {
        type: "authenticateOpts",
        countryCode,
        phone,
        origin: window.location.hostname ?? "exchange.stage.satschel.com"
      };

      const resAuthenticateCredentials = await authenticateOptions(
        payloadForAuthenticateCredentials
      );

      const { id } = resAuthenticateCredentials ?? {};

      if (id) {
        authenticateDevice(
          handleAuthenticationSuccess,
          resAuthenticateCredentials ?? {},
          onAuthenticationFailure
        );
      } else {
        setIsDeviceRegistered(false);
      }
      setIsbiometricLoading(false);
    } else {
      // open modal that web auth not supported
      setIsWebAuthSupported(false);
    }
  }, [
    authenticateDevice,
    authenticateOptions,
    handleAuthenticationSuccess,
    isbiometricLoading,
    onAuthenticationFailure,
    phone,
    countryCode,
  ]);

  const startRegisterDevice = useCallback(
    async (value: string = "not approved") => {
      setAuthenticationLoading(true);
      if (value === "approved") {
        // if user already approved mobile link
        await registrationOptions({ authenticationType: "login" });
      } else {
        const approvalPayload = {
          type: "registrationApproval",
          phone,
          countryCode,
          deviceInfo: navigator.userAgent ?? {},
        };
        const response = await registrationApproval(approvalPayload);
        if (response) {
          const { message, success, verificationId } = response;
          if (success && verificationId) {
            // move user to the waiting for approval screen
            setBiometricWaitingForApproval(true);
            successNotification(message);
            return;
          }
          errorNotification(message);
          return;
        }
      }
    },
    [
      errorNotification,
      registrationApproval,
      registrationOptions,
      successNotification,
      phone,
      countryCode,
    ]
  );

  const handleRegistrastionSuccess = useCallback(
    async (res: any) => {
      if (res) {
        const payloadSaveCredential = {
          type: "verifyRegistration",
          registrationOptResponse: res,
          id: optionsResponse?.id,
          verificationId: verificationID,
          deviceInfo: {
            location: locationInfo,
            ip: ipAddress,
          },
        };
        const resp = await verifyRegistration(payloadSaveCredential);
        const { isRegistered, token } = resp ?? {};

        if (isRegistered) {
          if (token) {
            handleAfterSuccess();
          }
        } else {
          handleAfterFailure();
          errorNotification("Biometric Authentication Failed");
        }
      }
    },
    [
      optionsResponse?.id,
      verificationID,
      locationInfo,
      ipAddress,
      verifyRegistration,
      handleAfterSuccess,
      errorNotification,
    ]
  );

  return {
    registerNewCredential,
    authenticateDevice,
    getWebAuthnSupported,
    handleWebAuth,
    isbiometricLoading,
    setIsbiometricLoading,
    isWebAuthSuppoerted,
    isDeviceRegisterd,
    setIsDeviceRegistered,
    startRegisterDevice,
    biometricWaitingForApproval,
    setBiometricWaitingForApproval,
    authenticationLoading,
    setAuthenticationLoading,
    handleRegistrastionSuccess,
    setIsWebAuthSupported
  };
};
