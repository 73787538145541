import { FC, useMemo } from "react";
import useDarkMode from "use-dark-mode";
import cn from "classnames";

import "./skeleton-loader.scss";

interface ISkeletonSearch {
  listsToRender: number;
  className?: string;
}

export const SkeletonSearch: FC<ISkeletonSearch> = ({ listsToRender, className = "" }) => {
  const darkMode = useDarkMode(false);
  const skeletonLoader = useMemo(() => {
    return Array(listsToRender).fill(1).map((card, index) => (
      <div
        key={`search__${index}`}
        className="skeleton_WatchList"
        style={{
          borderBottom: darkMode.value
            ? "1px solid #23262F"
            : "1px solid #e6e8ec",
        }}
      >
        <div
          className={cn("skeleton_WatchList_star", {
            "skeleton-loader-dark": darkMode.value,
            "skeleton-loader-light": !darkMode.value,
          })}
        />
        <div className="skeleton_WatchList_asset">
          <div
            className={cn("skeleton_WatchList_symbol", {
              "skeleton-loader-dark": darkMode.value,
              "skeleton-loader-light": !darkMode.value,
            })}
          />
          <div
            className={cn("skeleton_WatchList_detail", {
              "skeleton-loader-dark": darkMode.value,
              "skeleton-loader-light": !darkMode.value,
            })}
          />
        </div>
        <div
          className={cn("skeleton_WatchList_star", {
            "skeleton-loader-dark": darkMode.value,
            "skeleton-loader-light": !darkMode.value,
          })}
        />
      </div>
    ));
  }, [darkMode.value, listsToRender]);
  return <div className={className}>{skeletonLoader}</div>;
};
