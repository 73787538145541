import { useLocation } from "react-router-dom";
import { SuccessIcon } from "./SignIn/components/status";
import styles from "./mobile-email-verify.module.sass";
import { Image } from "@storybook";
import { useNetwork } from "@hooks/network";
import { useCookie } from "@hooks/cookie";
import { LoginUserState } from "@states/user";
import { useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { APIS } from "constant";
import Mobileview from "./mobile-view";
import { Loader } from "@components/Loader2";


const MobileCoownerJoining = () => {
    const location = useLocation();
    const { put: loginInvite, loading } = useNetwork();
    const { set: setCookieToken } = useCookie();
    const { deleteCookie } = useCookie();
    const [joiningCancelStatus, setJoiningCancelStatus] = useState(false)

    const setUserInfo = useSetRecoilState(LoginUserState);

    useEffect(() => {
        if (location?.pathname.includes("/co-owners-email-accept/")) {
            const urlId = location.pathname.split("/");
            const inviteId = urlId[urlId.length - 1];

            loginInvite(`${APIS.COOWNER}/${inviteId}`).then((res: any) => {
                if (res?.token) {
                    const registrations = JSON.parse(
                        localStorage.exchageAppRegistration || "[]"
                    );
                    localStorage.clear();
                    deleteCookie("userDetails");
                    localStorage.setItem(
                        "exchageAppRegistration",
                        JSON.stringify(registrations)
                    );
                    const loginDetails = {
                        token: res?.token,
                        phone: "",
                        isLoggedIn: true,
                    };
                    setCookieToken("userDetails", loginDetails);
                    setUserInfo(loginDetails);
                } else {
                    setJoiningCancelStatus(true)
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            {loading ? <Loader /> : joiningCancelStatus ? <Mobileview /> : <div className={styles.page}>
                <div className={styles.main}>
                    <SuccessIcon />
                    <div className={styles.heading}>Successfully joined</div>
                    <div className={styles.text}>
                        Please restart your "Liquidity iOS APP" or refresh iOS profile view.
                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={styles.footer_item}>
                        <div className={styles.text}>
                            Liquidity mobile app is now available on iOS platform
                        </div>
                        <a href="https://apps.apple.com/in/app/testflight/id899247664">
                            <Image fileName={"images/appstore.svg"} />
                        </a>
                        <Image fileName={"images/Originalwordmark.svg"} />
                    </div>
                </div>
            </div>}

        </>

    );
};
export default MobileCoownerJoining;