import { Image } from "@storybook";
import "./mpc-setup.scss";
import { Button } from "@components/button";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@routes/constants";
import { LoginUserState } from "@states/user";
import { useRecoilValue } from "recoil";
import { useCookie } from "@hooks/cookie";
import { loginToasterMessages } from "constant";
import { useNotification } from "@hooks/notification";
import { useWebSocket } from "@hooks/web-socket";

export const MpcQr = ({ showButton = true, showLogo = true }) => {
    const navigate = useNavigate();
    const { MPC_SETUP_STATUS, EXCHANGE, LOGIN } = ROUTES;
    const { set: setCookieToken } = useCookie();
    const tempLoginUser = useRecoilValue(LoginUserState);
    const { LOGIN_SUCCESS } = loginToasterMessages;
    const { successNotification } = useNotification();

    const { socket } = useWebSocket();
    useEffect(() => {
        socket?.on(`mpc`, (data) => {
            if (data.type === "MESSAGE") {
                navigate(MPC_SETUP_STATUS, {state: {userId: data?.userId, isSuccess: data?.isSuccess} })
            }
        });
        return () => {
          socket?.off("mpc");
        };
    }, [socket]);

    useEffect(() => {
        if (!tempLoginUser?.token) {
            navigate(LOGIN)
        }
    }, [])

    const handleLogin = useCallback(() => {
        setCookieToken("userDetails", tempLoginUser);
        navigate(EXCHANGE);
        successNotification(LOGIN_SUCCESS);
    }, [tempLoginUser]);

    return (
        <div className="mpc_setup_container">
            {showLogo && <div className="header">
                <Image
                    className="brand_logo"
                    fileName={`images/Liquidity_Blue_Logo.png`}
                />
            </div>
}
            <div className="body">
                <div>
                    <h2 className="heading">Initiate the wallet setup</h2>
                    <p className="para">Scan the QR code to install the Liquidity app and secure your assets on the go</p>
                </div>
                <div className="qr-wrapper">
                    <Image className="qr_code"
                        fileName={`images/app-qr.svg`} />
                </div>
                {showButton && <div>
                    <Button type="btn-outline-blue" label="Explore Liquidity" handleClick={handleLogin} />
                </div>}
            </div>
        </div>
    );
};
export default MpcQr;