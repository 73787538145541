import type { RangeKeyDict } from "react-date-range";

import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { isSameDay } from "date-fns";
import classNames from "classnames";
import { useRecoilState } from "recoil";

import { useLocalStorage } from "hooks";
import { SelectedDateRangeState } from "states";
import { DateRangePicker, defaultStaticRanges } from "components";
import { getFormattedDate } from "utils";

import "./date-picker.scss";

interface IDatePicker {
  onSubmit: Function;
}

interface IDateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

export const DatePicker: FC<IDatePicker> = ({ onSubmit }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [range, setRange] = useState<IDateRange[] | any>();

  const [selectedDateRange, setSelectedDateRange] = useRecoilState(SelectedDateRangeState);

  const { set: setLocalStorage } = useLocalStorage();

  useEffect(() => {
    setRange(selectedDateRange);
  }, [selectedDateRange]);

  useEffect(() => {
    if (range) {
      const isSame = isSameDay(range[0].startDate, range[0].endDate);
      if (!isSame || count > 1) {
        setLocalStorage("date-range", range[0]);
        setCount(0);
      }
    }
  }, [count, range, setLocalStorage]);

  const handleOpenDatePicker = useCallback(() => {
    setIsVisible(true);

    const today = new Date();
    const isTodayRange =
      range &&
      range.length === 1 &&
      isSameDay(range[0].startDate, today) &&
      isSameDay(range[0].endDate, today);

    // If range matches today's date, reset the range to initial value
    if (isTodayRange) {
      setRange(selectedDateRange);
    } else if (range && count === 0) {
      setRange(selectedDateRange);
    }
  }, [count, range, selectedDateRange]);

  const handleChangeDateRange = useCallback(({ selection }: RangeKeyDict) => {
    if (selection) {
      setRange([selection as any]);
      setCount((prev) => prev + 1);
    }
  }, []);

  const handleApplyDateRange = useCallback(() => {
    onSubmit();
    setIsVisible(false);
    setSelectedDateRange(range);
    setLocalStorage("date-range", range[0]);
  }, [setSelectedDateRange, range, setLocalStorage, onSubmit]);

  const renderedDate = useMemo(() => {
    const { endDate, startDate }: any = selectedDateRange[0];

    const selectedRange = defaultStaticRanges.find(
      (date) => date.range().startDate === startDate && date.range().endDate === endDate,
    );

    if (selectedRange?.label) {
      return selectedRange.label;
    }
    const isSame = isSameDay(startDate, endDate);
    const fromDate = getFormattedDate(startDate, "MMM dd");
    const toDate = getFormattedDate(endDate, "MMM dd");
    if (isSame) {
      return fromDate;
    }

    return (
      <>
        <div className="selected-range--date">{fromDate}</div>
        <div className="selected-range--to">to</div>
        <div className="selected-range--date">{toDate}</div>
      </>
    );
  }, [selectedDateRange]);

  const selectedClass = classNames("calender-btn", {
    "calender-btn--active": isVisible,
  });

  const arrowIconClass = classNames("ri-arrow-down-s-line", {
    "arrow-icon--active": isVisible,
  });

  return (
    <div>
      <div onClick={handleOpenDatePicker} className={selectedClass}>
        <i className="ri-calendar-event-fill Date-picker--calendar-icon" />
        <span className="selected-range">{renderedDate}</span>
        <i className={arrowIconClass} />
      </div>
      <div className="picker-container">
        {isVisible && (
          <DateRangePicker
            range={range}
            rangeColor={["#4574f5"]}
            handleChangeRange={handleChangeDateRange}
            handleSubmit={handleApplyDateRange}
            setIsVisible={setIsVisible}
          />
        )}
      </div>
    </div>
  );
};
