import React, { useCallback, useMemo } from "react";

import styles from "../VerificationIncomplete/verificationIncomplete.module.sass";
import { userDetailsState } from "@states/user";
import { useRecoilValue } from "recoil";
import useDarkMode from "use-dark-mode";
import { ActiveCurrencyState } from "@states/exchange";

interface IUpgradeAccreditation {
  isProfile?: boolean
}

export const UpgradeAccreditation = ({ isProfile }: IUpgradeAccreditation) => {
  const darkmode = useDarkMode(false);
  const userDetails = useRecoilValue(userDetailsState);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);

  const { accreditationStatus, accreditationType, isAccreditationSuccess = true } = userDetails?.data?.onboardingData || {};
  const inProgressing = accreditationStatus === "processing";

  const handleStartUpgrade = useCallback(() => {

  }, []);

  const renderProfileUpgrade = useMemo(() => {
    return (
      <div className={styles.renderKycStatus}>
        <div className={styles.renderKycStatus__usersImg}>
          {!inProgressing ?
            <img src={darkmode.value ? "/media/icon/file-check-dark.svg" : "/media/icon/file-check-light.svg"}
                 alt="" />
            :
            <img src={darkmode.value ? "/media/icon/file-search-dark.svg" : "/media/icon/file-search-light.svg"}
                 alt="" />
          }
        </div>
        <div className={styles.renderKycStatus__Texts}>
          <div className={styles.renderKycStatus__Texts__heading}>
            {!inProgressing ?
              isProfile ? "Upgrade to Accredited 506(c)" :
                <>Accreditation {activeCurrency?.accreditationType} is required buy <br /> this private stock</>
              : "Accreditation Under Review"}
          </div>
          <div className={styles.renderKycStatus__Texts__subHeading}>
            {!inProgressing ?
              isProfile ?
                <>Switching from Non-Accredited or 506(b) to 506(c) requires <br /> additional verification steps.</>
                :
                <>You are {accreditationType} investor. Complete
                  your {activeCurrency?.accreditationType} to buy <br /> this private stock.</>
              :
              <>We are in the process of reviewing your 506(c) accreditation, which <br /> may take 1-2 business days.
                We will notify you when it's approved. <br />Thank you for your patience.</>
            }
          </div>
        </div>
        {!inProgressing && <button
          className={`accreditation-btn ${styles.renderKycStatus__btn}`}
          onClick={handleStartUpgrade}>
          {isProfile ? "Get Accreditation 506(c)"
            : `Complete Accreditation ${activeCurrency?.accreditationType || ""}`}
        </button>}
      </div>
    );
  }, [activeCurrency, userDetails]);

  const renderSuccess = useMemo(() => {
    return (
      <div className={styles.renderKycStatus}>
        <div className={styles.renderKycStatus__usersImg}>
          <i className={`ri-checkbox-circle-fill ${styles.renderKycStatus__icon}`} />
        </div>
        <div className={styles.renderKycStatus__Texts}>
          <div className={styles.renderKycStatus__Texts__heading}>
            {isAccreditationSuccess ? "Accreditation successfully completed" : "Accreditation 506(c) request submitted"}
          </div>
          <div className={styles.renderKycStatus__Texts__subHeading}>
            {isAccreditationSuccess ?
              <>Your request for 506(b) accreditation has been successfully <br /> completed.</> :
              <>
                Your request for 506(c) accreditation has been successfully <br /> submitted. The process takes 1-2
                business days, and we will <br /> notify you once your accreditation is approved.
              </>
            }
          </div>
        </div>
      </div>
    );
  }, []);
  return <>{renderProfileUpgrade}</>;
  // return <>{renderSuccess}</>;
};
