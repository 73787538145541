import React, { useCallback, useEffect } from "react";

import "./latest-bids.scss";
import { APIS } from "../../../../constant";
import { useCurrency, useNetwork, useNotification, usePrevious } from "../../../../hooks";
import { getJsonToParams } from "../../../../utils";
import { Json } from "../../../../types";
import { CountdownTimer, SkeletonCard } from "../../../../components";
import { useRecoilValue } from "recoil";
import { AuctionBidPostState } from "../../../../states";
import { Image } from "../../../../@storybook";
import useAuctionHook from "@views/AuctionConsumer/hook";

type ILatestBids = {
  auctionId: string
  auctionDetails: Json
}
export const LatestBids = ({ auctionId, auctionDetails }: ILatestBids) => {
  const { data: placeBidData = {}, loading: placeBidLoading } = useRecoilValue(AuctionBidPostState);
  const prevPlaceBidLoading = usePrevious(placeBidLoading);
  const { get: fetchLatestBids, data: latestBids, loading } = useNetwork();
  const prevLoading = usePrevious(loading);
  const { errorNotification } = useNotification();
  const { formatCurrencyWithBillion } = useCurrency();
  const { sbaAndNormalPrice, isSbaLoan } = useAuctionHook(auctionDetails);

  useEffect(() => {
    if (auctionId) {
      fetchLatestBids(APIS.LatestBid + getJsonToParams({ auctionId }));
    }
  }, []);

  useEffect(() => {
    if (placeBidData?.message === "ok" && !placeBidLoading && prevPlaceBidLoading) {
      fetchLatestBids(APIS.LatestBid + getJsonToParams({ auctionId }));
    }
  }, [placeBidData]);

  useEffect(() => {
    if (latestBids?.message !== "ok" && !loading && prevLoading) {
      errorNotification(latestBids?.message);
    }
  }, [latestBids]);

  const renderDate = useCallback(({ days, hours, minutes }: Json) => {
    if (days)
      return days + (days > 1 ? " days ago" : " day ago");
    else if (hours)
      return hours + (hours > 1 ? " hours ago" : " hour ago");
    else if (minutes && minutes > 5)
      return minutes + " mins ago";
    else return "Just now"
  }, []);

  return (
    <div className="latest-bids-container">
      <h2 className="latest-bids-heading">Latest bids</h2>
      <div className="bids-list">
        {loading && <SkeletonCard listsToRender={!!latestBids?.data?.length ? 1 : 4} />}
        {latestBids?.data?.map((bid: Json) => (
          <div className="latest-bid-card align-top" key={bid?.createdAt}>
            <img className="bid-img" src={bid?.profilePictureUrl || "/media/images/user.svg"} alt="User" />
            <div className="bid-detail">
              <h2 className="name">{bid?.customer}</h2>
              <span className="time">{bid?.userType}</span>
            </div>
            <div className="bid-detail right">
              <div className="bid-price">
                {sbaAndNormalPrice(auctionDetails, {
                  price: bid?.maxBidPrice
                })}
              </div>
              <span className="time">
                {isSbaLoan && <>
                  {formatCurrencyWithBillion(bid?.maxBidPrice, 2)}
                  <br />
                </>}
                <CountdownTimer dateTime={bid?.createdAt} renderer={renderDate} counterType="forward" />
              </span>
            </div>
          </div>
        ))}

        {!latestBids?.data?.length && !loading && (
          <div className="no-data-found">
            <Image fileName="icon/emptyCards.svg" />
            No bids yet
          </div>
        )}
      </div>
    </div>
  );
};
