import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { ICountries, ICountryCode } from ".";
import countries from "json/country-codes.json";
import "./country-code.scss";
import useDarkMode from "use-dark-mode";
import { useRecoilState, useSetRecoilState } from "recoil";
import { darkThemeState } from "@states/user";
import { selectedFlagState } from "./state";

export const CountryCode: FC<ICountryCode> = ({ handleChangeCountry, countryCode = "+1", showPhoneError }) => {
  const [country, setCountry] = useState<ICountries>();
  const [isOptionVisible, setIsOptionVisible] = useState<Boolean>(false);
  const [inputValue, setInputValue] = useState<any>("");
  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [noOptionState, setNoOptionState] = useState(false);
  const [selectedFlag, setSelectedFlag] = useRecoilState(selectedFlagState);
  const [isBorderVisible, setIsBorderVisible] = useState(false);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [highlightOption, setHighlightOption] = useState(-1);
  const iteratorRef = useRef<any>(null);
  const optionsContainerRef = useRef<HTMLDivElement | null>(null);
  const darkMode = useDarkMode();

  const handleOptionVisibility = useCallback(() => {
    setInputValue("");
    setIsBorderVisible(true);
    setIsInputFocused(true)
    setIsOptionVisible(!isOptionVisible);
  }, [isOptionVisible])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value.length <= 56) {
      setIsInputFocused(false);
      setIsBorderVisible(true);
      setInputValue(value);
    }
  };

  useEffect(() => {
    const countryObj = countries.find((item) => item.label === countryCode);
    setCountry(countryObj);
  }, [countryCode]);

  useEffect(() => {
    // Initialize the generator when the component mounts
    iteratorRef.current = incrementDecrementGenerator(filteredCountries);
  }, [filteredCountries]);

  useEffect(() => {
    setNoOptionState(false);
    const filteredList = countries.filter((obj) =>
      Object.values(obj).some((prop) =>
        prop.toString().toLowerCase().includes(inputValue.toLowerCase()),
      ),
    );
    setFilteredCountries(filteredList);
    if (!isOptionVisible && inputValue?.length > 0 && filteredCountries?.length > 0)
      setIsOptionVisible(true);
    if (isOptionVisible && inputValue?.length > 0 && filteredCountries?.length === 0) {
      setNoOptionState(true);
    }
  }, [filteredCountries?.length, inputValue, isOptionVisible]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOptionVisible && componentRef.current && !componentRef.current.contains(event.target as Node)) {
        const isClickInsideInput = inputRef.current?.contains(event.target as Node);

        if (!isClickInsideInput) {
          setIsOptionVisible(false);
          setIsBorderVisible(false);
          setInputValue("");
          setIsInputFocused(false);
        }
      }
    };

    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        // Handle Esc key press
        setIsOptionVisible(false);
        setIsBorderVisible(false);
        setInputValue("");
        setIsInputFocused(false);

        if (inputRef.current) {
          inputRef.current.blur();
        }
      }
    };


    // Add event listener when component is mounted
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("keydown", handleEscKeyPress);

    // Clean up the event listener when component is unmounted
    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleEscKeyPress);
    };

    // Specify any dependencies if needed (e.g., setIsOptionVisible, setIsBorderVisible, etc.)
  }, [isOptionVisible, setIsOptionVisible, setIsBorderVisible, setInputValue, setIsInputFocused]);

  function* incrementDecrementGenerator(array: any[]): Generator<number, never, 'increment' | 'decrement'> {
    let value = 0;

    while (true) {
      const action: 'increment' | 'decrement' = yield value;

      if (action === 'increment' && value < array.length - 1) {
        value++;
      } else if (action === 'decrement' && value > 0) {
        value--;
      }
    }
  }

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      setIsOptionVisible(true);
      const highlightOptionIndex =
        e.key === "ArrowDown"
          ? iteratorRef.current.next("increment").value
          : iteratorRef.current.next("decrement").value;
      setHighlightOption(highlightOptionIndex);

      // Scroll the container to make the highlighted option visible
      if (optionsContainerRef.current) {
        const optionElement = optionsContainerRef.current.children[highlightOptionIndex];
        if (optionElement) {
          const containerRect = optionsContainerRef.current.getBoundingClientRect();
          const optionRect = optionElement.getBoundingClientRect();

          if (optionRect.bottom > containerRect.bottom) {
            optionsContainerRef.current.scrollTop +=
              optionRect.bottom - containerRect.bottom;
          } else if (optionRect.top < containerRect.top) {
            optionsContainerRef.current.scrollTop -=
              containerRect.top - optionRect.top;
          }
        }
      }
    } else if (e.key === "Tab") {
      setIsOptionVisible(false);
      setIsBorderVisible(false);
      setInputValue("");
      setIsInputFocused(false);
    } else if (e.key === "Escape") {
      setIsOptionVisible(false);
      setIsBorderVisible(false);
      setInputValue("");
      setIsInputFocused(false);
    }
  }, []);



  const renderOptions = useMemo(() => {
    return (
      <div className="option-container" ref={optionsContainerRef}>
        {filteredCountries.map((country: any, index) => {
          return (
            <div
              key={`${country?.label} + ${country?.value}`}
              className={`option ${index === highlightOption ? "selected" : ""}`}
              onClick={() => {
                setSelectedFlag(country?.image);
                handleChangeCountry(country);
                setIsOptionVisible(false);
                setInputValue("");
                setIsBorderVisible(false);
              }}>
              <div className="option-content">
                <img className="option__flag" src={country.image} alt="country-code" />
                <span className="option__label">{country.label}</span>
              </div>
            </div>
          );
        })}
        {noOptionState && (
          <div className="option">
            <div className="option-content">
              <span className="option__label no-option">No Option</span>
            </div>
          </div>
        )}
      </div>
    );
  }, [filteredCountries, handleChangeCountry, highlightOption, noOptionState]);

  const handleInputFocus = useCallback(() => {
    setIsBorderVisible(true);
    setIsInputFocused(true);
    setIsOptionVisible(true);
  }, []);

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && filteredCountries.length > 0) {
        setSelectedFlag(filteredCountries[highlightOption]?.image);
        handleChangeCountry(filteredCountries[highlightOption]);
        setInputValue("");
        setIsBorderVisible(false);
        setIsOptionVisible(false);
        setIsInputFocused(false);

        // Blur the current input
        if (inputRef.current) {
          inputRef.current.blur();
        }

      }
    },
    [filteredCountries, handleChangeCountry, highlightOption, inputRef]
  );

  return (
    <div className={`country-code-container ${showPhoneError ? "input-error-background" : ""}`}>
      <input
        ref={inputRef}
        autoComplete="country-input"
        type="text"
        value={inputValue}
        onChange={(e) => handleInputChange(e)}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        className="country-input"
        onFocus={handleInputFocus}
      />
      <div className={`select-box ${isBorderVisible ? "active" : ""}`}>
        <div className="option-selected">
          {!inputValue.length && (
            <div className="option-content">
              <img
                className="option__flag"
                src={selectedFlag ? selectedFlag : country?.image}
                alt="country-code"
              />
              <span className="option__label">{country?.label}</span>
            </div>
          )}
        </div>
        <div className={`arrow ${isOptionVisible ? "rotate180" : ""}`} ref={componentRef} onClick={handleOptionVisibility}>
          <i className={`ri-arrow-down-s-line i`}></i>
        </div>
      </div>
      {isOptionVisible && renderOptions}
    </div>
  );
};
