import { useSetRecoilState } from "recoil";
import WalletLight from "../../../../assets/images/WalletLight.svg";
import WalletDark from "../../../../assets/images/WalletDark.svg";
import "./insufficientFunds.scss";
import { useCallback } from "react";
import { DepositModalOpenState, WithdrawModalOpenState } from "@states/wallet";
import useDarkMode from "use-dark-mode";
import { useNavigate } from "react-router-dom";

const InsufficientFunds = ({subHeading,fromAction,setVisibleModify}:any) => {
  const setIsDepositModalOpen = useSetRecoilState(DepositModalOpenState);
  const setIsWithdrawModalOpen = useSetRecoilState(WithdrawModalOpenState);
  const darkMode = useDarkMode(false);
  const navigate=useNavigate()

  const handleClickDeposit = useCallback(() => {
    if(fromAction){
      setVisibleModify(false)
      navigate("/wallet")
    }
    setIsWithdrawModalOpen(false);
    setIsDepositModalOpen(true);
  }, [fromAction, navigate, setIsDepositModalOpen, setIsWithdrawModalOpen, setVisibleModify]);

  return (
    <div className="InsufficientFunds">
      <div className="InsufficientFunds__img">
        <img src={darkMode.value ? WalletDark : WalletLight} alt="" />
      </div>
      <div className="InsufficientFunds__texts">
        <div className="InsufficientFunds__texts__heading">
          Insufficient fund available
        </div>
        <div className="InsufficientFunds__texts__subHeading">
         {subHeading || ""}
        </div>
      </div>
      <button className="InsufficientFunds__btn" onClick={handleClickDeposit}>
        Deposit
      </button>
    </div>
  );
};

export default InsufficientFunds;
