import { useCallback, useState } from "react";
import { Button, CountryCode, ICountries, Loader } from "components";

import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import style from "./coming-soon.module.sass";
import "./coming-soon.scss";
import { useNetwork } from "@hooks/network";
import { APIS } from "constant";
import { Image } from "@storybook";
import classNames from "classnames";
import { WaitlistState } from "./store/state";
import NewModal from "@components/new-modal/new-modal";
import useDarkMode from "use-dark-mode";
import { useNotification } from "@hooks/notification";
import { ROUTES } from "@routes/constants";
import { useNavigate } from "react-router-dom";
import { selectedFlagState } from "@components/country-code/state";


const ComingSoon = () => {
    const darkMode = useDarkMode()
    const navigate = useNavigate();

    const [waitlistModal, setWaitlistModal] = useState(false)
    const [isChecked, setIsChecked] = useState(false);

    const [waitlistData, setWaitlistData] = useRecoilState(WaitlistState)
    const  setSelectedFlag = useSetRecoilState(selectedFlagState);
    const resetWaitlistData = useResetRecoilState(WaitlistState)
    const { errorNotification } = useNotification()

    const { post: sendWaitlistData, loading } = useNetwork();
    const { TERMS_SERVICE, PRIVACY_POLICY, BIOMETRIC_POLICY } = ROUTES;

    const handleTermsService = useCallback(()=>{
        navigate(TERMS_SERVICE)
      }, [TERMS_SERVICE, navigate])
    
      const handlePrivacyPolicy = useCallback(()=>{
        navigate(PRIVACY_POLICY)
      }, [PRIVACY_POLICY, navigate])
    
      const handleBiometricPolicy = useCallback(()=>{
        navigate(BIOMETRIC_POLICY)
      }, [BIOMETRIC_POLICY, navigate])

    const handleOnClick = useCallback(() => {
        if (!waitlistData || waitlistData?.phone === "" || waitlistData?.email === "") {
            errorNotification("Please enter all details")
        } else {
            const payload = {
                phone: waitlistData?.phone,
                countryCode: waitlistData?.countryCode,
                email: waitlistData?.email,
            }
            sendWaitlistData(APIS.WAITLIST, payload).then((res: any) => {

                if (res?.success) {
                    setWaitlistModal(true)
                }
            });
        }

    }, [errorNotification, sendWaitlistData, waitlistData]);

    const handleFormSubmit = (e: any) => {
        e.preventDefault();
    };

    const onHandleChange = useCallback(
        (name: string, e: React.ChangeEvent<HTMLInputElement> | any) => {
            const { value } = e.target;
            let phoneRegex = value;
            if(name === "phone"){
                const digitsOnly = value.replace(/\D/g, ""); // Remove non-digit characters
                phoneRegex = digitsOnly.substring(0,10);
            }
            setWaitlistData((prevState) =>
                ({ ...prevState, [name]: phoneRegex })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleCountryCode = useCallback((e: ICountries) => {
        const value = e.label;
        setWaitlistData((prevState) => {
            const newState = { ...prevState, countryCode: value };
            return newState;
        });
    }, [setWaitlistData])


    // const handleGoBack = useCallback(() => {
    //     navigate(-1);
    // }, [navigate]);

    const handleCloseModal = useCallback(() => {
        setWaitlistModal(false)
        resetWaitlistData()
        setIsChecked(!isChecked)
        setSelectedFlag("")
    }, [isChecked, resetWaitlistData])

    return (
        <div className={style.comingsoon_container}>
            <div className={style.header}>
                <Image
                    className={style.brand_logo}
                    fileName={`images/Liquidity_Blue_Logo.png`}
                />
                <span className={style.last_element}>Accessibility</span>
            </div>
            <div className={style.wrapper}>
                <div className={style.inner_wrapper}>
                    <div className={style.inner}>
                        <div className={style.title_header}>
                            <div className={style.title}>Launching Soon!</div>
                            <div className={style.title_waitlist}>Join The Waitlist</div>
                            <div className={style.sub_title}>
                                Be among the first to experience Liquidity!
                            </div>

                        </div>
                        <form
                            className={style.form_wrapper}
                            autoComplete="on"
                            onSubmit={handleFormSubmit}
                        >
                            <div>
                                <label className={style.comingSoon_label}>Email Address</label>

                                <input
                                    type="email"

                                    className={classNames(style.input_field, style.emailInput, style.comingsoon_input)}
                                    onChange={(e: any) => onHandleChange('email', e)}
                                    // onKeyDown={onHandleChange}
                                    value={waitlistData.email}
                                    placeholder="Email"
                                    name="email"
                                />
                            </div>
                            <label className={style.comingSoon_label}>Phone Number</label>
                            <div className={style.input_wrapper}>
                                <CountryCode
                                    handleChangeCountry={handleCountryCode}
                                    countryCode={waitlistData.countryCode as string}
                                />
                                <input
                                    type="tel"
                                    minLength={6}
                                    maxLength={10}
                                    className={classNames(style.input_field, style.comingsoon_input)}
                                    onChange={(e: any) => onHandleChange('phone', e)}
                                    // onKeyDown={onHandleChange}
                                    value={waitlistData.phone}
                                    placeholder="XXXXXXXXXX"
                                    name="phone"
                                    autoComplete="tel"
                                />

                            </div>
                            <div className={style.sub_title_small}>
                                <label className="main">
                                    <input type="checkbox"   checked={isChecked} />
                                    <span className="checkbox-container"  onClick={()=> setIsChecked((prev => !prev))}></span>
                                </label>
                                <span>By joining our waitlist, you agree to our Terms of service, Privacy policy and Biometric policy</span>
                            </div>
                            <button
                                onClick={handleOnClick}
                                disabled={!isChecked || !waitlistData.email || !waitlistData.phone}
                                className={
                                    !isChecked || !waitlistData.email || !waitlistData.phone ? style.login_btn_disabled : style.login_btn
                                }
                            >
                                {loading ? <span><Loader dimension={20} /></span> : "Join the waitlist"}
                            </button>
                        </form>
                        <div className={style.footer}>
                            <div className={style.terms_and_privacy_wrapper}>
                                <span className={style.first_element} onClick={handleTermsService}>Terms of Service</span>
                                <span className={style.middle_element} onClick={handlePrivacyPolicy}>Privacy Policy</span>
                                <span className={style.middle_element} onClick={handleBiometricPolicy}>Biometric Policy</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
            <NewModal
                isOpen={waitlistModal}
                closeModal={handleCloseModal}
                modalName={"ComingSoon"}
                showCloseBtn={false}
                isStopOutsideClick={false}
                closeOnEscBtn={false}
                className="Modal__wrapper exchange-modal-wrapper model-title-margin"
            >
                <div className={style.comingSoon__modal}>
                    {darkMode ?
                        <Image fileName={"images/kyc-success.svg"} height={"220px"} /> :
                        <Image fileName={"images/kyc-success-light.svg"} height={"220px"} />
                    }
                    <h2 className={style.comingSoon__modal__heading}>Thank you for joining the waitlist!</h2>
                    <p className={style.comingSoon__modal__para}><br />You're now part of an exclusive community eager to explore Liquidity. We'll keep you updated with exciting news and launch details. </p>
                    <button onClick={handleCloseModal} className={style.comingSoon__modal__button}>Close</button>

                </div>
            </NewModal>
            {/* <p className={style.disclaimer}>
                <span className={style.main_disclaimer}>DISCLAIMER: Securities on this platform are offered by ARQ Securities (“ARQ”), a FINRA
member broker-dealer and operator the ARQ Securities/ATS. ARQ is a wholly owned subsidiary
of Satschel Inc. liquidity.io is a wholly owned subsidiary of Satschel Inc. and provides services
to this Platform. By using the Site, you are subject to ARQ Securities Terms of Service and to
our Privacy Policy.
                    <br></br>
                    <br></br>
                    Please read these carefully before using the Platform. Although our Platform may describe
opportunities to invest in various securities, we do not make recommendations regarding the
appropriateness of any particular investment opportunity for any particular investor. We are not
investment advisers and we do not provide investment advice or monitor, endorse, analyze, or
recommend any securities. You should be aware that the value of investments in private
securities may be impacted by limited disclosure of financial and other company information,
and of transaction reporting; they may be less-liquid than comparable public-market securities
and that private securities holdings are often subject to additional trading restrictions which may
impact your ability to sell. You should consult your business adviser, accounting adviser, and/or
attorney with respect to price, value, risk or other aspects of your security or investment, prior to
entering into a transaction. Digital assets using blockchain, are speculative, involve a high degree
of risk, are generally illiquid, may have no value, have limited regulatory certainty, are subject to
potential market manipulation risks and may expose investors to loss of principal. Investments in
private placements, start-up investments in particular, are also speculative and involve a high
degree of risk. Investors must be able to afford the loss of their entire investment. Blockchain is a
new technology and unproven in financial markets. Securities ownership is officially maintained
at a securities custodian and the transaction agent's records supersede the blockchain. There is no
guarantee that tokenization will enable any secondary market liquidity in the future and your
investment may remain illiquid.
                </span>
                <br></br>
                <br></br>
                <span>
                Arq Securities is a member of{" "}
                    <a href="#">FINRA</a>{" "}
                    and <a href="#">SIPC:</a>
                </span>
                <br></br>
                ARQ Securities Contact:
                <br></br>
                David Sukoff
                <br></br>
                dsukoff@arqadvisors.com
                <br></br>
                Tel: 908-608-8831
                <br></br>
                Address: 31 Ocean Terrace, Long Branch, NJ 07740
            </p> */}
        </div>
    )
}

export default ComingSoon