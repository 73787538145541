import { FC } from "react";
import type { INotSupportedModal } from "./type";
import { Button } from "../../components";
import notSupported from "../../assets/icon/notSupported.svg";
import "./not-supported.scss";

export const Nosupported: FC<INotSupportedModal> = ({
  visible,
  title,
  description,
  handleModal,
  boldDescription = "",
  secondryLabel = "Close",
}) => {
  if (!visible) {
    return null;
  }

  return (
    <div className="modal-main" onClick={handleModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="notsupported-header">
          <img
            src={notSupported}
            className="confirmation-image"
            alt="Not supported"
          />
          <div className="modal-title-not">{title}</div>
        </div>
        <div>
          <div className="modal-des">
            <span>
              {description}
              {boldDescription && <strong>{boldDescription}</strong>}
            </span>
          </div>
          <div className="modal-footer">
            <Button
              label={secondryLabel}
              handleClick={handleModal}
              type="button__filled button__filled--secondary button__large button-cancel"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
