import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  NotificationVisibleState,
} from "@views/Notification/store/state";
import NotificationDrawer from "@components/NotificationDrawer/notificationDrawer";
import Notification from "@views/Notification/Notification";
import { Icon } from "..";
import { db } from "libs";
import { ref, onValue  } from "firebase/database";
import { ENVIRONMENT} from "envs";

import styles from "./NotificationBell.module.sass";
import { userPersonalDetails } from "@states/user";


const NotificationBell = () => {
  const [visibleNotification, setVisibleNotification] = useRecoilState(
    NotificationVisibleState
  );
  const [count, setCount] = useState(0);

  const personalData = useRecoilValue(userPersonalDetails);

  const notification = <Notification />;
  const NotificationProps = {
    visibleNotification,
    setVisibleNotification,
  };
  const handleClickNotification = useCallback(() => {
    return setVisibleNotification(true);
  }, []);

  useEffect(() => {
    const id = personalData?.id;
    if (id) {
      const dataRef = ref(
        db,
        `In-App-Notification-${ENVIRONMENT}/${id}`
      );
  
      onValue(dataRef, snapshot => {
        const data: {counter: number} = snapshot.val();
        const {counter} = data ?? {};
          setCount(counter); 
      });
    }
  }, [personalData?.id]);

  return (
    <div>
      {visibleNotification && (
        <NotificationDrawer
          {...NotificationProps}
          notificationBody={notification}
        />
      )}
      <span
        onClick={handleClickNotification}
        className={styles.notificationIconContainer}
      >
        {!!count && (
          <span className={styles.notificationIconContainer__dot} />
        )}
        <Icon className={styles.notification_icon} name="bell" size="24" />
      </span>
    </div>
  );
};

export default NotificationBell;
