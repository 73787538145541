import { Dropdown } from "components";
import "./pagination.scss";


interface IPagination {
  showCount: boolean,
  page: number,
  perPage: number,
  listCount: number,
  perPageOptions: number[],
  onChangePerPage: (perPage: number) => void,
  onChangePage: (page: number) => void,
}

export const Pagination = (props: IPagination) => {
  const {
    showCount, perPageOptions, page, perPage, onChangePerPage, onChangePage,
    listCount
  } = props;

  return (
    <div className="pagination-exchange">
      <div className="row-per-page">
        {showCount && <>1-10 of 23 &nbsp; &nbsp; </>}Rows per page &nbsp; &nbsp;{" "}
        <Dropdown
          classDropdownHead="pagination-drop-down"
          value={perPage}
          setValue={onChangePerPage}
          options={perPageOptions}
          positionDropDown="top"
          className={""}
          classDropdownArrow="pagination-drop-arrow"
        />
      </div>
      <div className="prev-next-page">
        <span className={`prev-page ${page === 0 ? "disabled-cur" : ""}`}
              onClick={() => page > 0 && onChangePage(page - 1)}>
          <i className="ri-arrow-left-s-line" />Prev</span>
        <span className="pageNumber">{page < 9 ? `0${page + 1}` : page + 1}</span>
        <span className={`next-page ${perPage !== listCount ? "disabled-cur" : ""}`}
              onClick={() => perPage === listCount && onChangePage(page + 1)}>
          Next<i className="ri-arrow-right-s-line" /></span>
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  showCount: true,
  perPageOptions: [10, 20, 40, 50, 100],
  page: 1,
  perPage: 20,
  listCount: 0,
  onChangePerPage: (perPage: number) => {},
  onChangePage: (page: number) => {},
}
