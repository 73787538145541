export const convertFileToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
};

export const formatBase64 = (base64String: string) => {
  return base64String.replace(/^data:[^;]+;base64,/, "");
};
