// @ts-ignore
import { Json } from "@types/common";
import { useCurrency } from "@hooks/currency";
import React, { useMemo } from "react";


type IOptions = {
  price: number,
  brake?: boolean,
  labelClass?: string,
};

const useAuctionHook = (auctionDetail?: Json) => {
  const { formatCurrencyWithBillion, amountToBondPoints, formatNumberWithBillion } = useCurrency();
  const calculateWeightedYieldPer = (detail: Json, amount: number, decimal = 0) => {
    const avgYieldPer = amount ? ((detail?.metadata?.totalWeightedLoanBalance / amount) * 100) || 0 : 0;
    if (decimal) return avgYieldPer?.toFixed(decimal);
    else return avgYieldPer;
  }

  const sbaAndNormalPrice = (detail: Json, options: IOptions) => {
    const { price = 0, brake = false, labelClass = "" } = options || {};
    if (detail?.product === "SBA-7" && detail?.category === "Loans") {
      const bp = amountToBondPoints(detail?.currentLoanBalance, price);
      return (
        <>
          {formatNumberWithBillion(bp, 6)}
          {brake && <br />}{" "}
          <small className={`text-lower ${labelClass}`}>
            {"bps"} 
          </small>
        </>
      );
    }
    return formatCurrencyWithBillion(price, 2)
  };

  const isSbaLoan = useMemo(() => {
    return auctionDetail?.product === "SBA-7" && auctionDetail?.category === "Loans"
  }, [auctionDetail]);

  return { calculateWeightedYieldPer, sbaAndNormalPrice, isSbaLoan }
}

export default useAuctionHook;
