/* eslint-disable react/jsx-one-expression-per-line */
import { FC, useMemo } from "react";

import cn from "classnames";

import "./skeleton-table.scss";

interface ISkeletonTable {
  listsToRender: number;
  numberColumn: number;
  column?: any;
}

export const SkeletonTable: FC<ISkeletonTable> = ({
  listsToRender,
  numberColumn,
  column,
}) => {
  const columns = column ?? Array(numberColumn).fill(1);
  const skeletonLoader = useMemo(() => {
    return Array(listsToRender)
      .fill(1)
      .map((card: any, index: number) => (
        <div
          key={`table__${index.toString() + card}`}
          className={cn("skeleton_table")}
        >
          {columns.map((colCard: any, idx: number) => (
            <div
              className="sub_table_div"
              key={`sub-table__${idx.toString()}`}
              style={{ width: colCard.width }}
            >
              <div
                className={cn("skeleton_table_symbol skeleton-loader-light")}
              />
            </div>
          ))}
        </div>
      ));
  }, [listsToRender, numberColumn, column]);

  return <div className="skeleton_body">{skeletonLoader}</div>;
};
