export const formatSizeUnits = (size: string | number) => {
  if (typeof size === "string") {
    return size;
  }
  if (size >= 1073741824) {
    size = (size / 1073741824).toFixed(2) + " GB";
  } else if (size >= 1048576) {
    size = (size / 1048576).toFixed(2) + " MB";
  } else if (size >= 1024) {
    size = (size / 1024).toFixed(2) + " KB";
  } else if (size < 10) {
    size = size.toFixed(2) + " MB";
  }

  return size;
};
